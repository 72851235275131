import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import withStyles from "@mui/styles/withStyles";

import { serviceOptionHelper } from "../../../helpers";
import DeliveryMethod from "../../../models/delivery/DeliveryMethod";

const styles = (theme) => ({
  group: {
    display: "block",
    width: "100%"
  },
  serviceOption: {
    border: "2px solid #ddd",
    borderRadius: 4,
    margin: `${theme.spacing()} 0`,
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`
    //color: "#ddd"
  },
  enabledServiceOption: {
    cursor: "pointer"
  },
  noServiceOptionSelected: {
    color: "black"
  },
  selectedServiceOption: {
    borderColor: theme.palette.primary.main,
    color: "black"
  },
  serviceOptionName: {
    //color: "black"
  },
  description: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  deliveryMethod: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `${theme.spacing()} ${theme.spacing()}`,
    color: "#ddd"
  },
  noDeliveryMethodSelected: {
    color: "black"
  },
  selectedDeliveryMethod: {
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  deliveryMethodIcon: {
    marginRight: theme.spacing(0.5)
  },
  selectedDeliveryMethodRadio: {
    color: `${theme.palette.primary.main} !important`
  }
});

class SelectServiceOption extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: ""
    };
  }

  componentDidMount() {
    this.checkServiceOption();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkServiceOption();
  }

  getAvailableServiceOptions = () => {
    const { allServiceOptions, disabledServiceOptions } = this.props;

    if (!disabledServiceOptions) {
      return allServiceOptions;
    }

    const availableServiceOptions = serviceOptionHelper.createServiceOptions();

    allServiceOptions.forEach((serviceOption) => {
      if (!disabledServiceOptions.has(serviceOption.name)) {
        availableServiceOptions.add(serviceOption);
      }
    });

    return availableServiceOptions;
  };

  checkServiceOption = () => {
    const { serviceOption, onChange } = this.props;

    const availableServiceOptions = this.getAvailableServiceOptions();

    if (!serviceOption || !availableServiceOptions.has(serviceOption.name)) {
      const defaultServiceOption = this.getDefaultServiceOption();
      if (serviceOption?.name !== defaultServiceOption?.name) {
        onChange(defaultServiceOption);
      }
    }
  };

  getDefaultServiceOption = () => {
    const availableServiceOptions = this.getAvailableServiceOptions();

    return availableServiceOptions.length === 1
      ? availableServiceOptions.all[0]
      : null;
  };

  handleServiceOptionClick = (serviceOption) => (event) => {
    const { onChange } = this.props;

    onChange(serviceOption);
  };

  handleChange = (event) => {
    // TO FIX: replaced with handleServiceOptionClick to cover whole clickable area
    /*const { onChange, allServiceOptions } = this.props;

    const name = event.target.value;

    onChange(allServiceOptions.find(name));*/
  };

  isServiceOptionDisabled = (serviceOption) => {
    const { isDisabled, disabledServiceOptions } = this.props;

    return (
      isDisabled ||
      (disabledServiceOptions && disabledServiceOptions.has(serviceOption.name))
    );
  };

  render() {
    const {
      classes,
      t,
      allServiceOptions,
      disabledServiceOptions,
      getDescription
    } = this.props;

    const selectedServiceOptionName = this.props.serviceOption
      ? this.props.serviceOption.name
      : "";

    return (
      <RadioGroup
        className={classes.group}
        value={selectedServiceOptionName}
        onChange={this.handleChange}
      >
        {allServiceOptions.length > 0 ? (
          allServiceOptions.map((serviceOption) => (
            <div
              key={serviceOption.name}
              className={classNames(classes.serviceOption, {
                [classes.noServiceOptionSelected]: !selectedServiceOptionName,
                [classes.selectedServiceOption]:
                  selectedServiceOptionName === serviceOption.name,
                [classes.enabledServiceOption]:
                  !this.isServiceOptionDisabled(serviceOption)
              })}
              onClick={
                this.isServiceOptionDisabled(serviceOption)
                  ? undefined
                  : this.handleServiceOptionClick(serviceOption)
              }
            >
              <FormControlLabel
                value={serviceOption.name}
                control={
                  <Radio
                    disabled={this.isServiceOptionDisabled(serviceOption)}
                    name={serviceOption.name}
                    color="primary"
                    className={classNames({
                      [classes.selectedDeliveryMethodRadio]:
                        selectedServiceOptionName === serviceOption.name
                    })}
                  />
                }
                label={
                  <span
                    className={classNames(classes.serviceOptionName, {
                      [classes.selectedDeliveryMethodRadio]:
                        selectedServiceOptionName === serviceOption.name
                    })}
                  >
                    {serviceOptionHelper.getServiceOptionLabel(
                      serviceOption,
                      t
                    )}
                  </span>
                }
              />
              <div className={classes.description}>
                {getDescription &&
                  getDescription(
                    serviceOption,
                    !disabledServiceOptions ||
                      !disabledServiceOptions.has(serviceOption.name)
                  )}
              </div>
              <div
                className={classNames(classes.deliveryMethod, {
                  [classes.noDeliveryMethodSelected]:
                    !selectedServiceOptionName,
                  [classes.selectedDeliveryMethod]:
                    selectedServiceOptionName === serviceOption.name
                })}
              >
                {serviceOption.deliveryMethod === DeliveryMethod.SERVE ? (
                  <EventSeatIcon className={classes.deliveryMethodIcon} />
                ) : serviceOption.deliveryMethod === DeliveryMethod.PICKUP ? (
                  <EmojiPeopleIcon className={classes.deliveryMethodIcon} />
                ) : (
                  <HourglassEmptyIcon className={classes.deliveryMethodIcon} />
                )}
                <span>
                  {t(
                    serviceOption.deliveryMethod === DeliveryMethod.SERVE
                      ? "delivery.my-order-will-be-served"
                      : serviceOption.deliveryMethod === DeliveryMethod.PICKUP
                      ? "delivery.i-pick-up-my-order-myself"
                      : "label.unknown"
                  )}
                </span>
              </div>
            </div>
          ))
        ) : (
          <Typography>{t("order.no-service-options-found")}</Typography>
        )}
      </RadioGroup>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SelectServiceOption)
);
