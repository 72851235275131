import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Linkify from 'react-linkify';

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  description: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
    margin: "0 auto",
    padding: 16
  },
  line: {
    minHeight: 24
  },
  link: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "inherit"
  }
});

class BarDescription extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, bar } = this.props;

    return (
      <div>
        {bar && bar.params && bar.params.barDescription && (
          <div className={classes.description}>
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="_blank" rel="noreferrer" href={decoratedHref} key={key} className={classes.link}>
                  {decoratedText}
                </a>
            )}>
            {bar.params.barDescription.split("\n").map((line, index) => (
              <Typography key={index} className={classes.line}>
                {line}
              </Typography>
            ))}
            </Linkify>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(BarDescription));
