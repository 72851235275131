import React, { PureComponent } from "react";

import SelectOrderZoneButton from "./SelectOrderZoneButton";
import SelectOrderDeliveryButton from "./SelectOrderDeliveryButton";

class SelectOrderPrerequisitesButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      bar,
      orderZone,
      orderLayoutPath,
      orderServiceOption,
      hideLabel,
      onClick
    } = this.props;

    return bar.isUsingZones() ? (
      <SelectOrderZoneButton
        orderZone={orderZone}
        orderLayoutPath={orderLayoutPath}
        hideLabel={hideLabel}
        onClick={onClick}
      />
    ) : (
      <SelectOrderDeliveryButton
        orderServiceOption={orderServiceOption}
        hideLabel={hideLabel}
        onClick={onClick}
      />
    );
  }
}

export default SelectOrderPrerequisitesButton;
