import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";

const styles = (theme) => ({
  content: {
    display: "block",
    textAlign: "center",
    padding: "4px 39px"
  },
  icon: {
    verticalAlign: "middle",
    margin: "-2px 4px 0 -24px"
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
    color: theme.palette.primary.main
  }
});

class AssistanceNeeded extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t } = this.props;

    return (
      <Typography className={classes.content} variant="caption">
        <InfoIcon fontSize="small" className={classes.icon} />
        {t("help.assistance-needed-with-ordering-1")}
        {config.support.email && (
          <span>
            {", "}
            {t("help.assistance-needed-with-ordering-2")}{" "}
            <a href={`mailto:${config.support.email}`} className={classes.link}>
              {t("help.assistance-needed-with-ordering-3")}
            </a>
          </span>
        )}
        .
      </Typography>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(AssistanceNeeded));
