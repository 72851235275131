import React, { PureComponent } from "react";

import { deviceStorage } from "tap-io/storage";

import {
  CACHE_KEY_DELIVERY_CONTACT,
  CACHE_KEY_SERVICE_OPTION_NAME
} from "../../constants/cache";
import { serviceOptionHelper } from "tap-io/helpers";

class ApplyDeliveryCache extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.checkDelivery();
  }

  checkDelivery = async () => {
    const { onOrderServiceOptionChange, onOrderDeliveryContactChange } =
      this.props;

    const serviceOptionName = await this.getServiceOptionName();

    const deliveryContact = await this.getDeliveryContact();

    if (serviceOptionName) {
      try {
        const serviceOption =
          serviceOptionHelper.createServiceOption(serviceOptionName);
        onOrderServiceOptionChange(serviceOption);
      } catch (error) {
        console.warn(error);
      }
    }

    if (deliveryContact) {
      onOrderDeliveryContactChange(deliveryContact);
    }
  };

  getServiceOptionName = async () => {
    const { bar } = this.props;

    if (this.props.serviceOptionName) {
      try {
        return serviceOptionHelper.createServiceOption(
          this.props.serviceOptionName
        ).name;
      } catch (error) {
        console.warn(error);
      }
    }

    return await deviceStorage.getDeviceCacheItem(
      bar.id,
      CACHE_KEY_SERVICE_OPTION_NAME
    );
  };

  getDeliveryContact = async () => {
    const { bar } = this.props;

    return (
      this.props.deliveryContact ||
      (await deviceStorage.getDeviceCacheItem(
        bar.id,
        CACHE_KEY_DELIVERY_CONTACT
      ))
    );
  };

  render() {
    return null;
  }
}

export default ApplyDeliveryCache;
