import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import {
  ListItem,
  ListItemText,
  MenuItem,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import withStyles from "@mui/styles/withStyles";

import { barHelper, utilsHelper } from "tap-io/helpers";
import CustomerFieldType from "tap-io/models/bar/CustomerFieldType";
import SelectDate from "tap-io/client/components/common/SelectDate";

const styles = (theme) => ({
  mixedField: {
    width: "100%"
  },
  fieldInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 5
  },
  fieldInfoIcon: {
    marginRight: 5
  },
  toggleField: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  selectField: {
    width: "100%"
  },
  helperText: {
    color: theme.palette.text.secondary
  },
  error: {
    color: theme.palette.error.main
  }
});

class CustomerFieldsForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      formFields: null
    };
  }

  componentDidMount() {
    const { initialFieldValues, initialFieldValueForType } = this.props;

    this.refreshCustomerFields(initialFieldValues, initialFieldValueForType);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /*const { customerFields, fieldValues } = this.props;

    const prevCustomerFields = prevProps.customerFields;

    const prevFieldValues = prevProps.prevFieldValues;*/
  }

  refreshCustomerFields(fieldValues, fieldValueForType) {
    const { customerFields } = this.props;

    const formFields = customerFields
      ? customerFields.map((customerField) => {
          const formField = {
            id: customerField.id,
            customerField,
            isValid: false,
            value:
              fieldValues && fieldValues[customerField.id]
                ? fieldValues[customerField.id].value
                : fieldValueForType && fieldValueForType[customerField.type]
                ? fieldValueForType[customerField.type]
                : ""
          };

          switch (customerField.type) {
            case CustomerFieldType.TEXT:
              formField.isValid = barHelper.isTextCustomerFieldValueValid(
                customerField,
                formField.value
              );
              break;
            case CustomerFieldType.EMAIL:
              formField.isValid = barHelper.isEmailCustomerFieldValueValid(
                customerField,
                formField.value
              );
              break;
            case CustomerFieldType.DATE:
              if (!formField.value) {
                const date = utilsHelper.getFirstDateExceptDays(
                  customerField.exceptDays,
                  customerField.minDate
                );
                const dateAsString = utilsHelper.jsDateToDateString(date);
                formField.value = dateAsString;
              }
              formField.isValid = barHelper.isDateCustomerFieldValueValid(
                customerField,
                formField.value
              );
              break;
            case CustomerFieldType.TOGGLE:
              formField.value = !!formField.value;

              formField.isValid = barHelper.isToggleCustomerFieldValueValid(
                customerField,
                formField.value
              );
              break;
            case CustomerFieldType.SELECT:
              if (!formField.value) {
                formField.value =
                  customerField.options &&
                  customerField.options.length > 0 &&
                  customerField.options[0] &&
                  customerField.options[0].id
                    ? customerField.options[0].id
                    : "";
              }
              formField.isValid = barHelper.isSelectCustomerFieldValueValid(
                customerField,
                formField.value
              );
              break;
          }

          return formField;
        })
      : null;

    this.setState({ formFields }, this.notifyChange);
  }

  updateField = (id, data) => {
    const { formFields } = this.state;

    const newFields = formFields.map((field) => {
      if (field && field.id === id) {
        for (let key in data) {
          field[key] = data[key];
        }
      }

      return field;
    });

    this.setState(
      {
        formFields: newFields
      },
      this.notifyChange
    );
  };

  notifyChange = () => {
    const { onChange } = this.props;
    const { formFields } = this.state;

    const fieldValues = {};
    formFields.forEach(
      (field) =>
        (fieldValues[field.id] = { isValid: field.isValid, value: field.value })
    );
    onChange(fieldValues);
  };

  handleCustomerTextFieldChange = (formField) => (event) => {
    const value = event.target.value;
    const isValid = barHelper.isTextCustomerFieldValueValid(
      formField.customerField,
      value
    );

    this.updateField(formField.id, { isValid, value });
  };

  handleCustomerEmailFieldChange = (formField) => (event) => {
    const value = event.target.value;
    const isValid = barHelper.isEmailCustomerFieldValueValid(
      formField.customerField,
      value
    );

    this.updateField(formField.id, { isValid, value });
  };

  shouldCustomerDateFieldDisableDate = (formField) => (date) => {
    const day = date.day();

    return (
      formField &&
      formField.customerField &&
      formField.customerField.exceptDays &&
      formField.customerField.exceptDays[day] === true
    );
  };

  handleCustomerDateFieldChange = (formField) => (date) => {
    const value = date.toDate();
    const isValid = barHelper.isDateCustomerFieldValueValid(
      formField.customerField,
      value
    );

    const dateAsString = utilsHelper.jsDateToDateString(value);

    this.updateField(formField.id, { isValid, value: dateAsString });
  };

  handleCustomerToggleFieldChange = (formField) => (event) => {
    const value = event.target.checked === true;
    const isValid = barHelper.isToggleCustomerFieldValueValid(
      formField.customerField,
      value
    );

    this.updateField(formField.id, { isValid, value });
  };

  handleCustomerSelectFieldChange = (formField) => (event) => {
    const value = event.target.value;
    const isValid = barHelper.isSelectCustomerFieldValueValid(
      formField.customerField,
      value
    );

    this.updateField(formField.id, { isValid, value });
  };

  getFieldDescription = (formField) => {
    const { t } = this.props;

    return formField.customerField
      ? formField.customerField.description
        ? `${formField.customerField.description}${
            formField.customerField.isRequired && !formField.isValid
              ? ` (${t("field.required")})`
              : ""
          }`
        : formField.customerField.isRequired && !formField.isValid
        ? t("field.required")
        : ""
      : "";
  };

  render() {
    const { classes, t } = this.props;
    const { formFields } = this.state;

    return (
      <div>
        {formFields &&
          formFields.map((formField) => (
            <ListItem key={formField.id}>
              {formField.customerField.type === CustomerFieldType.TEXT ? (
                <TextField
                  variant="standard"
                  label={formField.customerField.name}
                  value={formField.value}
                  onChange={this.handleCustomerTextFieldChange(formField)}
                  fullWidth
                  required={formField.customerField.isRequired}
                  error={!formField.isValid}
                  helperText={this.getFieldDescription(formField)}
                />
              ) : formField.customerField.type === CustomerFieldType.EMAIL ? (
                <div className={classes.mixedField}>
                  <TextField
                    variant="standard"
                    type="email"
                    name="email"
                    autoComplete="email"
                    label={formField.customerField.name}
                    value={formField.value}
                    onChange={this.handleCustomerEmailFieldChange(formField)}
                    fullWidth
                    required={formField.customerField.isRequired}
                    error={!formField.isValid}
                    helperText={this.getFieldDescription(formField)}
                  />
                  {formField.customerField
                    .sendOrderConfirmationToThisAddress && (
                    <div className={classes.fieldInfo}>
                      <InfoIcon
                        fontSize="small"
                        className={classes.fieldInfoIcon}
                      />
                      <Typography variant="caption">
                        {t("field.enter-to-receive-confirmation")}
                      </Typography>
                    </div>
                  )}
                </div>
              ) : formField.customerField.type === CustomerFieldType.DATE ? (
                <SelectDate
                  className={classes.dateField}
                  label={formField.customerField.name}
                  helperText={this.getFieldDescription(formField)}
                  value={formField.value}
                  onChange={this.handleCustomerDateFieldChange(formField)}
                  inputFormat="DD/MM/YYYY"
                  minDate={
                    formField.customerField.minDate
                      ? new Date(formField.customerField.minDate)
                      : undefined
                  }
                  maxDate={
                    formField.customerField.maxDate
                      ? new Date(formField.customerField.maxDate)
                      : undefined
                  }
                  shouldDisableDate={this.shouldCustomerDateFieldDisableDate(
                    formField
                  )}
                />
              ) : formField.customerField.type === CustomerFieldType.TOGGLE ? (
                <div className={classes.toggleField}>
                  <ListItemText
                    className={classNames({
                      [classes.error]: !formField.isValid
                    })}
                    primary={`${formField.customerField.name}${
                      formField.customerField.isRequired ? " *" : ""
                    }`}
                    secondary={
                      <Typography
                        variant="caption"
                        className={classNames(classes.helperText, {
                          [classes.error]: !formField.isValid
                        })}
                      >
                        {this.getFieldDescription(formField)}
                      </Typography>
                    }
                  />
                  <Switch
                    onChange={this.handleCustomerToggleFieldChange(formField)}
                    checked={formField.value}
                  />
                </div>
              ) : formField.customerField.type === CustomerFieldType.SELECT ? (
                <TextField
                  select
                  fullWidth
                  variant="standard"
                  label={formField.customerField.name}
                  helperText={this.getFieldDescription(formField)}
                  value={formField.value}
                  onChange={this.handleCustomerSelectFieldChange(formField)}
                >
                  {formField.customerField.options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : null}
            </ListItem>
          ))}
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(CustomerFieldsForm));
