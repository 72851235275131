import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import { orderService } from "tap-io/client/services";

import SelectVouchers from "../voucher/SelectVouchers";

const styles = (theme) => ({
  totalContainer: {
    display: "flex",
    width: "100%",
    fontWeight: "bold"
  },
  totalLabel: {
    width: "100%"
  },
  totalAmount: {
    width: "100%",
    textAlign: "right"
  }
});

class OrderVouchersLine extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getTotalToBePaid = () => {
    const { bar, orderTotal, vouchers, orderItems, orderVoucherIds } =
      this.props;

    if (vouchers && orderItems && orderVoucherIds) {
      const items = [];
      for (let id in orderItems) {
        const { menuItem, ...other } = orderItems[id];
        items.push({
          ...menuItem.toJSON(),
          ...other,
          id
        });
      }

      let remainingOrderTotal = orderTotal;

      for (let i = 0; i < vouchers.length; i++) {
        const voucher = vouchers[i];

        if (
          remainingOrderTotal > 0 &&
          orderVoucherIds.indexOf(voucher.id) >= 0
        ) {
          // Use dummy order
          const dummyOrder = orderService.createDummyOrder(bar.id, "", {
            items,
            total: remainingOrderTotal
          });

          // Use dummy voucher (clone)
          const dummyVoucher = voucher.clone();

          const redeemedWithVoucherTotal =
            dummyVoucher.redeemForOrder(dummyOrder);
          remainingOrderTotal = utilsHelper.roundToTwoDecimals(
            remainingOrderTotal - redeemedWithVoucherTotal
          );
        }

        if (remainingOrderTotal === 0) {
          break;
        }
      }

      return remainingOrderTotal;
    }

    return orderTotal;
  };

  render() {
    const {
      classes,
      t,
      allowOrderChange,
      bar,
      vouchers,
      orderVoucherIds,
      onOrderVoucherIdsChange
    } = this.props;

    return (
      <div>
        {vouchers && vouchers.length > 0 && (
          <div>
            <ListItem>
              <SelectVouchers
                isDisabled={!allowOrderChange}
                vouchers={vouchers}
                selectedVoucherIds={orderVoucherIds}
                onSelectedVoucherIdsChange={onOrderVoucherIdsChange}
              />
            </ListItem>
            <ListItem>
              <Typography variant="body1" className={classes.totalContainer}>
                <span className={classes.totalLabel}>
                  {t("order.total-to-be-paid")}
                </span>
                <span
                  className={classes.totalAmount}
                >{`${utilsHelper.formatToTwoDecimals(
                  this.getTotalToBePaid()
                )} ${bar.getOrderCurrency()}`}</span>
              </Typography>
            </ListItem>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderVouchersLine));
