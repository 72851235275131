import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Redirect } from "react-router-dom";

import { toast } from "react-toastify";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import theme from "tap-io/client/theme";
import {
  barService,
  baseService,
  menuService,
  voucherService
} from "tap-io/client/services";
import {
  baseHelper,
  menuHelper,
  utilsHelper,
  voucherHelper
} from "tap-io/helpers";
import { deviceStorage } from "tap-io/storage";
import withPageQuery from "tap-io/client/components/common/withPageQuery";
import ConfirmVoucherDialog from "tap-io/client/components/voucher/ConfirmVoucherDialog";

import HeaderAds from "../components/ad/HeaderAds";
import CreateOrder from "../components/order/CreateOrder";
import * as routes from "../constants/routes";
import OrderWaiterPageMessage from "../components/order/OrderWaiterPageMessage";
import OrderVouchersAppliedMessage from "../components/order/OrderVouchersAppliedMessage";
import {
  CACHE_KEY_CUSTOMER_FIELDS,
  CACHE_KEY_DELIVERY_CONTACT,
  CACHE_KEY_NAME,
  CACHE_KEY_ORDER_HISTORY,
  CACHE_KEY_SERVICE_OPTION_NAME,
  CACHE_KEY_VOUCHER_CODES
} from "../constants/cache";
import ScanOrManuallyEnterVoucherDrawer from "../components/voucher/ScanOrManuallyEnterVoucherDrawer";
import AssistanceNeeded from "../components/common/AssistanceNeeded";

const styles = (theme) => ({
  message: {
    padding: "40px 20px",
    fontWeight: "bold"
  },
  spacing: {
    height: 30
  },
  header: {
    position: "relative",
    zIndex: 10
  }
});

const ORDER_DETAILS_MAX_CACHE_AGE = 6 * 3600; // 6 hours
const ORDER_NAME_VOUCHER = "voucher";

class CreateOrderPage extends PureComponent {
  constructor(props) {
    super(props);

    const { match } = props;

    let barLocator = null,
      orderName = null;
    if (match && match.params) {
      // Make sure params are properly decoded
      if (match.params.barLocator) {
        barLocator = decodeURIComponent(match.params.barLocator);
      }
      if (match.params.orderName) {
        orderName = decodeURIComponent(match.params.orderName);
      }
    }

    this.state = {
      voucherDataToConfirm: null,
      isScanOrManuallyEnterVoucherDrawerOpen: false,
      waiterCode: null,
      isWaiterPage: false,
      vouchers: null,
      voucherCodes: null,
      barLocator,
      orderName,
      bases: null,
      baseIds: null,
      orderZone: null,
      orderLayoutPath: null,
      menuElements: null,
      menuCategories: null,
      menuItems: null,
      orderId: null
    };
  }

  componentDidMount() {
    const { t, queryParams } = this.props;

    const voucherCodeInQueryParams = queryParams
      ? queryParams.voucherCode
      : undefined;

    this.checkIsWaiterPage()
      .then((isWaiterPage) => {
        if (!isWaiterPage) {
          this.checkName(voucherCodeInQueryParams === undefined);
          this.checkVouchers();
        }
      })
      .catch((error) => {
        console.warn(error);
      });

    if (voucherCodeInQueryParams) {
      this.promptToConfirmVoucher(voucherCodeInQueryParams)
        .then(() => { })
        .catch((error) => {
          console.warn(error);
        });
    }

    this.refreshBases();
    this.refreshMenuElements();

    utilsHelper.resetScrollPosition();

    //toast.warn(t("label.server-issues"), { autoClose: false });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { bar, activeDeposits } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;
    const barIsUsingZones = bar ? bar.isUsingZones() : undefined;
    const prevBarIsUsingZones = prevProps.bar
      ? prevProps.bar.isUsingZones()
      : undefined;


    if (barId !== prevBarId || barIsUsingZones !== prevBarIsUsingZones) {
      this.refreshBases();
      this.refreshMenuElements();
    } else {
      // This is in else clause because this.refreshMenuElements() also implies this.refreshMenuDeposits()
      const activeDepositIds = activeDeposits ? activeDeposits.map(deposit => deposit.id) : [];
      const prevActiveDepositIds = prevProps.activeDeposits ? prevProps.activeDeposits.map(deposit => deposit.id) : [];

      if (!utilsHelper.areObjectsEqualShallow(activeDepositIds, prevActiveDepositIds)) {
        this.refreshMenuDeposits();
      }
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeBases) {
      this.unsubscribeBases();
    }
    if (this.unsubscribeMenuElements) {
      this.unsubscribeMenuElements();
    }
  }

  checkIsWaiterPage = async () => {
    const { queryParams, bar } = this.props;

    if (queryParams && queryParams.waiterCode) {
      const waiterCode = queryParams.waiterCode;
      const isWaiterPage = await barService.isBarWaiterCodeValid(
        bar,
        waiterCode
      );

      this.setState({
        isWaiterPage,
        waiterCode: isWaiterPage ? waiterCode : null
      });

      return isWaiterPage;
    }

    return false;
  };

  checkName = async (checkDeviceStorage) => {
    const { bar } = this.props;
    const { orderName } = this.state;

    if (orderName) {
      // Open the drawer to add a voucher if the orderName equals ORDER_NAME_VOUCHER
      if (orderName === ORDER_NAME_VOUCHER) {
        this.setState({
          isScanOrManuallyEnterVoucherDrawerOpen: true,
          orderName: ""
        });
      }
    } else if (checkDeviceStorage) {
      const name = await deviceStorage.getDeviceCacheItem(
        bar.id,
        CACHE_KEY_NAME
      );
      if (name) {
        this.setState({ orderName: name });
      }
    }
  };

  checkDeliveryMethod = async () => { };

  checkVouchers = async (prioVoucherCode) => {
    const { t, bar } = this.props;

    const voucherCodes =
      (await deviceStorage.getDeviceCacheItem(
        bar.id,
        CACHE_KEY_VOUCHER_CODES
      )) || [];
    const voucherCodesToCache = [];
    const voucherCodesAsMap = {};
    const vouchers = [];

    // Check if there is a prio voucherCode (e.g. in url or manually scanned/entered)
    if (prioVoucherCode && voucherCodes.indexOf(prioVoucherCode) < 0) {
      try {
        const decodedPrioVoucherCode =
          voucherHelper.decodeVoucherCode(prioVoucherCode);
        const prioVoucher = await voucherService.getVoucherById(
          bar.id,
          decodedPrioVoucherCode.voucherId
        );

        if (prioVoucher.isValid) {
          if (prioVoucher.mustBeRedeemedInOneGo) {
            // If the voucher from the URL must be redeemed in one go, skip all other vouchers for this order
            return this.setState({
              vouchers: [prioVoucher],
              voucherCodes: {
                [decodedPrioVoucherCode.voucherId]: prioVoucherCode
              }
            });
          } else {
            // Otherwise, add the voucher to the other cached vouchers
            voucherCodesToCache.push(prioVoucherCode);
            voucherCodesAsMap[prioVoucher.id] = prioVoucherCode;
            vouchers.push(prioVoucher);
          }
        }
      } catch (error) {
        console.warn(error);

        toast.error(
          `${t("label.something-went-wrong")} (${t(
            error ? error.message : "error.unknown-error"
          )})`
        );
      }
    }

    try {
      vouchers.push(
        ...(
          await Promise.all(
            voucherCodes.map(async (voucherCode) => {
              try {
                const { voucherId, voucherPin } =
                  voucherHelper.decodeVoucherCode(voucherCode);

                const voucher = voucherCodesAsMap[voucherId]
                  ? voucherCodesAsMap[voucherId]
                  : await voucherService.getVoucherById(bar.id, voucherId);

                // Only process valid vouchers
                if (voucher.isValid) {
                  voucherCodesToCache.push(voucherCode);
                  voucherCodesAsMap[voucherId] = voucherCode;
                  return voucher;
                }
              } catch (error) {
                console.warn(error);
              }

              return null;
            })
          )
        ).filter((voucher) => voucher !== null)
      );

      this.setState({
        vouchers,
        voucherCodes: voucherCodesAsMap
      });

      await deviceStorage.setDeviceCacheItem(
        bar.id,
        CACHE_KEY_VOUCHER_CODES,
        voucherCodesToCache
      );
    } catch (error) {
      console.warn(error);
    }
  };

  promptToConfirmVoucher = async (voucherCode) => {
    const { bar } = this.props;

    const decodedVoucherCode = voucherHelper.decodeVoucherCode(voucherCode);
    const voucher = await voucherService.getVoucherById(
      bar.id,
      decodedVoucherCode.voucherId
    );

    this.setState({
      voucherDataToConfirm: {
        code: voucherCode,
        voucher
      }
    });
  };

  handleVoucherConfirm = async () => {
    const { voucherDataToConfirm } = this.state;

    if (voucherDataToConfirm) {
      await this.checkVouchers(voucherDataToConfirm.code);
    }

    this.setState({
      voucherDataToConfirm: null
    });
  };

  handleCancelVoucherToConfirm = () => {
    this.setState({
      voucherDataToConfirm: null
    });
  };

  updateMenuElements = (menuElements) => {
    const menuCategories = [],
      menuItems = [];

    menuElements.forEach((element) => {
      if (menuHelper.isCategoryMenuElement(element)) {
        menuCategories.push(element);
      } else if (menuHelper.isItemMenuElement(element)) {
        menuItems.push(element);
      }
    });

    this.setState({ menuElements, menuCategories, menuItems }, this.refreshMenuDeposits);
  };

  refreshMenuDeposits = () => {
    const { activeDeposits } = this.props;
    const { menuItems } = this.state;

    let menuDeposits = [];

    if (menuItems && activeDeposits) {
      const menuDepositIds = [];

      menuItems.forEach(menuItem => {
        menuItem.depositIds.forEach(depositId => {
          if (menuDepositIds.indexOf(depositId) < 0) {
            menuDepositIds.push(depositId);
          }
        });
      });

      menuDeposits = activeDeposits.filter(
        (deposit) => menuDepositIds.indexOf(deposit.id) >= 0
      );
    }

    this.setState({ menuDeposits });
  }

  refreshBases = () => {
    const { bar } = this.props;

    if (bar && bar.isUsingZones()) {
      // Do nothing, bases are managed by handleOrderZoneChange()
    } else {
      if (this.unsubscribeBases) {
        this.unsubscribeBases();
        this.unsubscribeBases = undefined;
      }

      if (bar && bar.id && bar.isUsingBases()) {
        // TO FIX: baseIds will need to be updated as well
        this.unsubscribeBases = baseService.onActiveBases(bar.id, (bases) => {
          this.setState({ bases }, this.refreshMenuElements);
        });
      } else {
        this.setState({ bases: null, baseIds: null }, this.clearMenuElements);
      }
    }
  };

  clearMenuElements = () => {
    this.setState({
      menuElements: null,
      menuCategories: null,
      menuItems: null
    });
  };

  refreshMenuElements = async () => {
    const { bar } = this.props;
    const { bases, baseIds } = this.state;

    if (this.unsubscribeMenuElements) {
      this.unsubscribeMenuElements();
      this.unsubscribeMenuElements = undefined;
    }

    try {
      if (!bar || !bar.id) {
        throw new Error("error.bar-or-bar-id-is-not-defined");
      }

      if (bar.isUsingBases()) {
        if (bases && bases.length > 0) {
          const menuIds = [];

          bases.forEach((base) => {
            if (!baseIds || baseIds.indexOf(base.id) >= 0) {
              base.menuIds.forEach((menuId) => {
                if (menuIds.indexOf(menuId) < 0) {
                  menuIds.push(menuId);
                }
              });
            }
          });

          this.unsubscribeMenuElements =
            menuService.onAllElementsFromActiveMenusWithIds(
              bar.id,
              menuIds,
              this.updateMenuElements
            );
        } else {
          this.clearMenuElements();
        }
      } else {
        this.unsubscribeMenuElements = menuService.onAllElementsFromActiveMenus(
          bar.id,
          this.updateMenuElements
        );
      }
    } catch (error) {
      console.warn(error);

      this.clearMenuElements();
    }
  };

  handleScanOrManuallyEnterVoucherDrawerClose = () => {
    this.setState({ isScanOrManuallyEnterVoucherDrawerOpen: false });
  };

  handleScanOrManuallyEnterVoucherCheck = async (voucherId, voucherPin) => {
    const { bar } = this.props;

    const voucherCode = voucherHelper.encodeVoucherCode(voucherId, voucherPin);
    const voucher = await voucherService.getVoucherById(bar.id, voucherId);

    this.setState({
      isScanOrManuallyEnterVoucherDrawerOpen: false,
      vouchers: null, // TO FIX: Needed for mustBeRedeemedInOneGo
      voucherCodes: null, // TO FIX: Needed for mustBeRedeemedInOneGo
      voucherDataToConfirm: {
        code: voucherCode,
        voucher
      },
      voucherId,
      voucherPin
    });
  };

  handleVoucherRemove = async (voucherToRemove) => {
    const { bar } = this.props;
    const { vouchers, voucherCodes } = this.state;

    const updatedVoucherCodes = {};
    for (let voucherId in voucherCodes) {
      if (voucherId !== voucherToRemove.id) {
        updatedVoucherCodes[voucherId] = voucherCodes[voucherId];
      }
    }
    const updatedVouchers = vouchers.filter(
      (voucher) => voucher.id !== voucherToRemove.id
    );

    this.setState({
      voucherCodes: updatedVoucherCodes,
      vouchers: updatedVouchers
    });

    await deviceStorage.setDeviceCacheItem(
      bar.id,
      CACHE_KEY_VOUCHER_CODES,
      Object.keys(updatedVoucherCodes)
    );
  };

  handleOrderZoneChange = async (zone) => {
    if (this.unsubscribeBases) {
      this.unsubscribeBases();
      this.unsubscribeBases = undefined;
    }

    if (zone) {
      this.unsubscribeBases = baseService.onActiveBasesWithIds(
        zone.barId,
        zone.baseIds,
        (bases) => {
          this.setState({ bases }, this.refreshMenuElements);
        }
      );
    }
  };

  handleOrderServiceOptionOrLayoutNodeChange = async (
    orderServiceOption,
    orderLayoutNode
  ) => {
    const { bar } = this.props;
    const { bases } = this.state;

    if (bar.isUsingBases()) {
      const prevBaseIds = this.state.baseIds;

      if (bar.isUsingZones()) {
        if (orderLayoutNode && orderServiceOption) {
          const baseIds = orderLayoutNode.getBaseIdsForServiceOption(
            orderServiceOption.name
          );

          if (!utilsHelper.areArraysEqualShallow(prevBaseIds, baseIds)) {
            this.setState({ baseIds }, this.refreshMenuElements);
          }
        } else {
          this.setState({ baseIds: null }, this.clearMenuElements);
        }
      } else {
        if (orderServiceOption && bases) {
          const baseIds = bases
            .filter(
              baseHelper.onlyBasesWithServiceOptionFilter(
                orderServiceOption.name
              )
            )
            .map((base) => base.id);

          if (!utilsHelper.areArraysEqualShallow(prevBaseIds, baseIds)) {
            this.setState({ baseIds }, this.refreshMenuElements);
          }
        } else {
          this.setState({ baseIds: null }, this.clearMenuElements);
        }
      }
    }
  };

  addToOrderHistory = async (orderId) => {
    const { bar } = this.props;

    const orderHistory =
      (await deviceStorage.getDeviceCacheItem(
        bar.id,
        CACHE_KEY_ORDER_HISTORY
      )) || [];

    orderHistory.push({ id: orderId });

    await deviceStorage.setDeviceCacheItem(
      bar.id,
      CACHE_KEY_ORDER_HISTORY,
      orderHistory
    );
  };

  handleOrderCreated = async (
    orderId,
    orderName,
    orderServiceOption,
    orderDeliveryContact,
    orderFields
  ) => {
    const { bar } = this.props;
    const { isWaiterPage } = this.state;

    if (!isWaiterPage) {
      await deviceStorage.setDeviceCacheItem(
        bar.id,
        CACHE_KEY_NAME,
        orderName,
        ORDER_DETAILS_MAX_CACHE_AGE
      );

      if (orderServiceOption) {
        await deviceStorage.setDeviceCacheItem(
          bar.id,
          CACHE_KEY_SERVICE_OPTION_NAME,
          orderServiceOption.name,
          ORDER_DETAILS_MAX_CACHE_AGE
        );
      }

      if (orderDeliveryContact) {
        await deviceStorage.setDeviceCacheItem(
          bar.id,
          CACHE_KEY_DELIVERY_CONTACT,
          orderDeliveryContact
        );
      }

      if (orderFields) {
        await deviceStorage.setDeviceCacheItem(
          bar.id,
          CACHE_KEY_CUSTOMER_FIELDS,
          orderFields
        );
      }

      await this.addToOrderHistory(orderId);
    }

    this.setState({ orderId });
  };

  getViewOrderRedirect = () => {
    const { isWaiterPage, waiterCode, voucherCodes, barLocator, orderId } =
      this.state;

    const pathname = routes.VIEW_ORDER.replace(
      ":barLocator",
      barLocator
    ).replace(":orderId", orderId);

    const search = `?action=pay${isWaiterPage ? `&waiterCode=${waiterCode}` : ""
      }${voucherCodes && voucherCodes.length > 0
        ? `&voucherCodes=${voucherCodes.join(",")}`
        : ""
      }`;

    return (
      <Redirect
        to={{
          pathname,
          search
        }}
      />
    );
  };

  render() {
    const {
      classes,
      t,
      queryParams,
      headerProps,
      locale,
      bar,
      activeFees,
      activeDeposits,
      allZones
    } = this.props;
    const {
      barLocator,
      voucherDataToConfirm,
      isScanOrManuallyEnterVoucherDrawerOpen,
      isWaiterPage,
      vouchers,
      voucherCodes,
      orderName,
      bases,
      menuElements,
      menuCategories,
      menuItems,
      menuDeposits,
      orderId
    } = this.state;

    if (orderId) {
      return this.getViewOrderRedirect();
    }
    // SPECIAL: COVID-19 contact tracing copy

    return (
      <div>
        <ConfirmVoucherDialog
          isOpen={voucherDataToConfirm !== null}
          onConfirm={this.handleVoucherConfirm}
          onCancel={this.handleCancelVoucherToConfirm}
          bar={bar}
          voucher={
            voucherDataToConfirm ? voucherDataToConfirm.voucher : undefined
          }
        />
        <ScanOrManuallyEnterVoucherDrawer
          isOpen={isScanOrManuallyEnterVoucherDrawerOpen}
          onClose={this.handleScanOrManuallyEnterVoucherDrawerClose}
          onConfirm={this.handleScanOrManuallyEnterVoucherCheck}
        />
        <div className={classes.header}>
          {isWaiterPage && <OrderWaiterPageMessage />}
          {bar.isAllowingVoucherPayments() &&
            vouchers &&
            vouchers.length > 0 && (
              <OrderVouchersAppliedMessage
                bar={bar}
                vouchers={vouchers}
                onVoucherRemove={this.handleVoucherRemove}
              />
            )}
        </div>
        {false && bar.integrations.showAds && (
          <HeaderAds
            bar={bar}
            headerSpacing={menuCategories && menuCategories.length > 0 ? 40 : 0}
          />
        )}
        {bar.isLive && allZones ? (
          <CreateOrder
            barLocator={barLocator}
            queryParams={queryParams}
            headerProps={headerProps}
            locale={locale}
            bar={bar}
            activeFees={activeFees}
            menuDeposits={menuDeposits}
            allZones={allZones}
            orderName={orderName}
            bases={bases}
            menuElements={menuElements}
            menuCategories={menuCategories}
            menuItems={menuItems}
            vouchers={vouchers}
            voucherCodes={voucherCodes}
            onOrderZoneChange={this.handleOrderZoneChange}
            onOrderServiceOptionOrLayoutNodeChange={
              this.handleOrderServiceOptionOrLayoutNodeChange
            }
            onOrderCreated={this.handleOrderCreated}
            onVoucherRemove={this.handleVoucherRemove}
          />
        ) : (
          <Typography className={classes.message}>
            {t("order.ordering-currently-not-available")}
          </Typography>
        )}
        <div className={classes.spacing} />
        <AssistanceNeeded />
        <div className={classes.spacing} />
        <theme.socials />
      </div>
    );
  }
}

export default withPageQuery(
  withStyles(styles)(withTranslation("common")(CreateOrderPage))
);

/*
menuElements && menuElements.length > 0 ?
:
            <Typography className={classes.message}>
              {t("order.no-items-available")}
            </Typography>
*/
