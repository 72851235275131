import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";

const styles = (theme) => ({
  totalContainer: {
    display: "flex",
    width: "100%"
  },
  totalLabel: {
    width: "100%"
  },
  totalAmount: {
    width: "100%",
    textAlign: "right"
  }
});

class OrderTotalForItemsLine extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, orderItemsAmount, orderItemsTotal } = this.props;

    return (
      <ListItem>
        <Typography variant="body1" className={classes.totalContainer}>
          <span className={classes.totalLabel}>{`${t(
            "order.total-for"
          )} ${orderItemsAmount} ${t(
            orderItemsAmount === 1 ? "order.item" : "order.items"
          )}`}</span>
          <span
            className={classes.totalAmount}
          >{`${utilsHelper.formatToTwoDecimals(
            orderItemsTotal
          )} ${bar.getOrderCurrency()}`}</span>
        </Typography>
      </ListItem>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderTotalForItemsLine));
