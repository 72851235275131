import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ContentCard from "tap-io/client/components/common/ContentCard";
import OrderIsPartiallyPaidMessage from "tap-io/client/components/payment/OrderIsPartiallyPaidMessage";
import PayOrderWithVoucher from "tap-io/client/components/payment/PayOrderWithVoucher";
import { AndroidWrapper } from "tap-io/client/native/AndroidWrapper";
import PaymentProvider from "tap-io/models/payment/PaymentProvider";
import { deviceStorage } from "tap-io/storage";

import withAuthorization from "../auth/withAuthorization";
import PayOrderCash from "./PayOrderCash";
import PayOrderDeferred from "./PayOrderDeferred";
import PayOrderWithCcv from "./PayOrderWithCcv";
import PayOrderWithMollie from "./PayOrderWithMollie";
import PayOrderWithPayconiq from "./PayOrderWithPayconiq";
import PayOrderWithStarnet from "./PayOrderWithStarnet";
import PayOrderWithVivaWalletOnline from "./PayOrderWithVivaWalletOnline";
import PayOrderWithVivaWalletPos from "./PayOrderWithVivaWalletPos";
import PayOrderWithMultiSafePay from "./PayOrderWithMultiSafePay";

import { CACHE_KEY_VOUCHER_CODES } from "../../constants/cache";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  content: {},
  contentCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  orderIsPartiallyPaidMessage: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20
  },
  paymentOptions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  paymentOption: {
    margin: 20,
    textAlign: "center"
  },
  cancelPayment: {
    marginTop: 40
  }
});

class PayOrderDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      providerProcessingPayment: null,
      paymentProviderError: null
    };
  };

  componentDidMount() {
    this.checkPaymentStatus();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;
    const prevIsOpen = prevProps.isOpen;

    if (isOpen !== prevIsOpen) {
      this.setState(this.initialState());
    }

    this.checkPaymentStatus();
  }

  checkPaymentStatus = () => {
    const { bar, order, onClose } = this.props;
    const { providerProcessingPayment, paymentProviderError } = this.state;

    if (providerProcessingPayment || paymentProviderError) {
      return;
    }
    if (!bar.areThereOnlinePaymentProvidersAvailable() || !order.canBePaid()) {
      onClose();
    }
  };

  handleProviderProcessingPayment = (provider) => (event) => {
    this.setState({ providerProcessingPayment: provider });
  };

  handleProviderComplete = (provider) => async (data) => {
    const { bar, onClose } = this.props;

    if (provider === PaymentProvider.VOUCHER) {
      if (data?.voucherCode) {
        const voucherCodes =
          (await deviceStorage.getDeviceCacheItem(
            bar.id,
            CACHE_KEY_VOUCHER_CODES
          )) || [];

        if (voucherCodes.indexOf(data.voucherCode) < 0) {
          voucherCodes.push(data.voucherCode);
          await deviceStorage.setDeviceCacheItem(
            bar.id,
            CACHE_KEY_VOUCHER_CODES,
            voucherCodes
          );
        }
      }

      if (data?.remainingTotal && data.remainingTotal > 0) {
        this.setState({
          providerProcessingPayment: null,
          paymentProviderError: null
        });
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  };

  handleProviderError = (provider) => (error) => {
    const { t } = this.props;

    toast.error(
      `${t("label.something-went-wrong")} (${t(
        error ? error.message : "label.unknown-error"
      )})`
    );

    this.setState({
      providerProcessingPayment: null,
      paymentProviderError: error
    });
  };

  render() {
    const {
      classes,
      t,
      auth,
      isOpen,
      onClose,
      barLocator,
      bar,
      id,
      order,
      isWaiter,
      waiterCode,
      voucherCode
    } = this.props;
    const { providerProcessingPayment } = this.state;

    const onlyOneOnlinePaymentProviderAvailable =
      bar.getAvailableOnlinePaymentProvidersCount() === 1;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
        fullScreen
      >
        <DialogContent className={classes.content}>
          {order.isPartiallyPaid() && (
            <div className={classes.orderIsPartiallyPaidMessage}>
              <OrderIsPartiallyPaidMessage order={order} />
            </div>
          )}
          {/* {bar.isAllowingStarnetPayments() &&
            (!providerProcessingPayment ||
              providerProcessingPayment === PaymentProvider.STARNET) && (
              <div className={classes.contentCard}>
                <ContentCard
                  title={t("order.pay-with-starnet-title")}
                  subtitle={t("order.pay-with-starnet-subtitle")}
                  variant="outlined"
                  maxWidth={600}
                  isResponsive={false}
                >
                  <PayOrderWithStarnet
                    barLocator={barLocator}
                    bar={bar}
                    order={order}
                    onProcessing={this.handleProviderProcessingPayment(
                      PaymentProvider.STARNET
                    )}
                    onComplete={this.handleProviderComplete(
                      PaymentProvider.STARNET
                    )}
                    onError={this.handleProviderError(PaymentProvider.STARNET)}
                  />
                </ContentCard>
              </div>
            )} */}
          {
            // providerProcessingPayment !== PaymentProvider.STARNET &&
            providerProcessingPayment !== PaymentProvider.VOUCHER &&
            (bar.isAllowingPayconiqPayments() ||
              bar.isAllowingMolliePayments() ||
              bar.isAllowingStarnetPayments() ||
              bar.isAllowingCcvPayments() ||
              bar.isAllowingMultiSafePayPayments() ||
              bar.isAllowingVivaWalletOnlinePayments() ||
              bar.isAllowingDeferredPayments()) && (
              <div className={classes.contentCard}>
                <ContentCard
                  title={t("order.pay-online-title")}
                  subtitle={t("order.pay-online-subtitle")}
                  variant="outlined"
                  maxWidth={600}
                  isResponsive={false}
                >
                  <div className={classes.paymentOptions}>
                    {isWaiter ? (
                      <>
                        <div className={classes.paymentOption}>
                          <PayOrderCash
                            bar={bar}
                            order={order}
                            onProcessing={this.handleProviderProcessingPayment(
                              PaymentProvider.DEFERRED
                            )}
                            onComplete={this.handleProviderComplete(
                              PaymentProvider.DEFERRED
                            )}
                            onError={this.handleProviderError(
                              PaymentProvider.DEFERRED
                            )}
                            autoRedirect={
                              !bar.isAllowingVivaWalletPosPayments() ||
                              !AndroidWrapper.get()
                            }
                          />
                        </div>
                        {bar.isAllowingVivaWalletPosPayments() &&
                          (!providerProcessingPayment ||
                            providerProcessingPayment ===
                            PaymentProvider.VIVAWALLET) &&
                          AndroidWrapper.get() && (
                            <div className={classes.paymentOption}>
                              <PayOrderWithVivaWalletPos
                                auth={auth}
                                bar={bar}
                                id={id}
                                order={order}
                                waiterCode={waiterCode}
                                onProcessing={this.handleProviderProcessingPayment(
                                  PaymentProvider.VIVAWALLET
                                )}
                                onComplete={this.handleProviderComplete(
                                  PaymentProvider.VIVAWALLET
                                )}
                                onError={this.handleProviderError(
                                  PaymentProvider.VIVAWALLET
                                )}
                                autoRedirect={
                                  false // Cash payments are always available onlyOneOnlinePaymentProviderAvailable
                                }
                              />
                            </div>
                          )}
                      </>
                    ) : (
                      <div>
                        {bar.isAllowingPayconiqPayments() &&
                          (!providerProcessingPayment ||
                            providerProcessingPayment ===
                            PaymentProvider.PAYCONIQ) && (
                            <div className={classes.paymentOption}>
                              <PayOrderWithPayconiq
                                bar={bar}
                                order={order}
                                onProcessing={this.handleProviderProcessingPayment(
                                  PaymentProvider.PAYCONIQ
                                )}
                                onComplete={this.handleProviderComplete(
                                  PaymentProvider.PAYCONIQ
                                )}
                                onError={this.handleProviderError(
                                  PaymentProvider.PAYCONIQ
                                )}
                              />
                            </div>
                          )}
                        {bar.isAllowingMolliePayments() &&
                          (!providerProcessingPayment ||
                            providerProcessingPayment ===
                            PaymentProvider.MOLLIE) && (
                            <div className={classes.paymentOption}>
                              <PayOrderWithMollie
                                bar={bar}
                                id={id}
                                order={order}
                                onProcessing={this.handleProviderProcessingPayment(
                                  PaymentProvider.MOLLIE
                                )}
                                autoRedirect={
                                  onlyOneOnlinePaymentProviderAvailable
                                }
                              />
                            </div>
                          )}
                        {bar.isAllowingCcvPayments() &&
                          (!providerProcessingPayment ||
                            providerProcessingPayment ===
                            PaymentProvider.CCV) && (
                            <div className={classes.paymentOption}>
                              <PayOrderWithCcv
                                bar={bar}
                                id={id}
                                order={order}
                                onProcessing={this.handleProviderProcessingPayment(
                                  PaymentProvider.CCV
                                )}
                                autoRedirect={
                                  onlyOneOnlinePaymentProviderAvailable
                                }
                              />
                            </div>
                          )}
                        {bar.isAllowingMultiSafePayPayments() &&
                          (!providerProcessingPayment ||
                            providerProcessingPayment ===
                            PaymentProvider.MULTISAFEPAY) && (
                            <div className={classes.paymentOption}>
                              <PayOrderWithMultiSafePay
                                bar={bar}
                                id={id}
                                order={order}
                                onProcessing={this.handleProviderProcessingPayment(
                                  PaymentProvider.MULTISAFEPAY
                                )}
                                autoRedirect={
                                  onlyOneOnlinePaymentProviderAvailable
                                }
                              />
                            </div>
                          )}
                        {bar.isAllowingVivaWalletOnlinePayments() &&
                          (!providerProcessingPayment ||
                            providerProcessingPayment ===
                            PaymentProvider.VIVAWALLET) && (
                            <div className={classes.paymentOption}>
                              <PayOrderWithVivaWalletOnline
                                bar={bar}
                                id={id}
                                order={order}
                                onProcessing={this.handleProviderProcessingPayment(
                                  PaymentProvider.VIVAWALLET
                                )}
                                autoRedirect={
                                  onlyOneOnlinePaymentProviderAvailable
                                }
                              />
                            </div>
                          )}
                        {bar.isAllowingStarnetPayments() &&
                          (!providerProcessingPayment ||
                            providerProcessingPayment ===
                            PaymentProvider.STARNET) && (
                            <div className={classes.paymentOption}>
                              <PayOrderWithStarnet
                                bar={bar}
                                id={id}
                                order={order}
                                onProcessing={this.handleProviderProcessingPayment(
                                  PaymentProvider.STARNET
                                )}
                                autoRedirect={
                                  onlyOneOnlinePaymentProviderAvailable
                                }
                              />
                            </div>
                          )}
                        {bar.isAllowingDeferredPayments() &&
                          (!providerProcessingPayment ||
                            providerProcessingPayment ===
                            PaymentProvider.DEFERRED) && (
                            <div className={classes.paymentOption}>
                              {order.hasBeenQueued() ? (
                                <div>
                                  {onlyOneOnlinePaymentProviderAvailable && (
                                    <Typography>
                                      {t(
                                        "payment.no-payment-options-available"
                                      )}
                                    </Typography>
                                  )}
                                </div>
                              ) : (
                                <PayOrderDeferred
                                  bar={bar}
                                  order={order}
                                  onProcessing={this.handleProviderProcessingPayment(
                                    PaymentProvider.DEFERRED
                                  )}
                                  onComplete={this.handleProviderComplete(
                                    PaymentProvider.DEFERRED
                                  )}
                                  onError={this.handleProviderError(
                                    PaymentProvider.DEFERRED
                                  )}
                                  autoRedirect={
                                    onlyOneOnlinePaymentProviderAvailable
                                  }
                                />
                              )}
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </ContentCard>
              </div>
            )
          }
          {bar.isAllowingVoucherPayments() &&
            (!providerProcessingPayment ||
              providerProcessingPayment === PaymentProvider.VOUCHER) && (
              <div className={classes.contentCard}>
                <ContentCard
                  title={t("order.pay-with-voucher-title")}
                  subtitle={t("order.pay-with-voucher-subtitle")}
                  variant="outlined"
                  maxWidth={600}
                  isResponsive={false}
                >
                  <PayOrderWithVoucher
                    auth={auth}
                    bar={bar}
                    order={order}
                    voucherCode={voucherCode}
                    onProcessing={this.handleProviderProcessingPayment(
                      PaymentProvider.VOUCHER
                    )}
                    onComplete={this.handleProviderComplete(
                      PaymentProvider.VOUCHER
                    )}
                    onError={this.handleProviderError(PaymentProvider.VOUCHER)}
                    autoRedirect={onlyOneOnlinePaymentProviderAvailable}
                  />
                </ContentCard>
              </div>
            )}
        </DialogContent>
        <DialogActions>
          {providerProcessingPayment === null && (
            <Button onClick={onClose} color="secondary">
              {t("label.cancel")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(PayOrderDialog)
  )
);
