import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    top: 0,
    padding: "5px 0",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
});

class OrderWaiterPageMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.content}>
        <Typography>
          {t("order.waiter-page")}
          {"."}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderWaiterPageMessage));
