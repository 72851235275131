import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";

const styles = (theme) => ({
  totalContainer: {
    display: "flex",
    width: "100%",
    fontWeight: "bold"
  },
  totalLabel: {
    width: "100%"
  },
  totalAmount: {
    width: "100%",
    textAlign: "right"
  }
});

class OrderTotalLine extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, orderTotal, orderItemsAmount } = this.props;

    return (
      <Typography variant="body1" className={classes.totalContainer}>
        <span className={classes.totalLabel}>
          {t("order.total")}
          {orderItemsAmount !== undefined && (
            <span>
              {" "}
              ({orderItemsAmount}{" "}
              {t(orderItemsAmount === 1 ? "order.item" : "order.items")})
            </span>
          )}
        </span>
        <span
          className={classes.totalAmount}
        >{`${utilsHelper.formatToTwoDecimals(
          orderTotal
        )} ${bar.getOrderCurrency()}`}</span>
      </Typography>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderTotalLine));
