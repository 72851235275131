import React, { PureComponent } from "react";
import classNames from "classnames";

import { CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  stepIconCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 24,
    height: 24,
    borderRadius: "50%",
    color: "white"
  },
  stepIcon: {
    fontSize: 16
  },
  customStepIcon: {
    fontSize: 24
  }
});

class OrderStatusStepIcon extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, color, active, completed, error, icon, CustomIcon } =
      this.props;

    return (
      <div
        className={classes.stepIconCircle}
        style={{
          backgroundColor: completed ? color : active ? undefined : "grey"
        }}
      >
        {CustomIcon ? (
          <CustomIcon
            className={classNames(classes.stepIcon, classes.customStepIcon)}
            style={{ color }}
          />
        ) : completed ? (
          <CheckIcon className={classes.stepIcon} />
        ) : error ? (
          <ErrorIcon className={classes.stepIcon} />
        ) : active ? (
          <CircularProgress size={24} style={{ color }} />
        ) : (
          icon
        )}
      </div>
    );
  }
}

export default withStyles(styles)(OrderStatusStepIcon);
