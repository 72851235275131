import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { List, ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ScanQRCodeDialog from "tap-io/client/components/common/ScanQRCodeDialog";

import SelectZone from "../zone/SelectZone";
import SelectLocation from "../zone/SelectLocation";

const styles = (theme) => ({
  selectLocation: {
    width: "100%",
    marginTop: -6
  },
  buttonItem: {
    display: "block",
    textAlign: "center"
  },
  textButton: {
    cursor: "pointer",
    color: theme.palette.primary.main
  },
  smallSpacing: {
    height: 10
  },
  largeSpacing: {
    height: 40
  }
});

class SelectOrderZoneAndLayoutPath extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isScanQRCodeDialogOpen: false
    };
  }

  handleScanOrderName = () => {
    this.setState({ isScanQRCodeDialogOpen: true });
  };

  handleQRCodeScanned = (data) => {
    const { t, onOrderNameChange } = this.props;

    try {
      const indexOfOrderName = data.lastIndexOf("/");
      if (indexOfOrderName < 0 || indexOfOrderName === data.length - 1) {
        throw new Error("error.code-invalid");
      }

      const orderName = decodeURIComponent(data.substr(indexOfOrderName + 1));
      onOrderNameChange(orderName);

      this.setState({
        isScanQRCodeDialogOpen: false
      });
    } catch (error) {
      console.warn(error);
      toast.error(`${t("label.something-went-wrong")} (${t(error.message)})`);
    }
  };

  handleScanQRCodeDialogClose = () => {
    this.setState({ isScanQRCodeDialogOpen: false });
  };

  render() {
    const {
      classes,
      t,
      allZones,
      orderZone,
      orderLayoutPath,
      onOrderZoneChange,
      onOrderLayoutPathChange
    } = this.props;
    const { isScanQRCodeDialogOpen } = this.state;

    return (
      <div>
        <ScanQRCodeDialog
          isOpen={isScanQRCodeDialogOpen}
          onClose={this.handleScanQRCodeDialogClose}
          onScan={this.handleQRCodeScanned}
        />
        <List>
          <ListItem>
            <Typography variant="subtitle1" className={classes.title}>
              {t("order.select-zone")}
            </Typography>
          </ListItem>
          <ListItem>
            <SelectZone
              variant="filled"
              allZones={allZones}
              selectedZone={orderZone}
              onChange={onOrderZoneChange}
            />
          </ListItem>
          {orderZone && (
            <ListItem className={classes.selectLocation}>
              <SelectLocation
                variant="filled"
                layoutNode={orderZone.layout}
                layoutPath={orderLayoutPath}
                onSelect={onOrderLayoutPathChange}
              />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SelectOrderZoneAndLayoutPath)
);

/*
<div className={classes.smallSpacing} />
<ListItem className={classes.buttonItem}>
  {t("order.you-can-also-scan-your-zone-or-table-number-1")}{" "}
  <span
    className={classes.textButton}
    onClick={this.handleScanOrderName}
  >
    {t("order.you-can-also-scan-your-zone-or-table-number-2")}
  </span>
</ListItem>
*/
