import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { List, ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import MenuItem from "tap-io/client/components/menu/MenuItem";
import OrderFeesLines from "./OrderFeesLines";
import OrderTipLine from "./OrderTipLine";
import OrderVouchersLine from "./OrderVouchersLine";
import OrderDepositsLine from "./OrderDepositsLine";

const styles = (theme) => ({
  dividerLine: {
    margin: "10px 16px",
    height: 1,
    backgroundColor: "#ccc"
  },
  tooMuchItemsWarning: {
    color: "red"
  }
});

class OrderItemsStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(this.handleVerifyOrderItems);
  }

  handleVerifyOrderItems = () => {
    const { bar, orderItemsAmount } = this.props;

    if (orderItemsAmount === 0) {
      throw new Error("error.amount-of-items-is-zero");
    } else if (orderItemsAmount > bar.getMaxAmountOfItemsPerOrder()) {
      throw new Error(
        "error.amount-of-items-greater-than-max-amount-per-order"
      );
    }
  };

  handleOrderItemAmountChange = (id) => (menuItem, amount) => {
    const { onOrderItemAmountChange } = this.props;

    onOrderItemAmountChange(id, amount);
  };

  render() {
    const {
      classes,
      t,
      allowOrderChange,
      bar,
      vouchers,
      orderItems,
      orderItemsAmount,
      orderItemsTotal,
      orderDistributedDeposits,
      orderDepositsAmount,
      orderDepositsTotal,
      orderTip,
      orderZone,
      orderLayoutPath,
      orderServiceOption,
      orderFees,
      orderTotal,
      orderVoucherIds,
      onOrderTipChange,
      onOrderVoucherIdsChange
    } = this.props;

    const maxAmountOfItemsPerOrder = bar.getMaxAmountOfItemsPerOrder();

    return (
      <List>
        {orderItemsAmount > maxAmountOfItemsPerOrder && (
          <ListItem>
            <Typography variant="body1" className={classes.tooMuchItemsWarning}>
              {`${t("order.you-can-only-select-x-items-1")} `}
              <strong>
                {`${t("order.you-can-only-select-x-items-2", {
                  maxAmountOfItemsPerOrder
                })} ${t(
                  maxAmountOfItemsPerOrder === 1 ? "order.item" : "order.items"
                )}`}
              </strong>
              {` ${t("order.you-can-only-select-x-items-3")}`}
              <br />
              <strong>
                {`${t("order.remove-x-items-to-complete-your-order-1", {
                  amountOfItemsToRemove:
                    orderItemsAmount - maxAmountOfItemsPerOrder
                })} ${t(
                  orderItemsAmount - maxAmountOfItemsPerOrder === 1
                    ? "order.item"
                    : "order.items"
                )}`}
              </strong>
              {` ${t("order.remove-x-items-to-complete-your-order-2")}`}
            </Typography>
          </ListItem>
        )}
        {orderItems &&
          Object.keys(orderItems).map((id) => (
            <MenuItem
              key={id}
              isDisabled={!allowOrderChange}
              bar={bar}
              item={orderItems[id].menuItem}
              amount={orderItems[id].amount}
              onAmountChange={this.handleOrderItemAmountChange(id)}
              isCompact={true}
            />
          ))
        }
        {orderDepositsAmount > 0 &&
          <OrderDepositsLine
            bar={bar}
            orderDepositsAmount={orderDepositsAmount}
            orderDepositsTotal={orderDepositsTotal}
          />
        }
        {(orderFees?.length > 0 ||
          bar.isAllowingTips() ||
          vouchers?.length > 0) && <div className={classes.dividerLine} />}
        {orderFees && orderFees.length > 0 && (
          <OrderFeesLines
            bar={bar}
            orderItemsTotal={orderItemsTotal}
            orderServiceOption={orderServiceOption}
            orderZone={orderZone}
            orderLayoutPath={orderLayoutPath}
            orderFees={orderFees}
          />
        )}
        {bar.isAllowingTips() && (
          <OrderTipLine
            bar={bar}
            orderItemsTotal={orderItemsTotal}
            orderTip={orderTip}
            onOrderTipChange={onOrderTipChange}
          />
        )}
        <OrderVouchersLine
          allowOrderChange={allowOrderChange}
          bar={bar}
          vouchers={vouchers}
          orderTotal={orderTotal}
          orderItems={orderItems}
          orderVoucherIds={orderVoucherIds}
          onOrderVoucherIdsChange={onOrderVoucherIdsChange}
        />
      </List>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderItemsStep));
