import React, { PureComponent } from "react";

import handleViewport from "react-in-viewport";

import withStyles from "@mui/styles/withStyles";

import Ads from "./Ads";

const styles = (theme) => ({});

class AdsInViewport extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { inViewport, onInitialViewport } = this.props;

    onInitialViewport(inViewport);
  }

  render() {
    const { classes, height, ads, inViewport } = this.props;

    return <Ads height={height} ads={ads} />;
  }
}

export default withStyles(styles)(
  handleViewport(AdsInViewport, { threshold: 0 })
);
