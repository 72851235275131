import React, { PureComponent } from "react";

import SelectOrderZoneDrawer from "./SelectOrderZoneDrawer";
import SelectOrderDeliveryDrawer from "./SelectOrderDeliveryDrawer";

class SelectOrderPrerequisitesDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      isOpen,
      onClose,
      bar,
      bases,
      allZones,
      activeFees,
      orderZone,
      orderLayoutPath,
      orderServiceOption,
      onOrderZoneChange,
      onOrderLayoutPathChange,
      onOrderNameChange,
      onOrderServiceOptionChange
    } = this.props;

    return bar.isUsingZones() ? (
      <SelectOrderZoneDrawer
        isOpen={isOpen}
        onClose={onClose}
        bar={bar}
        bases={bases}
        activeFees={activeFees}
        allZones={allZones}
        orderZone={orderZone}
        orderLayoutPath={orderLayoutPath}
        orderServiceOption={orderServiceOption}
        onOrderZoneChange={onOrderZoneChange}
        onOrderLayoutPathChange={onOrderLayoutPathChange}
        onOrderNameChange={onOrderNameChange}
        onOrderServiceOptionChange={onOrderServiceOptionChange}
      />
    ) : (
      <SelectOrderDeliveryDrawer
        isOpen={isOpen}
        onClose={onClose}
        bar={bar}
        bases={bases}
        activeFees={activeFees}
        orderServiceOption={orderServiceOption}
        onOrderServiceOptionChange={onOrderServiceOptionChange}
      />
    );
  }
}

export default SelectOrderPrerequisitesDrawer;
