import React, { PureComponent } from "react";

import { Helmet } from "react-helmet";

import withStyles from "@mui/styles/withStyles";

import BarHeader from "./BarHeader";
import BarContent from "./BarContent";
import BarFooter from "./BarFooter";

const styles = (theme) => ({});

class BarSetting extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      headerProps: {
        isLarge: true,
        height: 0
      }
    };
  }

  handleHeaderUpdate = (headerProps) => {
    this.setState({ headerProps });
  };

  render() {
    const { timeOffset, locale, setLocale, bar, assets, activeFees, activeDeposits, allZones } =
      this.props;
    const { headerProps } = this.state;

    return bar ? (
      <div>
        <Helmet htmlAttributes={{ lang: bar.getOrderLang() }}>
          {bar && bar.name && <title>{bar.name}</title>}
          <meta
            name="theme-color"
            content={bar ? bar.getPrimaryColor() : "#fafafa"}
          />
        </Helmet>
        <BarHeader
          bar={bar}
          assets={assets}
          onUpdate={this.handleHeaderUpdate}
        />
        <BarContent
          headerProps={headerProps}
          timeOffset={timeOffset}
          locale={locale}
          bar={bar}
          assets={assets}
          activeFees={activeFees}
          activeDeposits={activeDeposits}
          allZones={allZones}
        />
        <BarFooter locale={locale} setLocale={setLocale} />
      </div>
    ) : null;
  }
}

export default withStyles(styles)(BarSetting);
