import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

//import Moment from "moment";

import {
  Button,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import config from "tap-io/client/env";
import { deliveryHelper, orderHelper, utilsHelper } from "tap-io/helpers";
import OrderItems from "tap-io/client/components/order/OrderItems";

import OrderStatusAsSoonAsPossible from "./OrderStatusAsSoonAsPossible";
import OrderStatusOnDemand from "./OrderStatusOnDemand";
import OrderConfirmationCode from "./OrderConfirmationCode";
import OrderTotalForItemsLine from "./OrderTotalForItemsLine";
import OrderStatusAutomatic from "./OrderStatusAutomatic";
import OrderDepositsLine from "./OrderDepositsLine";

const styles = (theme) => ({
  fontWeightNormal: {
    fontWeight: "normal"
  },
  spacing: {
    height: 15
  },
  toggleShowOrderDetailsButton: {
    fontSize: 12
  },
  dividerLine: {
    margin: "10px 16px",
    height: 1,
    backgroundColor: "#ccc"
  },
  errorIcon: {
    color: theme.palette.error.main
  }
});

//const STARNET_GRACE_PERIOD_IN_MINUTES = 2;
const REFRESH_ESTIMATED_TIME_REMAINING_INTERVAL = 5000;

class OrderStatusCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      orderUrl: null,
      shouldPreparationBeComplete: false,
      estimatedRemainingPreparationTimeInMinutes: null,
      //showStarnetPushError: false,
      allowPulse: true,
      showOrderDetails: false
    };
  }

  componentDidMount() {
    //const { order } = this.props;

    // TO FIX

    // const areSubOrdersSent = this.areSubOrdersSent();

    // if (!areSubOrdersSent) {
    //   const hasExceededStarnetGracePeriod =
    //     !order.payment ||
    //     Moment().diff(order.payment.timestamp, "minutes") >=
    //       STARNET_GRACE_PERIOD_IN_MINUTES;

    //   if (hasExceededStarnetGracePeriod) {
    //     this.setState({ showStarnetPushError: true });
    //   } else {
    //     setTimeout(() => {
    //       if (!this.areSubOrdersSent()) {
    //         this.setState({ showStarnetPushError: true });
    //       }
    //     }, STARNET_GRACE_PERIOD_IN_MINUTES * 60000);
    //   }
    // }

    this.refreshOrderUrl();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { bar, base, order } = this.props;
    //const { showStarnetPushError } = this.state;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;
    const orderId = order ? order.id : undefined;
    const prevOrderId = prevProps.order ? prevProps.order.id : undefined;

    if (barId !== prevBarId || orderId !== prevOrderId) {
      this.refreshOrderUrl();
    }

    // if (showStarnetPushError) {
    //   const areSubOrdersSent = this.areSubOrdersSent();

    //   if (areSubOrdersSent) {
    //     this.setState({ showStarnetPushError: false });
    //   }
    // }

    if (order.isFulfilmentMethodAsSoonAsPossible) {
      if (order.isStatusClaimed(base)) {
        if (!this.refreshEstimatedPreparationTimeInterval) {
          this.refreshEstimatedPreparationTimeInterval = setInterval(
            this.refreshEstimatedPreparationTime,
            REFRESH_ESTIMATED_TIME_REMAINING_INTERVAL
          );

          this.refreshEstimatedPreparationTime();
        }
      } else {
        if (this.refreshEstimatedPreparationTimeInterval) {
          clearInterval(this.refreshEstimatedPreparationTimeInterval);
          this.refreshEstimatedPreparationTimeInterval = undefined;
        }

        this.setState({
          shouldPreparationBeComplete: false,
          estimatedRemainingPreparationTimeInMinutes: null
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.refreshEstimatedPreparationTimeInterval) {
      clearInterval(this.refreshEstimatedPreparationTimeInterval);
      this.refreshEstimatedPreparationTimeInterval = undefined;
    }
  }

  refreshOrderUrl = async () => {
    const { bar, order } = this.props;

    let orderUrl = null;

    if (bar && order) {
      const barUrl = await barService.getBarUrl(
        bar,
        config.hosting.orderDomain
      );
      orderUrl = orderHelper.getOrderUrl(barUrl, order.id);
    }

    this.setState({ orderUrl });
  };

  refreshEstimatedPreparationTime = async () => {
    const { timeOffset, base, order } = this.props;

    const calculateRemainingTime = (date) =>
      utilsHelper.calculateRemainingTime(date, timeOffset);

    const shouldPreparationBeComplete = order.shouldPreparationBeComplete(
      calculateRemainingTime,
      base
    );
    const estimatedRemainingPreparationTimeInMinutes =
      order.getEstimatedRemainingPreparationTime(calculateRemainingTime, base);

    this.setState({
      shouldPreparationBeComplete,
      estimatedRemainingPreparationTimeInMinutes
    });
  };

  areSubOrdersSent = () => {
    const { order, base } = this.props;

    return order.hasSubOrders
      ? base
        ? order.subOrders.getForBase(base.id)?.isSent
        : order.subOrders.areAllSent
      : true;
  };

  handleToggleShowOrderDetails = (event) => {
    event.stopPropagation();

    const { showOrderDetails } = this.state;

    this.setState({ showOrderDetails: !showOrderDetails });
  };

  handleStopPulseAndShowDeliveryConfirmation = (event) => {
    this.setState({
      allowPulse: false
    });

    if (this.showDeliveryConfirmation) {
      this.showDeliveryConfirmation();
    }
  };

  render() {
    const { classes, t, bar, order, base, allZones } = this.props;
    const {
      orderUrl,
      shouldPreparationBeComplete,
      estimatedRemainingPreparationTimeInMinutes,
      showStarnetPushError,
      allowPulse,
      showOrderDetails
    } = this.state;

    const deliveryColor = utilsHelper.convertHexColorToRGBColor(
      deliveryHelper.getDeliveryColorValue(order.getDeliveryColor(base))
    );

    const isCustomerActionNeeded =
      order.isDeliveryMethodPickup &&
      (order.isFulfilmentMethodOnDemand
        ? order.isStatusQueued(base)
        : shouldPreparationBeComplete);

    return (
      <Card
        className={
          allowPulse && isCustomerActionNeeded ? "pulsatingBorder" : undefined
        }
        style={{
          "--border-color-r":
            deliveryColor && deliveryColor.r ? deliveryColor.r : 0,
          "--border-color-g":
            deliveryColor && deliveryColor.g ? deliveryColor.g : 0,
          "--border-color-b":
            deliveryColor && deliveryColor.b ? deliveryColor.b : 0
        }}
        onClick={
          isCustomerActionNeeded
            ? this.handleStopPulseAndShowDeliveryConfirmation
            : undefined
        }
      >
        <CardContent>
          {showStarnetPushError ? (
            <div>
              <ErrorIcon className={classes.errorIcon} />
              <Typography>
                {t("order.failed-to-send-order-to-starnet-contact-bar-staff")}
              </Typography>
              <div className={classes.spacing} />
            </div>
          ) : order.isFulfilmentMethodOnDemand ? (
            <OrderStatusOnDemand
              bar={bar}
              order={order}
              base={base}
              allZones={allZones}
              orderUrl={orderUrl}
              setShowDeliveryConfirmation={(showDeliveryConfirmation) =>
                (this.showDeliveryConfirmation = showDeliveryConfirmation)
              }
            />
          ) : order.isFulfilmentMethodAutomatic ? (
            <OrderStatusAutomatic
              bar={bar}
              order={order}
              base={base}
              allZones={allZones}
              orderUrl={orderUrl}
              setShowDeliveryConfirmation={(showDeliveryConfirmation) =>
                (this.showDeliveryConfirmation = showDeliveryConfirmation)
              }
            />
          ) : (
            <OrderStatusAsSoonAsPossible
              bar={bar}
              order={order}
              base={base}
              allZones={allZones}
              orderUrl={orderUrl}
              setShowDeliveryConfirmation={(showDeliveryConfirmation) =>
                (this.showDeliveryConfirmation = showDeliveryConfirmation)
              }
              shouldPreparationBeComplete={shouldPreparationBeComplete}
              estimatedRemainingPreparationTimeInMinutes={
                estimatedRemainingPreparationTimeInMinutes
              }
            />
          )}
          <div className={classes.spacing} />
          {showOrderDetails ? (
            <div>
              <OrderItems order={order} base={base} />
              <div className={classes.dividerLine} />
              <OrderTotalForItemsLine
                bar={bar}
                orderItemsAmount={order.getTotalAmount(base)}
                orderItemsTotal={order.getTotal(base)}
              />
              {order.deposits.hasDistributed &&
                <OrderDepositsLine
                  bar={bar}
                  orderDepositsAmount={order.deposits.distributedAmount}
                  orderDepositsTotal={order.deposits.distributedTotal}
                />
              }
              {order.deposits.hasCollected &&
                <OrderDepositsLine
                  bar={bar}
                  orderDepositsAmount={order.deposits.collectedAmount}
                  orderDepositsTotal={-order.deposits.collectedTotal}
                />
              }
              <div className={classes.spacing} />
              <Button
                size="small"
                variant="contained"
                color="secondary"
                className={classes.toggleShowOrderDetailsButton}
                onClick={this.handleToggleShowOrderDetails}
              >
                {t("order.hide-order-details")}
              </Button>
            </div>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              className={classes.toggleShowOrderDetailsButton}
              onClick={this.handleToggleShowOrderDetails}
            >
              {t("order.show-order-details")}
            </Button>
          )}
          <div className={classes.spacing} />
          <OrderConfirmationCode order={order} base={base} />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderStatusCard));

/*
<CardHeader
  title={
    <Typography variant="h6">
      <span className={classes.fontWeightNormal}>
        {t(
          order.isDeliveryMethodPickup
            ? base
              ? "delivery.pick-up-at"
              : "delivery.pickup"
            : base
            ? "delivery.served-by"
            : "delivery.serve"
        )}
      </span>{" "}
      {base ? base.name : ""}
    </Typography>
  }
/>
*/
