import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";

const styles = (theme) => ({
  title: {
    overflow: "hidden",
    height: 20,
    lineHeight: "20px",
    fontSize: 14,
    fontWeight: "bold",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: theme.palette.tertiary.contrastText
  }
});

class BarTitle extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, color, align } = this.props;

    return (
      <div
        className={classNames("BoBarTitle", classes.title)}
        style={{ color, textAlign: align || "center" }}
      >
        {config.product.tagline || bar.name}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(BarTitle));
