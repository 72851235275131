import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import withStyles from "@mui/styles/withStyles";

import AuthorizedAppLink from "../auth/AuthorizedAppLink";

const styles = (theme) => ({
  button: {
    margin: 20
  }
});

class PayOrderWithStarnet extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handlePaymentLinkClick = () => {
    const { onProcessing } = this.props;

    if (onProcessing) {
      onProcessing();
    }
  };

  render() {
    const { classes, t, bar, id, order, autoRedirect } = this.props;

    return (
      <div className={classes.button}>
        <AuthorizedAppLink
          bar={bar}
          endpoint="public/payment"
          params={{
            provider: "starnet",
            kind: "order",
            orderId: order.id
          }}
          target="_self"
          variant="outlined"
          size="large"
          color="primary"
          text={t("payment.pay-with-starnet")}
          icon={<CreditCardIcon />}
          autoRedirect={autoRedirect}
          onClick={this.handlePaymentLinkClick}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(PayOrderWithStarnet)
);

// import React, { PureComponent } from "react";
// import classNames from "classnames";

// import { withTranslation } from "react-i18next";

// import { toast } from "react-toastify";

// import {
//   Button,
//   CircularProgress,
//   InputAdornment,
//   TextField,
//   Typography
// } from "@mui/material";
// import CheckIcon from "@mui/icons-material/Check";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import withStyles from "@mui/styles/withStyles";

// import config from "tap-io/client/env";
// import { integrationService } from "tap-io/client/services";
// import { deviceStorage } from "tap-io/storage";
// import { integrationHelper, utilsHelper } from "tap-io/helpers";

// import withAuthorization from "../auth/withAuthorization";
// import { CACHE_KEY_STARNET_CARD } from "../../constants/cache";
// import InitialiseStarnetCardDialog from "./InitialiseStarnetCardDialog";

// const styles = (theme) => ({
//   fields: {
//     display: "flex",
//     flexDirection: "column",
//     marginBottom: 10,
//     textAlign: "center"
//   },
//   field: {},
//   cardNumberField: {},
//   cardPinField: {},
//   smallSpacing: {
//     height: 15
//   },
//   largeSpacing: {
//     height: 30
//   },
//   center: {
//     textAlign: "center"
//   },
//   link: {
//     textDecoration: "none"
//   },
//   disabledLink: {
//     pointerEvents: "none",
//     cursor: "default"
//   },
//   accentuatedText: {
//     color: theme.palette.error.main
//   }
// });

// class PayOrderWithStarnet extends PureComponent {
//   constructor(props) {
//     super(props);

//     this.state = {
//       isInitialiseStarnetCardDialogOpen: false,
//       cardNumber: "",
//       cardPin: "",
//       cardInfo: null,
//       paymentError: null
//     };
//   }

//   componentDidMount() {
//     this.checkStarnetCardInStorage();
//   }

//   checkStarnetCardInStorage = async () => {
//     const { bar } = this.props;

//     const starnetCard = await deviceStorage.getDeviceCacheItem(
//       bar.id,
//       CACHE_KEY_STARNET_CARD
//     );

//     if (starnetCard && starnetCard.number) {
//       const cardNumber = this.parseCardNumber(starnetCard.number);
//       this.setState({ cardNumber });

//       if (this.isCardNumberValid(cardNumber)) {
//         await this.refreshCardInfo(cardNumber);
//       }
//     }
//   };

//   saveStarnetCardToStorage = async () => {
//     const { bar } = this.props;
//     const { cardNumber, cardPin } = this.state;

//     const starnetCard = {
//       number: cardNumber
//       //pin: cardPin
//     };

//     await deviceStorage.setDeviceCacheItem(
//       bar.id,
//       CACHE_KEY_STARNET_CARD,
//       starnetCard
//     );
//   };

//   parseCardNumber = (cardNumber) => {
//     return cardNumber.replace(/[^0-9]/g, "").substr(0, 9);
//   };

//   parseCardPin = (cardPin) => {
//     return cardPin.replace(/[^0-9]/g, "").substr(0, 4);
//   };

//   handleCardNumberChange = (event) => {
//     const cardNumber = this.parseCardNumber(event.target.value);

//     this.setState({
//       cardNumber
//     });

//     if (this.isCardNumberValid(cardNumber)) {
//       this.refreshCardInfo(cardNumber);
//     } else {
//       this.resetCardInfo();
//     }
//   };

//   handlePinChange = (event) => {
//     this.setState({
//       cardPin: this.parseCardPin(event.target.value)
//     });
//   };

//   isCardNumberValid = (cardNumber) => {
//     return (
//       cardNumber &&
//       (cardNumber.substr(0, 1) === "0"
//         ? cardNumber.length === 9
//         : cardNumber.length === 8)
//     );
//   };

//   isCardPinValid = (cardPin) => {
//     return cardPin && cardPin.length >= 4;
//   };

//   isCardValid = () => {
//     const { cardNumber, cardPin, cardInfo } = this.state;

//     return (
//       this.isCardNumberValid(cardNumber) &&
//       (this.isCardPinValid(cardPin) || (cardInfo && !cardInfo.isInitialised))
//     );
//   };

//   getOrderEmail = () => {
//     const { order } = this.props;

//     return order.contactAddresses.find((p) => p.email)?.email || "";
//   };

//   refreshCardInfo = async (cardNumber) => {
//     const { t, auth, bar } = this.props;

//     if (this.getCardInfoPromise) {
//       this.getCardInfoPromise.cancel();
//       this.getCardInfoPromise = undefined;
//     }

//     if (!auth || !auth.user || !auth.user.uid) {
//       return toast.error(
//         `${t("label.error")}: ${t("error.no-valid-user-found")}`
//       );
//     }

//     this.setState({ isRefreshingCardInfo: true });

//     try {
//       this.getCardInfoPromise = utilsHelper.makePromiseCancelable(
//         integrationService.getStarnetCardInfo(
//           config.functions.integrationsApi,
//           auth.user,
//           bar.id,
//           cardNumber
//         )
//       );

//       const cardInfo = await this.getCardInfoPromise.promise;

//       this.setState({
//         isRefreshingCardInfo: false,
//         cardInfo
//       });
//     } catch (error) {
//       console.warn(error);

//       this.setState({ isRefreshingCardInfo: false, cardInfo: null });
//       toast.error(
//         `${t("label.something-went-wrong")} (${t(
//           error ? error.message : "error.unknown-error"
//         )})`
//       );
//     }
//   };

//   resetCardInfo = () => {
//     this.setState({
//       cardInfo: null
//     });
//   };

//   handlePay = async (emailAddress) => {
//     const { t, auth, bar, order, onProcessing, onComplete, onError } =
//       this.props;
//     const { cardInfo, cardNumber, cardPin } = this.state;

//     if (!auth || !auth.user || !auth.user.uid) {
//       return toast.error(
//         `${t("label.error")}: ${t("error.no-valid-user-found")}`
//       );
//     }

//     this.setState({ isLoading: true, paymentError: null });

//     try {
//       if (onProcessing) {
//         onProcessing();
//       }

//       if (!cardPin && (!cardInfo || cardInfo.isInitialised)) {
//         throw new Error(
//           "error.pin-is-not-defined-while-card-can-not-be-initialised"
//         );
//       }

//       const data = await integrationService.payOrderWithStarnet(
//         config.functions.integrationsApi,
//         auth.user,
//         bar.id,
//         order.id,
//         cardNumber,
//         cardPin || undefined,
//         emailAddress || undefined
//       );

//       await this.saveStarnetCardToStorage();

//       this.setState({ isLoading: false, cardNumber: "", cardPin: "" }, () => {
//         onComplete(data);
//       });
//     } catch (error) {
//       console.warn(error);

//       const paymentError =
//         error && error.message
//           ? error.message === "error.starnet-card-balance-is-insufficient" &&
//             error.data &&
//             error.data.insufficientBalance
//             ? t("error.starnet-card-balance-is-insufficient-x", {
//                 insufficientBalance: error.data.insufficientBalance
//               })
//             : t(error.message)
//           : t("error.unknown-error");

//       this.setState({ isLoading: false, pin: "", paymentError }, () => {
//         onError(error);
//       });

//       // Probably cardInfo is outdated, get latest version
//       if (paymentError === "error.pin-is-set-for-card") {
//         await this.refreshCardInfo(cardNumber);
//       }
//     }
//   };

//   handleInitialiseAndPay = () => {
//     this.setState({ isInitialiseStarnetCardDialogOpen: true });
//   };

//   handleInitialiseStarnetCardDialogConfirm = (data) => {
//     const { emailAddress } = data;
//     this.setState({ isInitialiseStarnetCardDialogOpen: false });

//     this.handlePay(emailAddress);
//   };

//   handleInitialiseStarnetCardDialogCancel = () => {
//     this.setState({ isInitialiseStarnetCardDialogOpen: false });
//   };

//   render() {
//     const { classes, t, barLocator, order } = this.props;
//     const {
//       isInitialiseStarnetCardDialogOpen,
//       isLoading,
//       isRefreshingCardInfo,
//       cardNumber,
//       cardPin,
//       cardInfo,
//       paymentError
//     } = this.state;

//     return (
//       <div>
//         <InitialiseStarnetCardDialog
//           isOpen={isInitialiseStarnetCardDialogOpen}
//           cardNumber={cardNumber}
//           emailAddress={this.getOrderEmail()}
//           onConfirm={this.handleInitialiseStarnetCardDialogConfirm}
//           onCancel={this.handleInitialiseStarnetCardDialogCancel}
//         />
//         <div className={classes.fields}>
//           <TextField
//             fullWidth
//             className={classes.field}
//             margin="dense"
//             label={t("payment.starnet-card-number")}
//             error={!this.isCardNumberValid(cardNumber)}
//             disabled={isRefreshingCardInfo || isLoading}
//             value={cardNumber}
//             onChange={this.handleCardNumberChange}
//             inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   {isRefreshingCardInfo ? (
//                     <CircularProgress size={20} color="primary" />
//                   ) : this.isCardNumberValid(cardNumber) && cardInfo ? (
//                     <CheckIcon />
//                   ) : (
//                     <ErrorOutlineIcon />
//                   )}
//                 </InputAdornment>
//               )
//             }}
//           />
//           {this.isCardNumberValid(cardNumber) && cardInfo && (
//             <div>
//               {cardInfo.isInitialised && (
//                 <TextField
//                   fullWidth
//                   className={classes.field}
//                   type="password"
//                   margin="dense"
//                   label={t("payment.starnet-card-pin")}
//                   disabled={isLoading}
//                   value={cardPin}
//                   onChange={this.handlePinChange}
//                   inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
//                 />
//               )}
//               <div className={classes.smallSpacing} />
//               <Button
//                 fullWidth
//                 variant="outlined"
//                 size="large"
//                 color="primary"
//                 startIcon={isLoading ? <CircularProgress size={30} /> : null}
//                 disabled={!this.isCardValid() || isLoading}
//                 onClick={
//                   cardInfo.isInitialised
//                     ? this.handlePay
//                     : this.handleInitialiseAndPay
//                 }
//               >
//                 {t("payment.pay-with-starnet")}
//               </Button>
//               {paymentError && (
//                 <div>
//                   <div className={classes.smallSpacing} />
//                   <Typography className={classes.accentuatedText}>
//                     {paymentError}
//                   </Typography>
//                 </div>
//               )}
//               {cardInfo.isInitialised && (
//                 <div>
//                   <div className={classes.largeSpacing} />
//                   <div className={classes.center}>
//                     <a
//                       href={integrationHelper.generateStarnetPinResetUrl(
//                         config.links.starnetPinReset,
//                         barLocator,
//                         order ? order.id : undefined,
//                         cardNumber
//                       )}
//                       target="_blank"
//                       rel="noreferrer"
//                       className={classNames(classes.link, {
//                         [classes.disabledLink]: isLoading
//                       })}
//                     >
//                       <Button
//                         disabled={isLoading}
//                         size="small"
//                         variant="contained"
//                         color="secondary"
//                       >
//                         {t("payment.reset-pin-code")}
//                       </Button>
//                     </a>
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//         <div className={classes.smallSpacing} />
//         <Typography variant="caption">
//           {t("payment.pay-with-starnet-help")}
//         </Typography>
//       </div>
//     );
//   }
// }

// export default withAuthorization()(
//   withStyles(styles, { withTheme: true })(
//     withTranslation("common")(PayOrderWithStarnet)
//   )
// );
