import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import { IconButton, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import withStyles from "@mui/styles/withStyles";

import ShowVoucherDialog from "./ShowVoucherDialog";

const styles = (theme) => ({
  container: {
    position: "relative"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 200,
    padding: 10,
    borderRadius: 10,
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  remainingValue: {
    fontWeight: "bold"
  },
  validUntil: {
    fontSize: 10
  },
  removeButton: {
    position: "absolute",
    top: -10,
    right: -10,
    padding: 0,
    fontSize: 28,
    backgroundColor: "white !important"
  }
});

class VoucherButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isShowVoucherDialogOpen: false
    };
  }

  handleShowVoucherLog = () => {
    this.setState({ isShowVoucherDialogOpen: true });
  };

  handleShowVoucherDialogClose = () => {
    this.setState({ isShowVoucherDialogOpen: false });
  };

  render() {
    const { classes, bar, t, voucher, onRemove } = this.props;
    const { isShowVoucherDialogOpen } = this.state;

    return (
      <div className={classes.container}>
        <ShowVoucherDialog
          isOpen={isShowVoucherDialogOpen}
          onClose={this.handleShowVoucherDialogClose}
          bar={bar}
          voucher={voucher}
        />
        <div className={classes.content} onClick={this.handleShowVoucherLog}>
          <Typography className={classes.remainingValue}>
            {voucher.label && <span>{voucher.label} &bull; </span>}
            {voucher.remainingValueToString(t)}
          </Typography>
          {voucher.isExpired ? (
            <Typography variant="caption" className={classes.validUntil}>
              {`${t("voucher.voucher-is-expired")} `}
            </Typography>
          ) : (
            <Typography variant="caption" className={classes.validUntil}>
              {`${t("voucher.voucher-valid-until")} `}
              <ReactMoment format="DD/MM/YYYY, HH:mm">
                {voucher.validUntil}
              </ReactMoment>
            </Typography>
          )}
        </div>
        {onRemove && (
          <IconButton
            color="primary"
            className={classes.removeButton}
            onClick={onRemove}
            size="large"
          >
            <HighlightOffIcon fontSize="inherit" />
          </IconButton>
        )}
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(VoucherButton));
