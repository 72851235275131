import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({});

class SelectZone extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedZone: null
    };
  }

  componentDidMount() {
    const { allZones, selectedZone, onChange } = this.props;

    if (selectedZone) {
      this.setState({ selectedZone });
    } else if (allZones && allZones.length === 1) {
      // Automatically select zone if there is only one (and selectedZone is not defined)
      onChange(allZones[0]);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedZone } = this.props;

    const selectedZoneCode = selectedZone ? selectedZone.code : undefined;
    const prevSelectedZone = prevProps.selectedZone
      ? prevProps.selectedZone.code
      : undefined;

    if (selectedZoneCode && selectedZoneCode !== prevSelectedZone) {
      this.setState({ selectedZone });
    }
  }

  handleChange = (event) => {
    const { onChange, allZones } = this.props;

    const zoneCode = event.target.value;

    const foundZones = allZones.filter((zone) => zone.code === zoneCode);

    if (foundZones.length !== 1) {
      throw new Error("error.could-not-find-zone");
    }

    onChange(foundZones[0]);
  };

  render() {
    const { t, variant, isDisabled, allZones } = this.props;
    const { selectedZone } = this.state;

    return (
      <FormControl fullWidth variant={variant}>
        <InputLabel id="select-zone">{t("label.zone")}</InputLabel>
        <Select
          disabled={isDisabled}
          value={selectedZone ? selectedZone.code : ""}
          onChange={this.handleChange}
          label={t("zone.zone")}
          autoComplete="chrome-off"
          fullWidth
        >
          {allZones &&
            allZones.map((zone) => (
              <MenuItem key={zone.code} value={zone.code}>
                {zone.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SelectZone));
