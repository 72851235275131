import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button, CircularProgress } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import withStyles from "@mui/styles/withStyles";

import { authHelper } from "../../../helpers";

const styles = (theme) => ({
  fab: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    display: "flex",
    marginRight: theme.spacing(1)
  }
});

class AuthorizedLink extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { isDisabled: false };
  }

  componentDidMount() {
    const { autoRedirect } = this.props;

    if (autoRedirect) {
      setTimeout(this.handleOpenLink, 100);
    }
  }

  handleOpenLink = async (event) => {
    const { t, auth, bar, link, params, target, onClick } = this.props;

    const generateAndOpenLink = async () => {
      try {
        const authorizedLink = await authHelper.generateAuthorizedLink(
          auth.user,
          link,
          { barId: bar.id, ...params }
        );
        window.open(authorizedLink, target ? target : "_blank");

        if (target !== "_self") {
          this.setState({ isDisabled: false });
        }
      } catch (error) {
        console.warn(error);

        this.setState({ isDisabled: false });
        toast.error(error ? error.message : t("error.unknown-error"));
      }

      if (onClick) {
        onClick(event);
      }
    };

    if (auth && auth.user) {
      this.setState({ isDisabled: true }, () => {
        // Wait for the UI to show
        setTimeout(generateAndOpenLink, 200);
      });
    } else {
      toast.error(t("error.no-authorized-user-found"));
    }
  };

  render() {
    const { classes, size, color, text, icon, variant, fullWidth } = this.props;
    const { isDisabled } = this.state;

    return (
      <Button
        className={classes.fab}
        onClick={this.handleOpenLink}
        variant={variant}
        disabled={this.props.isDisabled || isDisabled}
        size={size}
        color={color}
        fullWidth={fullWidth}
      >
        <div className={classes.extendedIcon}>
          {isDisabled ? (
            <CircularProgress size={30} />
          ) : icon === null ? undefined : icon ? (
            icon
          ) : (
            <ArrowDownwardIcon />
          )}
        </div>
        {text}
      </Button>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(AuthorizedLink)
);
