import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItemText, Switch } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { cookieHelper } from "../../helpers";

const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: `0 ${theme.spacing(2)}`
  }
});

class PreferencesConsentToggle extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasPreferencesConsent: cookieHelper.getConsent().hasPreferencesConsent
    };
  }

  componentDidMount() {
    this.unsubscribeOnConsentReady = cookieHelper.onConsentReady(
      this.handleOnConsentReady
    );
  }

  componentWillUnmount() {
    if (this.unsubscribeOnConsentReady) {
      this.unsubscribeOnConsentReady();
      this.unsubscribeOnConsentReady = undefined;
    }
  }

  handleOnConsentReady = (consent) => {
    this.setState({ hasPreferencesConsent: consent.hasPreferencesConsent });
  };

  handleHasPreferencesConsentToggle = (event) => {
    const hasPreferencesConsent = event.target.checked === true;

    cookieHelper.setHasPreferencesConsent(hasPreferencesConsent);
  };

  render() {
    const { classes, t, label, description } = this.props;
    const { hasPreferencesConsent } = this.state;

    return (
      <div className={classes.container}>
        <ListItemText primary={label} secondary={description} />
        <Switch
          onChange={this.handleHasPreferencesConsentToggle}
          checked={hasPreferencesConsent}
        />
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(PreferencesConsentToggle));
