import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button, TextField, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import withStyles from "@mui/styles/withStyles";

import ScanQRCodeDialog from "tap-io/client/components/common/ScanQRCodeDialog";
import config from "tap-io/client/env";

import ContentPage from "tap-io/client/components/common/ContentPage";
import { utilsHelper } from "tap-io/helpers";

const styles = (theme) => ({
  largeSpacing: {
    height: 40
  },
  smallSpacing: {
    height: 20
  },
  enterLocatorManuallyContainer: {
    display: "inline-block",
    padding: 10,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper
  },
  enterLocatorManually: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16
  },
  locatorField: {
    width: 100
  },
  locatorInput: {
    marginTop: 2,
    marginLeft: 1,
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  externalUrlContainer: {
    padding: "0 10px"
  }
});

class ScanQRCodePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isScanQRCodeDialogOpen: false,
      manuallyEnteredLocator: ""
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { orderUrl } = this.state;
    const prevOrderUrl = prevState.orderUrl;

    if (orderUrl && !prevOrderUrl) {
      window.location.replace(orderUrl);
    }
  }

  handleScanQRCode = () => {
    this.setState({ isScanQRCodeDialogOpen: true });
  };

  handleQRCodeScanned = (data) => {
    const { t } = this.props;

    try {
      const regExp = new RegExp(
        `^https:\/\/${config.hosting.orderDomain.replace(/\./g, "\\.")}\/(.*)$`
      );
      const matches = data.match(regExp);

      if (matches && matches.length === 2) {
        const orderQueryString = matches[1];
        const orderUrl = `https://${config.hosting.orderDomain}/${orderQueryString}`;

        this.setState({
          isScanQRCodeDialogOpen: false,
          orderUrl
        });
      } else {
        const externalUrl = utilsHelper.parseHttpUrl(data);

        this.setState({
          isScanQRCodeDialogOpen: false,
          externalUrl
        });
      }
    } catch (error) {
      console.warn(error);

      this.setState({
        isScanQRCodeDialogOpen: false
      });

      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`
      );
    }
  };

  handleScanQRCodeDialogClose = () => {
    this.setState({ isScanQRCodeDialogOpen: false });
  };

  handleShowLocatorManually = () => {
    this.setState({ isEnterLocatorManuallyVisible: true });
  };

  handleFocusLocatorField = (event) => {
    if (this.locatorInput) {
      this.locatorInput.focus();
    }
  };

  handleManuallyEnteredLocatorChange = (event) => {
    this.setState({ manuallyEnteredLocator: event.target.value });
  };

  handleSubmitManuallyEnteredLocator = (event) => {
    // Prevent page refresh
    event.preventDefault();

    const { manuallyEnteredLocator } = this.state;

    if (!manuallyEnteredLocator) {
      return;
    }

    const orderUrl = `https://${config.hosting.orderDomain}/${manuallyEnteredLocator}`;

    this.setState({ orderUrl });
  };

  visitExternalUrl = () => {
    const { externalUrl } = this.state;

    window.location.replace(externalUrl);
  };

  clearExternalUrl = () => {
    this.setState({ externalUrl: null });
  };

  render() {
    const { classes, t } = this.props;
    const {
      isScanQRCodeDialogOpen,
      isEnterLocatorManuallyVisible,
      manuallyEnteredLocator,
      orderUrl,
      externalUrl
    } = this.state;

    return (
      <div>
        <ScanQRCodeDialog
          isOpen={isScanQRCodeDialogOpen}
          onScan={this.handleQRCodeScanned}
          onClose={this.handleScanQRCodeDialogClose}
        />
        <ContentPage>
          {orderUrl ? (
            <Typography>{t("label.redirecting-to-order-page")}</Typography>
          ) : externalUrl ? (
            <div className={classes.externalUrlContainer}>
              <Typography>
                {t(
                  "label.you-scanned-a-qr-code-for-following-external-website"
                )}
                <br />
                <strong>{externalUrl}</strong>
                <br />
                <br />
                {t("label.are-you-sure-you-want-to-visit-this-website")}
              </Typography>
              <div className={classes.smallSpacing} />
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.visitExternalUrl}
                >
                  {t("label.visit-external-website")}
                </Button>
                <div className={classes.smallSpacing} />
                <Button onClick={this.clearExternalUrl}>
                  {t("label.cancel")}
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Button
                color="primary"
                variant="contained"
                size="large"
                disableElevation={true}
                onClick={this.handleScanQRCode}
              >
                {t("label.scan-qr-code")}
              </Button>
              <div className={classes.largeSpacing} />
              <div>
                {isEnterLocatorManuallyVisible ? (
                  <div className={classes.enterLocatorManuallyContainer}>
                    <Typography
                      variant="h6"
                      onClick={this.handleFocusLocatorField}
                    >
                      {t("bar.complete-the-link-below-and-open-the-order-page")}
                    </Typography>
                    <div className={classes.smallSpacing} />
                    <form
                      className={classes.enterLocatorManually}
                      onSubmit={this.handleSubmitManuallyEnteredLocator}
                    >
                      <span>{config.hosting.orderDomain}/</span>
                      <TextField
                        inputRef={(input) => {
                          this.locatorInput = input;
                        }}
                        variant="standard"
                        className={classes.locatorField}
                        value={manuallyEnteredLocator}
                        placeholder={t("bar.locator")}
                        onChange={this.handleManuallyEnteredLocatorChange}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: classes.locatorInput
                          }
                        }}
                      />
                    </form>
                    <div className={classes.smallSpacing} />
                    <Button
                      startIcon={<LaunchIcon />}
                      disabled={!manuallyEnteredLocator}
                      color="primary"
                      onClick={this.handleSubmitManuallyEnteredLocator}
                    >
                      {t("bar.open-order-page")}
                    </Button>
                  </div>
                ) : (
                  <Button size="small" onClick={this.handleShowLocatorManually}>
                    {t("label.i-have-trouble-scanning")}
                  </Button>
                )}
              </div>
            </div>
          )}
        </ContentPage>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ScanQRCodePage));
