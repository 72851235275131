import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import PaymentCurrency from "tap-io/models/payment/PaymentCurrency";

const styles = (theme) => ({
  button: {
    height: "auto",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    fontSize: 18
  },
  disabledButton: {
    padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    backgroundColor: `#ccc !important`
  },
  icon: {
    marginLeft: theme.spacing(),
    fontSize: 32
  },
  fullWidth: {
    flexGrow: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: theme.spacing(0, 1, 0, 0)
  },
  primaryText: {
    fontSize: 18
  },
  secondaryText: {
    fontSize: 16
  }
});

class VerifyOrderButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getFormattedTotal = (total) => {
    const { bar } = this.props;

    const formattedTotal = utilsHelper.formatToTwoDecimals(
      total
    );
    const orderCurrency = bar.getOrderCurrency();

    return orderCurrency === PaymentCurrency.EUR ?
      `€ ${formattedTotal}` :
      orderCurrency === PaymentCurrency.GBP ?
        `£ ${formattedTotal}` :
        `${formattedTotal} ${orderCurrency}`
  }


  render() {
    const {
      classes,
      t,
      bar,
      orderItemsAmount,
      orderItemsTotal,
      orderDepositsAmount,
      orderDepositsTotal,
      onClick
    } =
      this.props;

    const isEmptyOrder = orderItemsAmount === 0;

    return (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classNames(classes.button, {
          [classes.disabledButton]: isEmptyOrder
        })}
        disabled={isEmptyOrder}
        onClick={onClick}
      >
        <div
          className={classNames(classes.content, {
            [classes.fullWidth]: !isEmptyOrder
          })}
        >
          <span className={classes.primaryText}>
            {t(isEmptyOrder ? "order.select-items-to-order" : "order.order")}
          </span>
          {!isEmptyOrder && (
            <span className={classes.secondaryText}>
              {`${orderItemsAmount} ${t(
                orderItemsAmount === 1 ? "order.item" : "order.items"
              )} (${this.getFormattedTotal(orderItemsTotal)})`}
              {orderDepositsTotal === 0 ?
                null :
                <>
                  {` ${orderDepositsTotal < 0 ? "-" : "+"} ${t("order.deposit")} (${this.getFormattedTotal(Math.abs(orderDepositsTotal))})`}
                </>
              }
            </span>
          )}
        </div>
        {!isEmptyOrder && <ArrowForwardIosIcon className={classes.icon} />}
      </Button>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(VerifyOrderButton));
