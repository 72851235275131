import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";

const styles = (theme) => ({
  bases: {
    margin: `${theme.spacing(0.5)} 0`
  }
});

class ServiceOptionBases extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, bases, layoutNode, serviceOption } = this.props;

    const basesForServiceOption =
      bases && layoutNode && serviceOption
        ? bases.filter((base) =>
            layoutNode.bases.get(base.id)?.hasServiceOption(serviceOption.name)
          )
        : [];

    return (
      <div className={classes.bases}>
        {basesForServiceOption && basesForServiceOption.length > 0 ? (
          <>
            <Typography>
              {t("base.order-at")}{" "}
              {basesForServiceOption.map((base, index) => (
                <span key={base.id}>
                  <strong>{base.name}</strong>
                  <span>
                    {index < basesForServiceOption.length - 1
                      ? index === basesForServiceOption.length - 2
                        ? ` ${t("label.and")} `
                        : ", "
                      : ""}
                  </span>
                </span>
              ))}
            </Typography>
          </>
        ) : (
          <Typography>{t("base.no-bases-to-order-at")}</Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(ServiceOptionBases));
