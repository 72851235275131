import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import theme from "tap-io/client/theme";
import { cookieHelper } from "tap-io/client/helpers";
import SetLocale from "tap-io/client/components/common/SetLocale";

const styles = (theme) => ({
  container: {
    padding: "20px 10px"
  },
  logo: {
    height: 60,
    margin: "5px 0 -6px 0"
  },
  links: {
    fontSize: 12,
    textAlign: "center",
    color: "black"
  },
  link: {
    display: "inline-block",
    margin: `${theme.spacing(1.5)} ${theme.spacing()}`,
    textDecoration: "none",
    color: "black",
    cursor: "pointer"
  },
  spacing: {
    height: 20
  },
  line: {
    borderTop: "1px solid #ddd"
  }
});

class BarFooter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleShowCookieManager = () => {
    cookieHelper.showCookieManager();
  };

  render() {
    const { classes, t, locale, setLocale } = this.props;

    return (
      <div className={classes.container}>
        <a href={config.links.promo} target="_blank">
          <img className={classes.logo} src={theme.assets.logo} />
        </a>
        <div className={classes.links}>
          <a
            className={classes.link}
            href={config.links.termsOfUse}
            target="_blank"
          >
            {t("legal.terms-of-use")}
          </a>
          <span>&bull;</span>
          <a
            className={classes.link}
            href={config.links.privacyPolicy}
            target="_blank"
          >
            {t("legal.privacy-policy")}
          </a>
          {cookieHelper.hasCookieManager() && (
            <span>
              <span>&bull;</span>
              <span
                className={classes.link}
                onClick={this.handleShowCookieManager}
              >
                {t("legal.manage-privacy-settings")}
              </span>
            </span>
          )}
        </div>
        <div className={classes.spacing} />
        <div className={classes.line} />
        <div className={classes.spacing} />
        <SetLocale locale={locale} setLocale={setLocale} />
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(BarFooter));
