import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import QRCode from "qrcode.react";

import { CircularProgress, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  spacing: {
    height: 15
  },
  statusText: {
    fontSize: 20
  }
});

class OrderStatusAutomatic extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, order, base, allZones, orderUrl } = this.props;
    const { isOrderDeliveryConfirmationDialogOpen } = this.state;

    if (order && order.isStatusError(base)) {
      return (
        <Typography className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />{" "}
          {t("order.order-confirmation-failed")}. {t("order.retry-new-order")}.
        </Typography>
      );
    }

    return (
      <div>
        {order.isStatusComplete(base) ? (
          <div>
            <Typography className={classes.statusText}>
              {t("order.pick-up-order-yourself")}
              {base && (
                <span>
                  {` ${t("order.at")} `}
                  <strong>{base.name}</strong>
                </span>
              )}
            </Typography>
            <div className={classes.spacing} />
            <CheckIcon />
          </div>
        ) : (
          <div>
            <Typography className={classes.statusText}>
              {t("order.status-queued-long")}
            </Typography>
            <div className={classes.spacing} />
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderStatusAutomatic)
);
