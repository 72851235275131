import React from "react";
import PropTypes from "prop-types";

/**
 * Enable one trust on the website
 * @param {string} domainScript - One trust domain script id
 * @param {string} language - One trust data culture
 * @returns {*|null}
 * @constructor
 */

const ONETRUST_SCRIPT_ID = "OneTrust";

function OneTrust({ domainScript, language }) {
  /* istanbul ignore next */
  if (typeof window === "undefined") {
    return null;
  }
  if (!domainScript || !document) {
    return null;
  }
  if (domainScript.length !== 36) {
    console.warn(
      `The one trust domain script id is ${domainScript.length} characters, instead it should be 36 characters!`
    ); // eslint-disable-line no-console
  }

  if (!document.getElementById(ONETRUST_SCRIPT_ID)) {
    const script = document.createElement("script");
    script.setAttribute("id", ONETRUST_SCRIPT_ID);
    script.setAttribute(
      "src",
      "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    );
    script.setAttribute("data-domain-script", domainScript);
    script.setAttribute("data-document-language", "true");
    script.setAttribute("type", "text/javascript");
    if (language) {
      //script.setAttribute("data-culture", language);
    }
    const head = document.querySelector("html > head");
    head.insertBefore(script, head.firstChild);
  }

  return null;
}

OneTrust.defaultProps = {
  domainScript: undefined,
  language: undefined
};

OneTrust.propTypes = {
  /** One trust domain group id */
  domainScript: PropTypes.string,
  /** One trust language */
  language: PropTypes.string
};

export default OneTrust;
