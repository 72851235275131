import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { List, ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { serviceOptionHelper } from "tap-io/helpers";
import LayoutPath from "tap-io/models/zone/LayoutPath";
import SelectServiceOption from "tap-io/client/components/service/SelectServiceOption";

import ServiceOptionFees from "../service/ServiceOptionFees";
import ServiceOptionBases from "../service/ServiceOptionBases";

const styles = (theme) => ({});

class SelectOrderZoneServiceOption extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getAvailableServiceOptions = (nodeServiceOptions) => {
    const { bar, bases } = this.props;

    return serviceOptionHelper.getAvailableServiceOptions(
      bar,
      bases,
      nodeServiceOptions
    );
  };

  render() {
    const {
      classes,
      t,
      bar,
      bases,
      activeFees,
      orderZone,
      orderLayoutPath,
      orderServiceOption,
      onOrderServiceOptionChange
    } = this.props;

    const orderLayoutNode =
      orderZone && orderZone.layout
        ? orderZone.layout.find(
            orderLayoutPath ? orderLayoutPath : new LayoutPath()
          )
        : null;

    const serviceOptionsForZone = orderZone
      ? orderZone.serviceOptions
      : serviceOptionHelper.createDefaultServiceOptions(bar.deliveryMethods);
    const serviceOptionsForLayoutNode = orderZone
      ? orderLayoutNode
        ? orderLayoutNode.serviceOptions
        : serviceOptionHelper.createServiceOptions()
      : serviceOptionHelper.createDefaultServiceOptions(bar.deliveryMethods);
    const serviceOptionsForChildLayoutNodes = orderZone
      ? orderLayoutNode
        ? orderLayoutNode.childServiceOptions
        : serviceOptionHelper.createServiceOptions()
      : serviceOptionHelper.createDefaultServiceOptions(bar.deliveryMethods);

    const availableServiceOptionsForZone = this.getAvailableServiceOptions(
      serviceOptionsForZone
    );
    const availableServiceOptionsForLayoutNode =
      this.getAvailableServiceOptions(serviceOptionsForLayoutNode);
    const availableServiceOptionsForChildLayoutNodes =
      this.getAvailableServiceOptions(serviceOptionsForChildLayoutNodes);

    return (
      <List>
        <ListItem>
          <Typography variant="subtitle1" className={classes.title}>
            {t("order.select-service-option")}
          </Typography>
        </ListItem>
        <ListItem>
          {orderZone && bases ? (
            <SelectServiceOption
              allServiceOptions={availableServiceOptionsForZone}
              disabledServiceOptions={serviceOptionHelper.createInvertedServiceOptions(
                availableServiceOptionsForLayoutNode
              )}
              serviceOption={orderServiceOption}
              onChange={onOrderServiceOptionChange}
              getDescription={(serviceOption, isEnabled) => (
                <div>
                  {isEnabled ? (
                    <>
                      <ServiceOptionBases
                        bar={bar}
                        bases={bases}
                        layoutNode={orderLayoutNode}
                        serviceOption={serviceOption}
                      />
                      <ServiceOptionFees
                        bar={bar}
                        fees={activeFees}
                        zone={orderZone}
                        layoutPath={orderLayoutPath}
                        serviceOption={serviceOption}
                      />
                    </>
                  ) : (
                    <Typography variant="caption">
                      {t(
                        availableServiceOptionsForChildLayoutNodes.has(
                          serviceOption?.name
                        )
                          ? "order.provide-more-information-above-to-enable-this-service-option"
                          : "order.service-option-not-available-in-this-area"
                      )}
                    </Typography>
                  )}
                </div>
              )}
            />
          ) : (
            <Typography>{t("order.no-service-options-found")}</Typography>
          )}
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SelectOrderZoneServiceOption)
);
