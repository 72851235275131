import React, { PureComponent } from "react";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { adService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";

import AdsInViewport from "./AdsInViewport";
import Ads from "./Ads";

const LANDSCAPE_ADS_HEIGHT = 100;
const STRIP_ADS_HEIGHT = 50;

const styles = (theme) => ({
  container: {
    zIndex: 2,
    position: "sticky",
    top: -(LANDSCAPE_ADS_HEIGHT + 1)
  },
  visible: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  },
  landscapeAdsContainer: {
    transition: "opacity 0.5s ease-out"
  },
  stripAdsContainer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    transition: "opacity 0.5s ease-out, height 0.5s step-end"
  },
  noHeight: {
    overflow: "hidden",
    height: 0
  },
  spacing: {}
});

class HeaderAds extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      landscapeAdsInViewport: true
    };
  }

  componentDidMount() {
    const { bar } = this.props;

    this.initAds();
  }

  initAds = async () => {
    const { auth, bar } = this.props;

    if (auth && auth.user && auth.user.uid) {
      const ads = await adService.fetchAds(
        config.functions.api,
        auth.user,
        bar.id,
        1
      );

      this.setState({
        ads
      });
    }
  };

  handleLandscapeAdsInitialViewport = (inViewport) => {
    this.setState({ landscapeAdsInViewport: inViewport === true });
  };

  handleLandscapeAdsEnteringViewport = () => {
    this.setState({ landscapeAdsInViewport: true });
  };

  handleLandscapeAdsLeavingViewport = () => {
    this.setState({ landscapeAdsInViewport: false });
  };

  render() {
    const { classes, headerSpacing } = this.props;
    const { ads, landscapeAdsInViewport } = this.state;

    if (!ads || ads.length === 0) {
      return null;
    }

    return (
      <div className={classes.container}>
        <div
          className={classNames(
            classes.landscapeAdsContainer,
            landscapeAdsInViewport ? classes.visible : classes.hidden
          )}
        >
          <AdsInViewport
            ads={ads}
            height={LANDSCAPE_ADS_HEIGHT}
            onInitialViewport={this.handleLandscapeAdsInitialViewport}
            onEnterViewport={this.handleLandscapeAdsEnteringViewport}
            onLeaveViewport={this.handleLandscapeAdsLeavingViewport}
          />
        </div>
        <div
          className={classNames(
            classes.stripAdsContainer,
            landscapeAdsInViewport
              ? classNames(classes.hidden, classes.noHeight)
              : classes.visible
          )}
        >
          <Ads ads={ads} height={STRIP_ADS_HEIGHT} />
        </div>
      </div>
    );
  }
}

export default withAuthorization()(withStyles(styles)(HeaderAds));
