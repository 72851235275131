import React, { PureComponent } from "react";

import CancelOrderButton from "tap-io/client/components/order/CancelOrderButton";

import withAuthorization from "../auth/withAuthorization";

class CancelOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { auth, order } = this.props;

    return (
      <CancelOrderButton
        auth={auth}
        order={order}
        color="primary"
        size="small"
      />
    );
  }
}

export default withAuthorization()(CancelOrder);
