import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Card, CardContent, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import OrderFeesLines from "./OrderFeesLines";

const styles = (theme) => ({
  spacing: {
    height: 15
  }
});

class OrderStatusCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, order } = this.props;

    if (!order || !order.fees || order.fees.length === 0) {
      return null;
    }

    return (
      <Card>
        <CardContent>
          <Typography variant="h6">{t("order.charged-fees")}</Typography>
          <div className={classes.spacing} />
          <OrderFeesLines bar={bar} orderFees={order.fees} />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderStatusCard));
