import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import DoneIcon from "@mui/icons-material/Done";
import withStyles from "@mui/styles/withStyles";

import PaymentMessage from "tap-io/client/components/payment/PaymentMessage";

const styles = (theme) => ({});

class OrderIsPaidMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <PaymentMessage
        color="#4caf50"
        icon={<DoneIcon />}
        text={t("order.order-is-paid")}
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderIsPaidMessage)
);
