import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

class ApplyItemsCache extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.checkItems();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkItems();
  }

  checkItems = async () => {
    const { t, isReady, menuItems, items, onOrderItemsChange } = this.props;

    if (this.areItemsAlreadyChecked) {
      return;
    }

    if (!isReady) {
      return;
    }

    this.areItemsAlreadyChecked = true;

    let amountOfOrderItemsChanged = 0;
    const orderItems = {};

    const itemsAsMap = {};
    items.forEach((item) => (itemsAsMap[item.id] = item));

    menuItems.forEach((menuItem) => {
      if (itemsAsMap[menuItem.id]) {
        if (!orderItems[menuItem.id]) {
          orderItems[menuItem.id] = { menuItem, amount: 0 };
        }

        orderItems[menuItem.id].amount += itemsAsMap[menuItem.id].amount;
        amountOfOrderItemsChanged++;
      }
    });

    if (amountOfOrderItemsChanged > 0) {
      onOrderItemsChange(orderItems);

      if (amountOfOrderItemsChanged !== items.length) {
        toast.info(
          t(
            "order.order-has-been-adjusted-because-availability-of-some-items-has-changed"
          )
        );
      }
    }
  };

  render() {
    return null;
  }
}

export default withTranslation("common")(ApplyItemsCache);
