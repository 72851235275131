import React, { PureComponent } from "react";

import { Route, Switch } from "react-router-dom";

import config from "tap-io/client/env";

import * as routes from "../../constants/routes";
import CreateOrderPage from "../../pages/CreateOrderPage";
import ViewOrderPage from "../../pages/ViewOrderPage";

class BarRoutes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path={routes.VIEW_ORDER}
          render={(params) => <ViewOrderPage {...params} {...this.props} />}
        />
        <Route
          exact
          path={routes.CREATE_ORDER}
          render={(params) => <CreateOrderPage {...params} {...this.props} />}
        />
        <Route
          render={(params) => {
            window.location.href = config.links.promo;
            return null;
          }}
        />
      </Switch>
    );
  }
}

export default BarRoutes;
