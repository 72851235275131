import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button, CircularProgress } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import withStyles from "@mui/styles/withStyles";

import { paymentService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  center: { textAlign: "center" }
});

class PayOrderDeferred extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false
    };
  }

  componentDidMount() {
    const { autoRedirect } = this.props;

    if (autoRedirect) {
      setTimeout(this.handleDeferPayment, 100);
    }
  }

  handleDeferPayment = async () => {
    const { t, auth, order, onProcessing, onComplete, onError } = this.props;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    this.setState({ isDisabled: true });

    try {
      if (onProcessing) {
        onProcessing();
      }

      await paymentService.requestDeferredPayment(auth.user.uid, order);

      onComplete(); // Component will be unmounted because this payment method is not available anymore
      //this.setState({ isDisabled: false }, onComplete);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false }, () => {
        onError(error);
      });
    }
  };

  render() {
    const { t } = this.props;
    const { isDisabled } = this.state;

    return (
      <Button
        variant="outlined"
        size="large"
        color="primary"
        startIcon={
          isDisabled ? <CircularProgress size={30} /> : <ScheduleIcon />
        }
        onClick={this.handleDeferPayment}
        disabled={isDisabled}
      >
        {t("payment.pay-deferred")}
      </Button>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(PayOrderDeferred)
  )
);
