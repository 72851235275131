import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import {
  Drawer,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  Typography,
  Button,
  ListItemIcon
} from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import withStyles from "@mui/styles/withStyles";

import { deviceStorage } from "tap-io/storage";
import { orderService } from "tap-io/client/services";

import { CACHE_KEY_ORDER_HISTORY } from "../../constants/cache";
import * as routes from "../../constants/routes";

const ORDERS_INITIAL_BATCH_SIZE = 3;
const ORDERS_ADDITIONAL_BATCH_SIZE = 3;

const styles = (theme) => ({
  drawer: {
    //maxHeight: "90%"
  },
  link: {
    textDecoration: "none",
    color: "black"
  }
});

class OrderHistoryDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ordersMaxCount: ORDERS_INITIAL_BATCH_SIZE
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;
    const { ordersMaxCount } = this.state;

    const prevIsOpen = prevProps.isOpen;
    const prevOrdersMaxCount = prevState.ordersMaxCount;

    if (isOpen) {
      if (!prevIsOpen || ordersMaxCount !== prevOrdersMaxCount) {
        this.refreshOrderHistory();
      }
    }
  }

  refreshOrderHistory = async () => {
    const { bar } = this.props;
    const { ordersMaxCount } = this.state;

    const orderHistory = (
      (await deviceStorage.getDeviceCacheItem(
        bar.id,
        CACHE_KEY_ORDER_HISTORY
      )) || []
    ).reverse();

    let wasOrderHistoryUpdated = false;
    const orderHistorySlice = await Promise.all(
      orderHistory.slice(0, ordersMaxCount).map(async (order) => {
        if (order.name && order.timestamp) {
          return order;
        } else {
          const fetchedOrder = await orderService.getById(bar.id, order.id);
          wasOrderHistoryUpdated = true;

          return {
            id: fetchedOrder.id,
            name: fetchedOrder.name,
            timestamp: fetchedOrder.timestamp.toISOString()
          };
        }
      })
    );

    if (wasOrderHistoryUpdated) {
      const updatedOrderHistory = [
        ...orderHistorySlice,
        ...orderHistory.slice(ordersMaxCount)
      ].reverse();

      await deviceStorage.setDeviceCacheItem(
        bar.id,
        CACHE_KEY_ORDER_HISTORY,
        updatedOrderHistory
      );
    }

    this.setState({ orderHistory, orderHistorySlice });
  };

  handleLinkClick = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  handleFetchMoreOrders = () => {
    const { ordersMaxCount } = this.state;

    this.setState({
      ordersMaxCount: ordersMaxCount + ORDERS_ADDITIONAL_BATCH_SIZE
    });
  };

  getOrderUrl = (order) => {
    const { barLocator } = this.props;

    return routes.VIEW_ORDER.replace(":barLocator", barLocator).replace(
      ":orderId",
      order.id
    );
  };

  render() {
    const { classes, t, isOpen, onClose } = this.props;
    const { orderHistory, orderHistorySlice } = this.state;

    return (
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.drawer }}
      >
        <List>
          <ListItem>
            <Typography variant="h6" className={classes.title}>
              {t("order.order-history")}
            </Typography>
          </ListItem>

          {orderHistorySlice && orderHistorySlice.length > 0 ? (
            orderHistorySlice.map((order) => (
              <Link
                key={order.id}
                to={this.getOrderUrl(order)}
                className={classes.link}
                onClick={this.handleLinkClick}
              >
                <ListItemButton>
                  <ListItemText
                    primary={order.name}
                    secondary={
                      <ReactMoment format="DD/MM/YYYY, HH:mm">
                        {order.timestamp}
                      </ReactMoment>
                    }
                  />
                </ListItemButton>
              </Link>
            ))
          ) : (
            <ListItem>{t("order.no-orders-found")}</ListItem>
          )}
          {orderHistory &&
            orderHistorySlice &&
            orderHistorySlice.length < orderHistory.length && (
              <ListItemButton onClick={this.handleFetchMoreOrders}>
                <ListItemIcon>
                  <RestoreIcon />
                </ListItemIcon>
                <ListItemText primary={t("label.load-more-orders")} />
              </ListItemButton>
            )}
          <ListItem>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={onClose}
            >
              {t("label.close")}
            </Button>
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(OrderHistoryDrawer)
);
