import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import AuthorizedLink from "tap-io/client/components/auth/AuthorizedLink";
import config from "tap-io/client/env";

import withAuthorization from "./withAuthorization";

const styles = (theme) => ({});

class AuthorizedAppLink extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      auth,
      bar,
      endpoint,
      params,
      target,
      variant,
      size,
      color,
      text,
      icon,
      autoRedirect,
      isDisabled,
      onClick,
      fullWidth
    } = this.props;

    return (
      <AuthorizedLink
        auth={auth}
        bar={bar}
        link={`${config.functions.api}/${endpoint}`}
        params={params}
        target={target}
        size={size}
        color={color}
        text={text}
        icon={icon}
        autoRedirect={autoRedirect}
        isDisabled={isDisabled}
        onClick={onClick}
        variant={variant}
        fullWidth={fullWidth}
      />
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(AuthorizedAppLink)
);
