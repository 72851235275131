import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import OnlyPaidOrdersAreProcessedMessage from "../payment/OnlyPaidOrdersAreProcessedMessage";
import OrderIsPaidMessage from "../payment/OrderIsPaidMessage";
import PaymentIsBeingProcessedMessage from "../payment/PaymentIsBeingProcessedMessage";
import OrderWillBePaidDeferredMessage from "../payment/OrderWillBePaidDeferredMessage";
import OrderPayButton from "./OrderPayButton";
import PayOrderDialog from "../payment/PayOrderDialog";

const styles = (theme) => ({
  spacing: {
    height: 20
  }
});

class OrderPayment extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPayOrderDialogOpen: false
    };
  }

  componentDidMount() {
    const { requestImmediatePayment } = this.props;

    if (requestImmediatePayment) {
      this.setState({ isPayOrderDialogOpen: true });
    }
  }

  handlePayOrder = () => {
    this.setState({ isPayOrderDialogOpen: true });
  };

  handlePayOrderDialogClose = () => {
    this.setState({ isPayOrderDialogOpen: false });
  };

  getMessage = () => {
    const { classes, bar, order } = this.props;

    if (order.isPaid()) {
      return <OrderIsPaidMessage />;
    } else if (order.isPaymentProcessing()) {
      return <PaymentIsBeingProcessedMessage />;
    } else if (bar.isAllowingOnlinePayments()) {
      if (order.hasBeenQueued()) {
        return <OrderWillBePaidDeferredMessage />;
      } else if (order.canBePaid()) {
        return (
          <div>
            {!bar.isAllowingDeferredPayments() && (
              <div>
                <OnlyPaidOrdersAreProcessedMessage />
                <div className={classes.spacing} />
              </div>
            )}
            <div>
              {bar.areTherePaymentProvidersAvailable() && (
                <OrderPayButton onClick={this.handlePayOrder} />
              )}
            </div>
          </div>
        );
      }
    }

    return null;
  };

  render() {
    const { classes, t, barLocator, bar, order, isWaiter, waiterCode, voucherCodes } =
      this.props;
    const { isPayOrderDialogOpen } = this.state;

    const message = this.getMessage();

    if (message) {
      return (
        <div>
          <PayOrderDialog
            isOpen={isPayOrderDialogOpen}
            onClose={this.handlePayOrderDialogClose}
            barLocator={barLocator}
            bar={bar}
            order={order}
            isWaiter={isWaiter}
            waiterCode={waiterCode}
            voucherCodes={voucherCodes}
          />
          {message}
        </div>
      );
    }

    return null;
  }
}

export default withStyles(styles)(withTranslation("common")(OrderPayment));
