import React, { PureComponent } from "react";

import { deviceStorage } from "tap-io/storage";

import { CACHE_KEY_CUSTOMER_FIELDS } from "../../constants/cache";
import { barHelper } from "tap-io/helpers";

class ApplyCustomerFieldsCache extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.checkCustomerFields();
  }

  checkCustomerFields = async () => {
    const { bar, onOrderFieldsChange } = this.props;

    const customerFields = await deviceStorage.getDeviceCacheItem(
      bar.id,
      CACHE_KEY_CUSTOMER_FIELDS
    );

    if (customerFields) {
      const parsedCustomerFields = {};

      bar.getCustomerFields().forEach((customerField) => {
        const data = customerFields[customerField.id];

        if (
          data &&
          data.value &&
          barHelper.isCustomerFieldValueValid(customerField, data.value)
        ) {
          parsedCustomerFields[customerField.id] = {
            value: data.value,
            isValid: true
          };
        }
      });

      onOrderFieldsChange(parsedCustomerFields);
    }
  };

  render() {
    return null;
  }
}

export default ApplyCustomerFieldsCache;
