import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import { barService, feeService, depositService, zoneService } from "tap-io/client/services";
import { cloudStorage, deviceStorage } from "tap-io/storage";
import withAuthContext from "tap-io/client/components/hoc/withAuthContext";
import { bugsnagHelper } from "tap-io/client/helpers";
import config from "tap-io/client/env";

import withLocator from "../auth/withLocator";
import BarSetting from "./BarSetting";
import { CACHE_KEY_LOCALE } from "../../constants/cache";

const styles = (theme) => ({});

class BarWrapper extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      timeOffset: 0
    };
  }

  componentDidMount() {
    const { barId } = this.props;

    if (barId) {
      this.setBarId(barId);
    }

    // Time
    this.setTimeOffset();
  }

  componentDidUpdate(prevProps, prevState) {
    const { barId } = this.props;
    const prevBarId = prevProps.barId;

    // barId
    if (barId !== prevBarId) {
      this.setBarId(barId);
    }
  }

  componentWillUnmount() {
    this.clearBarId();
  }

  getCachedLocale = async (barId) => {
    return await deviceStorage.getDeviceCacheItem(barId, CACHE_KEY_LOCALE);
  };

  setCachedLocale = async (barId, locale) => {
    const { bar } = this.state;
    // TO FIX
    if (bar) {
      bar.locale = locale;
    }

    return await deviceStorage.setDeviceCacheItem(
      barId,
      CACHE_KEY_LOCALE,
      locale
    );
  };

  handleSetLocale = async (locale) => {
    const { setLocale } = this.props;
    const { bar } = this.state;

    setLocale(locale);

    if (bar && bar.id) {
      await this.setCachedLocale(bar.id, locale);
    }
  };

  setBarId = async (barId) => {
    const { setThemeColors, setLocale } = this.props;

    await this.clearBarId();

    // bar
    if (barId) {
      this.unsubscribeBar = barService.onBarById(barId, async (bar) => {
        // Bugsnag
        bugsnagHelper.setBar(bar);

        const prevBar = this.state.bar;

        this.setState({ bar });

        // Color
        const prevPrimaryColor = prevBar
          ? prevBar.getPrimaryColor()
          : undefined;
        const primaryColor = bar.getPrimaryColor();
        const prevSecondaryColor = prevBar
          ? prevBar.getSecondaryColor()
          : undefined;
        const secondaryColor = bar.getSecondaryColor();
        if (
          primaryColor !== prevPrimaryColor ||
          secondaryColor !== prevSecondaryColor
        ) {
          setThemeColors(primaryColor, secondaryColor);
        }

        // Language
        const orderLocale = bar.getOrderLocale();
        const cachedLocale = await this.getCachedLocale(barId);
        if (cachedLocale) {
          setLocale(cachedLocale);
        } else {
          const prevOrderLocale = prevBar
            ? prevBar.getOrderLocale()
            : undefined;

          if (orderLocale !== prevOrderLocale) {
            setLocale(orderLocale);
          }
        }
        bar.locale = cachedLocale || orderLocale;

        // Assets
        const prevLogoFileName = prevBar
          ? prevBar.getLogoFileName()
          : undefined;
        const logoFileName = bar.getLogoFileName();
        if (logoFileName !== prevLogoFileName) {
          let logoImageUrl = null;

          try {
            logoImageUrl = await cloudStorage.getBarLogoImageUrl(bar);
          } catch (error) {
            console.warn(error);
          }

          this.setState({ assets: { ...this.state.assets, logoImageUrl } });
        }
        const prevCoverFileName = prevBar
          ? prevBar.getCoverFileName()
          : undefined;
        const coverFileName = bar.getCoverFileName();
        if (coverFileName !== prevCoverFileName) {
          let coverImageUrl = null;

          try {
            coverImageUrl = await cloudStorage.getBarCoverImageUrl(bar);
          } catch (error) {
            console.warn(error);
          }

          this.setState({ assets: { ...this.state.assets, coverImageUrl } });
        }
      });

      // Fees
      this.unsubscribeFees = feeService.onActiveFees(barId, (activeFees) => {
        this.setState({ activeFees });
      });

      // Deposits
      this.unsubscribeDeposits = depositService.onActiveDeposits(
        barId,
        (activeDeposits) => {
          this.setState({ activeDeposits });
        }
      );

      // Zones
      this.unsubscribeZones = zoneService.onActiveZones(barId, (allZones) => {
        this.setState({ allZones });
      });
    }
  };

  clearBarId = () => {
    return new Promise((resolve, reject) => {
      // Bugsnag
      bugsnagHelper.clearBar();

      // Unsubscribe
      if (this.unsubscribeBar) {
        this.unsubscribeBar();
        this.unsubscribeBar = undefined;
      }
      if (this.unsubscribeFees) {
        this.unsubscribeFees();
        this.unsubscribeFees = undefined;
      }
      if (this.unsubscribeDeposits) {
        this.unsubscribeDeposits();
        this.unsubscribeDeposits = undefined;
      }
      if (this.unsubscribeZones) {
        this.unsubscribeZones();
        this.unsubscribeZones = undefined;
      }

      this.setState(
        {
          bar: null,
          assets: null,
          activeFees: null,
          allZones: null
        },
        resolve
      );
    });
  };

  setTimeOffset = async () => {
    const { auth } = this.props;

    if (auth && auth.user) {
      const timeOffset = await barService.getTimeOffset(
        config.functions.api,
        auth.user
      );

      this.setState({ timeOffset });
    }
  };

  render() {
    const { locale } = this.props;
    const { timeOffset, bar, assets, activeFees, activeDeposits, allZones } = this.state;

    return (
      <BarSetting
        locale={locale}
        setLocale={this.handleSetLocale}
        timeOffset={timeOffset}
        bar={bar}
        assets={assets}
        activeFees={activeFees}
        activeDeposits={activeDeposits}
        allZones={allZones}
      />
    );
  }
}

export default withStyles(styles)(withLocator(withAuthContext(BarWrapper)));
