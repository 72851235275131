import React, { PureComponent } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import Moment from "moment";
import TagManager from "react-gtm-module";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "typeface-roboto";

import { CssBaseline } from "@mui/material";

import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from "@mui/material/styles";

import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { Helmet } from "react-helmet";

import { ToastContainer } from "react-toastify";

import * as routes from "./constants/routes";

import appTheme from "tap-io/client/theme";

import withAnalytics from "tap-io/client/components/hoc/withAnalytics";
import withBugsnag from "tap-io/client/components/hoc/withBugsnag";
import withTranslations from "tap-io/client/components/hoc/withTranslations";
import withCookieManager from "tap-io/client/components/hoc/withCookieManager";
import { authService } from "tap-io/client/services";
import CheckConnection from "tap-io/client/components/utils/CheckConnection";

import withAuthentication from "./components/auth/withAuthentication";
import BarWrapper from "./components/bar/BarWrapper";
import ScanQRCodePage from "./pages/ScanQRCodePage";
import { cookieHelper } from "tap-io/client/helpers";
import config from "tap-io/client/env";

if (config.gtm && config.gtm.id) {
  TagManager.initialize({ gtmId: config.gtm.id });
}

const DEFAULT_COLOR_PRIMARY = "#34b9c8";
const DEFAULT_COLOR_SECONDARY = "#faeb52";

class App extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.state = {
      theme: this.createTheme(DEFAULT_COLOR_PRIMARY, DEFAULT_COLOR_SECONDARY),
      locale: "nl_BE"
    };
  }

  componentDidMount() {
    authService.onAuthStateChanged((user) => {
      this.setState({ user });

      if (user === null) {
        authService
          .signInAnonymously()
          .then(() => {})
          .catch((error) => {
            console.warn(error);
          });
      }
    });
  }

  createTheme = (primaryColor, secondaryColor) => {
    const theme = createTheme(appTheme.options(primaryColor, secondaryColor));

    if (theme.palette.tertiary) {
      theme.palette.tertiary = theme.palette.augmentColor({
        color: theme.palette.tertiary,
        name: "tertiary"
      });
    }

    return theme;
  };

  setThemeColors = (primaryColor, secondaryColor) => {
    this.setState({
      theme: this.createTheme(primaryColor, secondaryColor)
    });
  };

  setLocale = (locale) => {
    const { changeLanguage } = this.props;

    Moment.locale(locale);
    cookieHelper.setCookieManagerLocale(locale);
    changeLanguage(locale);
    this.setState({ locale });
  };

  setBarId = (id, search, isCrew, authUser) => {
    /*const { changeLanguage } = this.props;

    // Unsubscribe
    if (this.unsubscribeBarInfo) {
      this.unsubscribeBarInfo();
      this.unsubscribeBarInfo = undefined;
    }
    if (this.unsubscribeBarMenu) {
      this.unsubscribeBarMenu();
      this.unsubscribeBarMenu = undefined;
    }
    if (this.unsubscribeBarBases) {
      this.unsubscribeBarBases();
      this.unsubscribeBarBases = undefined;
    }
    if (this.unsubscribeBarZones) {
      this.unsubscribeBarZones();
      this.unsubscribeBarZones = undefined;
    }

    // Info
    if (id && search && search.locator) {
      this.unsubscribeBarInfo = store.onGetBarInfo(id, (info) => {
        if (info) {
          const bar = {
            id,
            search,
            ...info
          };
          this.setState({ bar });

          // Language
          if (info.locales && info.locales.order) {
            changeLanguage(info.locales.order);
          }

          // Assets
          this.refreshAssets(id, info.logo);
        }
      });

      // menu
      this.unsubscribeBarMenu = store.onGetBarMenu(id, isCrew, (menu) => {
        this.setState({
          menu: {
            ...menu,
            zones: this.getMenuPerZone(menu, this.state.bases, this.state.zones)
          }
        });
      });

      // Bases
      this.unsubscribeBarBases = store.onGetBarBases(id, (bases) => {
        this.setState({
          bases,
          menu: {
            ...this.state.menu,
            zones: this.getMenuPerZone(this.state.menu, bases, this.state.zones)
          }
        });
      });

      // Zones
      this.unsubscribeBarZones = store.onGetBarZones(id, (zones) => {
        this.setState({
          zones: { all: zones },
          menu: {
            ...this.state.menu,
            zones: this.getMenuPerZone(this.state.menu, this.state.bases, {
              all: zones
            })
          }
        });
      });
    }*/
  };

  getMenuPerZone = (menu, bases, zones) => {
    /*const menuPerZone = {};

    if (menu && bases && zones && zones.all) {
      zones.all.forEach((zone) => {
        const menuIds = getZoneMenuIds(zone, bases);
        menuPerZone[zone.code] = {
          menuIds,
          elements: menu.elements.filter(
            (element) => menuIds.indexOf(element.menuId) >= 0
          )
        };
      });
    }

    return menuPerZone;*/
  };

  refreshAssets(barId, logo) {
    /*this.setState({
      assets: null
    });

    if (barId && logo) {
      // Url
      storage
        .getLogoImage(barId, logo)
        .then((url) => {
          let { assets } = this.state;

          if (assets === null) {
            assets = {};
          }
          if (assets.logo === undefined) {
            assets.logo = {};
          }
          assets.logo.url = url;

          this.setState({ assets });
        })
        .catch((error) => {
          console.warn(error);
        });
    }*/
  }

  render() {
    const { theme, locale } = this.state;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment} locale={locale}>
            <div className="App">
              {appTheme.faviconFolder && (
                <Helmet>
                  <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${appTheme.faviconFolder}/apple-touch-icon.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={`${appTheme.faviconFolder}/favicon-32x32.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`${appTheme.faviconFolder}/favicon-16x16.png`}
                  />
                  <link rel="manifest" href="/favicon-abi/site.webmanifest" />
                  <link
                    rel="mask-icon"
                    href={`${appTheme.faviconFolder}/safari-pinned-tab.svg`}
                    color="#000000"
                  />
                  <link
                    rel="shortcut icon"
                    href={`${appTheme.faviconFolder}/favicon.ico`}
                  />
                  <meta name="msapplication-TileColor" content="#ffffff" />
                  <meta
                    name="msapplication-config"
                    content={`${appTheme.faviconFolder}/browserconfig.xml`}
                  />
                  <meta name="theme-color" content="#ffffff" />
                </Helmet>
              )}
              <CssBaseline />
              <ToastContainer />
              <CheckConnection />
              <Switch>
                <Route
                  exact
                  path={routes.SCAN_QR_CODE}
                  render={(params) => (
                    <ScanQRCodePage {...params} {...this.props} />
                  )}
                />
                <Route
                  path={routes.ORDER_ROOT}
                  render={(params) => (
                    <BarWrapper
                      locale={locale}
                      setThemeColors={this.setThemeColors}
                      setLocale={this.setLocale}
                    ></BarWrapper>
                  )}
                />
              </Switch>
            </div>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

/*
<Route
                  exact
                  path={routes.CREATE_ORDER}
                  render={(params) => <CreateOrder {...params} />}
                />
                <Route
                  exact
                  path={routes.VIEW_ORDER}
                  render={(params) => <ViewOrder {...params} />}
                />
                <Route
                  render={(params) => {
                    window.location.href = "https://watdrinkje.be";
                    return null;
                  }}
                />
*/
const AppWithoutRouter = withRouter(
  withTranslations(
    withCookieManager(withBugsnag(withAnalytics(withAuthentication(App))))
  )
);

const AppWithRouter = () => (
  <BrowserRouter>
    <AppWithoutRouter />
  </BrowserRouter>
);

export default AppWithRouter;
