import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import OrderDeliveryLine from "./OrderDeliveryLine";

const styles = (theme) => ({});

class OrderNameAndDeliveryLine extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleOrderNameChange = (event) => {
    const { onOrderNameChange } = this.props;

    onOrderNameChange(event.target.value);
  };

  render() {
    const { classes, t, bar, orderName, orderServiceOption } = this.props;

    return (
      <div>
        <OrderDeliveryLine orderServiceOption={orderServiceOption} />
        <ListItem>
          <TextField
            variant="standard"
            label={bar.getCustomerAddressedAs(
              t("label.name"),
              t("order.table-number-or-other-reference")
            )}
            value={orderName}
            onChange={this.handleOrderNameChange}
            fullWidth
            required
            error={orderName.trim().length === 0}
            id="customer-ref"
            name="customer-ref"
            autoComplete="chrome-off"
          />
        </ListItem>
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderNameAndDeliveryLine));
