import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import { ListItem, Typography } from "@mui/material";

import config from "../../env";

const styles = (theme) => ({
  link: {
    fontWeight: "bold",
    textDecoration: "none",
    color: theme.palette.primary.main
  }
});

class ConsentAgreement extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      consents: {
        acceptTermsOfUse: true, //false,
        acceptPrivacyPolicy: true //false
      }
    };
  }

  componentDidMount() {
    const { onChange, value } = this.props;
    /*const { consents } = this.state;

    if (value !== undefined) {
      const newConsents = {};

      for (let type in consents) {
        newConsents[type] = value === true;
      }

      this.setState({ consents: newConsents });
    }*/

    onChange(this.hasAllConsents());
  }

  handleConsentChange = (event) => {
    const { onChange } = this.props;
    const { consents } = this.state;

    const value = event.target.checked === true;

    const newConsents = {};
    for (let type in consents) {
      newConsents[type] = value;
    }

    this.setState(
      {
        consents: newConsents
      },
      () => {
        onChange(this.hasAllConsents());
      }
    );
  };

  hasAllConsents() {
    const { consents } = this.state;

    for (let type in consents) {
      if (!consents[type]) {
        return false;
      }
    }

    return true;
  }

  render() {
    const { classes, t, label } = this.props;

    return (
      <ListItem>
        <Typography variant="caption">
          {label}{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={config.links.termsOfUse}
            className={classes.link}
          >
            {t("legal.terms-of-use")}
          </a>{" "}
          {t("label.and-the")}{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={config.links.privacyPolicy}
            className={classes.link}
          >
            {t("legal.privacy-policy")}
          </a>
          .
        </Typography>
      </ListItem>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ConsentAgreement));

/*
<FormControlLabel
  control={
    <Checkbox
      checked={this.hasAllConsents()}
      onChange={this.handleConsentChange}
    />
  }
  label={
    <div>
      {t("legal.i-consent-to-the")}{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href={config.links.termsOfUse}
        className={classes.link}
      >
        {t("legal.terms-of-use")}
      </a>{" "}
      {t("label.and-the")}{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href={config.links.privacyPolicy}
        className={classes.link}
      >
        {t("legal.privacy-policy")}
      </a>
      .
    </div>
  }
/>
*/
