import React, { PureComponent } from "react";

import ReactGA from "react-ga4";

import CookieBot from "../utils/CookieBot";
import OneTrust from "../utils/OneTrust";
import config from "../../env";
import { deviceStorage } from "../../../storage";
import { cookieHelper } from "../../helpers";

const withCookieManager = (Component) => {
  class WithCookieManager extends PureComponent {
    constructor() {
      super();
    }

    componentDidMount() {
      this.unsubscribeOnConsentReady = cookieHelper.onConsentReady(
        this.handleOnConsentReady
      );
    }

    componentWillUnmount() {
      if (this.unsubscribeOnConsentReady) {
        this.unsubscribeOnConsentReady();
        this.unsubscribeOnConsentReady = undefined;
      }
    }

    handleOnConsentReady = (consent) => {
      // https://www.simoahava.com/analytics/consent-settings-google-tag-manager/
      ReactGA.gtag("consent", "update", {
        functionality_storage: consent.hasNecessaryConsent
          ? "granted"
          : "denied",
        security_storage: consent.hasNecessaryConsent ? "granted" : "denied",
        personalization_storage: consent.hasPreferencesConsent
          ? "granted"
          : "denied",
        ad_storage: consent.hasMarketingConsent ? "granted" : "denied",
        analytics_storage: consent.hasStatisticsConsent ? "granted" : "denied"
      });

      deviceStorage.isDisabled = !consent || !consent.hasPreferencesConsent;
    };

    render() {
      return (
        <>
          {config && config.cookies && config.cookies.manager.domainId && (
            <>
              {cookieHelper.renderCookieManagerComponent(
                <CookieBot domainGroupId={config.cookies.manager.domainId} />,
                <OneTrust domainScript={config.cookies.manager.domainId} />
              )}
            </>
          )}
          <Component {...this.props} />
        </>
      );
    }
  }

  return WithCookieManager;
};

export default withCookieManager;
