import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import withStyles from "@mui/styles/withStyles";

import { barHelper } from "../../../helpers";

const styles = (theme) => ({
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  icon: {
    marginRight: theme.spacing()
  },
  button: {
    minWidth: 48,
    margin: `0 2px`,
    fontWeight: "normal",
    opacity: 0.6
  },
  selectedButton: {
    fontWeight: "bold",
    opacity: 1
  }
});

class SetLocale extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSetLocale = (locale) => (event) => {
    const { setLocale } = this.props;

    setLocale(locale);
  };

  render() {
    const { t, classes, locale } = this.props;

    return (
      <div className={classes.content}>
        <LanguageIcon className={classes.icon} />
        {barHelper.getAvailableLocales().map((availableLocale) => (
          <Button
            key={availableLocale}
            size="small"
            className={classNames(classes.button, {
              [classes.selectedButton]: availableLocale === locale
            })}
            onClick={this.handleSetLocale(availableLocale)}
          >
            {t(
              `locale.${availableLocale.replace("_", "-").toLowerCase()}-short`
            )}
          </Button>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SetLocale));
