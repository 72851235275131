import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import Voucher from "tap-io/client/components/voucher/Voucher";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({});

class ShowVoucherDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, isOpen, onClose, bar, voucher } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogTitle>{t("voucher.voucher")}</DialogTitle>
        <DialogContent>
          <Voucher bar={bar} voucher={voucher} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ShowVoucherDialog));
