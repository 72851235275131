import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, Drawer, List, ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import SelectOrderServiceOption from "./SelectOrderServiceOption";

const styles = (theme) => ({
  drawer: {
    //maxHeight: "90%"
  },
  content: {
    width: "100%",
    maxWidth: 600,
    margin: "0 auto"
  }
});

class SelectOrderDeliveryDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  isOrderServiceOptionValid = () => {
    const { orderServiceOption } = this.props;

    return !!orderServiceOption;
  };

  render() {
    const {
      classes,
      t,
      isOpen,
      onClose,
      bar,
      bases,
      activeFees,
      orderServiceOption,
      onOrderServiceOptionChange
    } = this.props;

    const isValid = this.isOrderServiceOptionValid();

    return (
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.content}>
          <List>
            <ListItem>
              <Typography variant="h6" className={classes.title}>
                {t("order.select-service-option")}
              </Typography>
            </ListItem>
          </List>
          <SelectOrderServiceOption
            bar={bar}
            bases={bases}
            activeFees={activeFees}
            orderServiceOption={orderServiceOption}
            onOrderServiceOptionChange={onOrderServiceOptionChange}
            hideTitle={true}
          />
          <List>
            <ListItem>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={!isValid}
                onClick={onClose}
              >
                {t("label.confirm")}
              </Button>
            </ListItem>
          </List>
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SelectOrderDeliveryDrawer)
);
