import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  container: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  background: {
    position: "absolute",
    zIndex: 0,
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    filter: "blur(20px)",
    opacity: 0.4
  },
  ad: {
    position: "relative",
    zIndex: 1,
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain"
  }
});

class Ad extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, ad } = this.props;

    return (
      <div
        className={classes.container}
        style={{ backgroundColor: ad.backgroundColor }}
      >
        <div
          className={classes.ad}
          style={{
            backgroundImage: `url("${ad.landscapeImageUrl}")`
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Ad);

/*
<div
  className={classes.background}
  style={{
    backgroundImage: `url("${ad.landscapeImageUrl}")`
  }}
/>
*/
