import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, ListItem, TextField, Typography } from "@mui/material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  tipContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  tipButton: {
    minWidth: 120
  },
  tipLabel: {
    width: "100%"
  },
  tipText: {
    width: 80,
    margin: "4px 10px 0 10px"
  }
});

class OrderTipLine extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleOrderTipChange = (event) => {
    const { onOrderTipChange } = this.props;

    const value = event.target.value.replace(/^0+(?=\d)/, ""); // Remove leading zeros

    onOrderTipChange(parseFloat(value));
  };

  handleCalculateOrderTipForRoundedTotal = () => {
    const { onOrderTipChange, orderItemsTotal } = this.props;

    const roundedTotal = Math.ceil(orderItemsTotal);
    const orderTip = parseFloat(
      Math.round((roundedTotal - orderItemsTotal) * 100) / 100
    );

    onOrderTipChange(orderTip);
  };

  render() {
    const { classes, t, bar, orderTip } = this.props;

    return (
      <ListItem>
        <div className={classes.tipContainer}>
          <Typography variant="body1" className={classes.tipLabel}>
            {t("order.tip")}
          </Typography>
          <Button
            size="small"
            className={classes.tipButton}
            onClick={this.handleCalculateOrderTipForRoundedTotal}
            endIcon={<KeyboardTabIcon />}
          >
            {t("order.round-tip")}
          </Button>
          <TextField
            variant="standard"
            type="number"
            size="small"
            className={classes.tipText}
            value={`${orderTip}`}
            onChange={this.handleOrderTipChange}
          />
          <Typography variant="body1">{bar.getOrderCurrency()}</Typography>
        </div>
      </ListItem>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderTipLine));
