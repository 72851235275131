import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import withStyles from "@mui/styles/withStyles";

import OrderHistoryDrawer from "./OrderHistoryDrawer";

const styles = (theme) => ({
  blackButton: {
    color: "black"
  },
  icon: {
    marginRight: theme.spacing(1)
  }
});

class ShowOrderHistoryButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOrderHistoryDrawerOpen: false
    };
  }

  handleShowOrderHistory = () => {
    this.setState({ isOrderHistoryDrawerOpen: true });
  };

  handleOrderHistoryDrawerClose = () => {
    this.setState({ isOrderHistoryDrawerOpen: false });
  };

  render() {
    const {
      classes,
      t,
      barLocator,
      bar,
      hideLabel,
      fullWidth,
      variant,
      size,
      color
    } = this.props;
    const { isOrderHistoryDrawerOpen } = this.state;

    return (
      <>
        <OrderHistoryDrawer
          isOpen={isOrderHistoryDrawerOpen}
          onClose={this.handleOrderHistoryDrawerClose}
          barLocator={barLocator}
          bar={bar}
        />
        <Button
          className={color ? undefined : classes.blackButton}
          fullWidth={fullWidth || hideLabel === false}
          variant={variant}
          size={size}
          color={color}
          onClick={this.handleShowOrderHistory}
        >
          <HistoryIcon className={classes.icon} />
          {!hideLabel && t("order.order-history")}
        </Button>
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ShowOrderHistoryButton)
);
