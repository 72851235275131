import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { AppBar, Toolbar, useScrollTrigger } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import theme from "tap-io/client/theme";

import BarTitle from "./BarTitle";

const LARGE_HEADER_HEIGHT = 160;
const SMALL_HEADER_HEIGHT = 80;

function WithScrollTrigger(props) {
  const { children, onTrigger } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: LARGE_HEADER_HEIGHT
  });

  setTimeout(() => {
    onTrigger(trigger);
  }, 10);

  return children;
}

const styles = (theme) => ({
  shadowHeader: {
    position: "sticky",
    top: 0,
    height: LARGE_HEADER_HEIGHT
  },
  appBar: {
    position: "fixed",
    padding: theme.spacing(0.5),
    transition: "height 0.3s ease",
    background: "transparent",
    "&:before": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: theme.spacing(0.5),
      content: "''",
      backgroundColor: theme.palette.background.default
    }
  },
  largeAppBar: {
    height: LARGE_HEADER_HEIGHT
  },
  smallAppBar: {
    height: SMALL_HEADER_HEIGHT
  },
  toolBar: {
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxWidth: 600,
    height: "100%",
    margin: "0 auto",
    //padding: `${HEADER_PADDING}px 0 0 0`,
    borderRadius: 4,
    backgroundColor: theme.palette.tertiary.main,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  gradient: {
    position: "absolute",
    zIndex: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 4,
    transition: "opacity 0.3s ease",
    backgroundImage:
      "linear-gradient(180deg,hsla(0, 0%, 35.29%, 0) 0%,hsla(0, 0%, 34.53%, 0.034375) 16.36%,hsla(0, 0%, 32.42%, 0.125) 33.34%,hsla(0, 0%, 29.18%, 0.253125) 50.1%,hsla(0, 0%, 24.96%, 0.4) 65.75%,hsla(0, 0%, 19.85%, 0.546875) 79.43%,hsla(0, 0%, 13.95%, 0.675) 90.28%,hsla(0, 0%, 7.32%, 0.765625) 97.43%,hsla(0, 0%, 0%, 0.8) 100%)"
  },
  logo: {
    position: "relative",
    zIndex: 10,
    width: "100%",
    height: "100%",
    transition: "margin 0.3s ease",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat"
  },
  smallLogo: {
    margin: 10
  },
  largeLogo: {
    margin: `20px 0 40px 0`
  },
  title: {
    position: "absolute",
    zIndex: 10,
    bottom: 10,
    left: 0,
    width: "100%"
  },
  textShadow: {
    textShadow: "0 2px 3px rgba(0, 0, 0, 0.3)"
  }
});

class BarHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLargeHeader: true
    };
  }

  componentDidMount() {
    this.triggerUpdate();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isLargeHeader } = this.state;

    const prevIsLargeHeader = prevState.isLargeHeader;

    if (isLargeHeader !== prevIsLargeHeader) {
      this.triggerUpdate();
    }
  }

  triggerUpdate = () => {
    const { onUpdate } = this.props;
    const { isLargeHeader } = this.state;

    onUpdate({
      isLarge: isLargeHeader,
      height: isLargeHeader ? LARGE_HEADER_HEIGHT : SMALL_HEADER_HEIGHT
    });
  };

  handleScrollTrigger = (isTriggered) => {
    this.setState({ isLargeHeader: !isTriggered });
  };

  render() {
    const { classes, t, bar, assets } = this.props;
    const { isLargeHeader } = this.state;

    const hasCoverImage = !!assets?.coverImageUrl;

    return (
      <WithScrollTrigger onTrigger={this.handleScrollTrigger}>
        <div className={classes.shadowHeader} />
        <AppBar
          position="sticky"
          className={classNames(
            classes.appBar,
            isLargeHeader ? classes.largeAppBar : classes.smallAppBar
          )}
        >
          <Toolbar
            className={classes.toolBar}
            style={{
              backgroundImage: assets?.coverImageUrl
                ? `url(${assets.coverImageUrl})`
                : null
            }}
          >
            {hasCoverImage && (
              <div
                className={classes.gradient}
                style={{ opacity: isLargeHeader ? 1 : 0 }}
              />
            )}
            <div
              className={classNames(
                classes.logo,
                isLargeHeader ? classes.largeLogo : classes.smallLogo
              )}
              style={{
                backgroundImage: `url(${
                  assets && assets.logoImageUrl
                    ? assets.logoImageUrl
                    : theme.assets.logo
                })`
              }}
            />
            {isLargeHeader && (
              <div
                className={classNames(classes.title, {
                  [classes.textShadow]: hasCoverImage
                })}
              >
                <BarTitle
                  bar={bar}
                  color={hasCoverImage ? "white" : undefined}
                />
              </div>
            )}
          </Toolbar>
        </AppBar>
      </WithScrollTrigger>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(BarHeader));
