import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { serviceOptionHelper } from "tap-io/helpers";

const styles = (theme) => ({
  zone: {
    fontSize: 16
  }
});

class OrderZoneAndDeliveryLine extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, orderZone, orderLayoutPath, orderServiceOption } =
      this.props;

    return (
      <ListItem>
        {orderZone && (
          <strong className={classes.zone}>
            {`${orderZone.name} ${
              orderLayoutPath && orderLayoutPath.length > 0
                ? " • " +
                  orderZone.layout
                    .getPathLocations(orderLayoutPath)
                    .map((location) => location.name)
                    .join(" • ")
                : ""
            } • ${
              orderServiceOption
                ? serviceOptionHelper.getServiceOptionLabel(
                    orderServiceOption,
                    t
                  )
                : `(${t("label.unknown")})`
            }`}
          </strong>
        )}
      </ListItem>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderZoneAndDeliveryLine));
