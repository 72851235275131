import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { voucherHelper } from "tap-io/helpers";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  voucherItem: {
    width: "100%",
    marginRight: 0
  },
  voucherItemLabel: {
    width: "100%"
  },
  voucherItemLabelContainer: {
    display: "flex",
    marginRight: -11
  },
  voucherItemLabelText: {
    flexGrow: 1
  },
  voucherId: {
    fontStyle: "italic"
  }
});

class SelectVouchers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleVoucherIdsChange = (voucher) => (event) => {
    const value = event.target.checked;

    const { onSelectedVoucherIdsChange } = this.props;

    const selectedVoucherIds = [...this.props.selectedVoucherIds];
    const voucherIdIndex = selectedVoucherIds.indexOf(voucher.id);

    if (value) {
      if (voucherIdIndex < 0) {
        selectedVoucherIds.push(voucher.id);
      }
    } else {
      if (voucherIdIndex >= 0) {
        selectedVoucherIds.splice(voucherIdIndex, 1);
      }
    }

    onSelectedVoucherIdsChange(selectedVoucherIds);
  };

  render() {
    const { classes, t, isDisabled, vouchers, selectedVoucherIds } = this.props;

    return (
      <div className={classes.container}>
        {voucherHelper.sortVouchersInRedeemOrder(vouchers).map((voucher) => (
          <FormControlLabel
            key={voucher.id}
            className={classes.voucherItem}
            classes={{
              label: classes.voucherItemLabel
            }}
            control={
              <Checkbox
                disabled={isDisabled}
                checked={selectedVoucherIds.indexOf(voucher.id) >= 0}
                onChange={this.handleVoucherIdsChange(voucher)}
              />
            }
            label={
              <Typography className={classes.voucherItemLabelContainer}>
                <span className={classes.voucherItemLabelText}>
                  {t("voucher.voucher")}{" "}
                  <span className={classes.voucherId}>{voucher.id}</span>
                </span>
                <span>{voucher.remainingValueToString(t)}</span>
              </Typography>
            }
          />
        ))}
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(SelectVouchers));
