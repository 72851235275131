import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import withStyles from "@mui/styles/withStyles";

import OrderItemsStep from "./OrderItemsStep";
import OrderCustomerStep from "./OrderCustomerStep";
import OrderZoneAndDeliveryLine from "./OrderZoneAndDeliveryLine";
import OrderNameAndDeliveryLine from "./OrderNameAndDeliveryLine";
import OrderTotal from "./OrderTotal";
import AssistanceNeeded from "../common/AssistanceNeeded";

const styles = (theme) => ({
  stepCard: {
    margin: `${theme.spacing()} !important`,
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper
  },
  accordion: {
    "&:before": {
      display: "none"
    }
  },
  accordionDetails: {
    display: "block",
    padding: 0
  }
});

const ORDER_ITEMS_STEP = "orderItemsStep";
const ORDER_CUSTOMER_STEP = "orderCustomerStep";

class OrderConfirmationStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showOrderItems: true
    };

    this.nextHandlers = {};
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(this.handleNext);
  }

  handleNext = () => {
    for (let stepName in this.nextHandlers) {
      try {
        this.nextHandlers[stepName]();
      } catch (error) {
        if (stepName === ORDER_CUSTOMER_STEP) {
          this.setState({ showOrderItems: false });
        }

        throw error;
      }
    }
  };

  handleSetOnNext = (stepName) => (onNext) => {
    this.nextHandlers[stepName] = onNext;
  };

  handleOrderSummaryClick = (event, isExpanded) => {
    this.setState({ showOrderItems: isExpanded });
  };

  render() {
    const {
      theme,
      classes,
      t,
      allowOrderChange,
      bar,
      vouchers,
      orderName,
      orderItems,
      orderItemsAmount,
      orderItemsTotal,
      orderDistributedDeposits,
      orderDepositsAmount,
      orderDepositsTotal,
      orderTip,
      orderZone,
      orderLayoutPath,
      orderServiceOption,
      orderDeliveryContact,
      orderFees,
      orderTotal,
      orderFields,
      orderVoucherIds,
      onOrderVoucherIdsChange,
      onOrderItemAmountChange,
      onOrderNoteChange,
      onOrderNameChange,
      onOrderDeliveryContactChange,
      onOrderFieldsChange,
      onOrderTipChange,
      orderNote
    } = this.props;
    const { showOrderItems } = this.state;

    return (
      <div>
        <div className={classes.stepCard}>
          {bar.isUsingZones() ? (
            <OrderZoneAndDeliveryLine
              orderZone={orderZone}
              orderLayoutPath={orderLayoutPath}
              orderServiceOption={orderServiceOption}
            />
          ) : (
            <OrderNameAndDeliveryLine
              bar={bar}
              orderName={orderName}
              orderServiceOption={orderServiceOption}
              onOrderNameChange={onOrderNameChange}
            />
          )}
        </div>
        <Accordion
          expanded={showOrderItems}
          onChange={this.handleOrderSummaryClick}
          className={classes.stepCard}
          classes={{
            root: classes.accordion
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <OrderTotal
              bar={bar}
              orderTotal={orderTotal}
              orderItemsAmount={orderItemsAmount}
            />
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <OrderItemsStep
              allowOrderChange={allowOrderChange}
              bar={bar}
              vouchers={vouchers}
              orderItems={orderItems}
              orderItemsAmount={orderItemsAmount}
              orderItemsTotal={orderItemsTotal}
              orderDistributedDeposits={orderDistributedDeposits}
              orderDepositsAmount={orderDepositsAmount}
              orderDepositsTotal={orderDepositsTotal}
              orderTip={orderTip}
              orderZone={orderZone}
              orderLayoutPath={orderLayoutPath}
              orderServiceOption={orderServiceOption}
              orderFees={orderFees}
              orderTotal={orderTotal}
              orderVoucherIds={orderVoucherIds}
              onOrderItemAmountChange={onOrderItemAmountChange}
              onOrderTipChange={onOrderTipChange}
              onOrderVoucherIdsChange={onOrderVoucherIdsChange}
              setOnNext={this.handleSetOnNext(ORDER_ITEMS_STEP)}
            />
          </AccordionDetails>
        </Accordion>
        <div className={classes.stepCard}>
          <OrderCustomerStep
            bar={bar}
            orderZone={orderZone}
            orderLayoutPath={orderLayoutPath}
            orderName={orderName}
            orderFields={orderFields}
            orderNote={orderNote}
            orderServiceOption={orderServiceOption}
            orderDeliveryContact={orderDeliveryContact}
            onOrderNameChange={onOrderNameChange}
            onOrderDeliveryContactChange={onOrderDeliveryContactChange}
            onOrderFieldsChange={onOrderFieldsChange}
            onOrderNoteChange={onOrderNoteChange}
            setOnNext={this.handleSetOnNext(ORDER_CUSTOMER_STEP)}
          />
          <AssistanceNeeded />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderConfirmationStep));
