import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import SyncIcon from "@mui/icons-material/Sync";
import PaymentMessage from "tap-io/client/components/payment/PaymentMessage";

const styles = (theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.primary.contrastText
  }
});

class PaymentIsBeingProcessedMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t } = this.props;

    return (
      <PaymentMessage
        color="#2196f3"
        icon={<SyncIcon />}
        text={t("order.order-payment-is-being-processed")}
        description={
          <span>
            {`${t("label.can-take-a-couple-of-minutes")}. `}
            <a
              color="inherit"
              className={classes.link}
              href=""
              onClick={() => {
                window.location.reload();
              }}
            >
              <strong>{t("payment.refresh-the-page")}</strong>
            </a>
            {` ${t("payment.if-the-payment-status-has-not-changed")}`}
          </span>
        }
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(PaymentIsBeingProcessedMessage)
);
