import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ScheduleIcon from "@mui/icons-material/Schedule";
import withStyles from "@mui/styles/withStyles";

import PaymentMessage from "tap-io/client/components/payment/PaymentMessage";

const styles = (theme) => ({});

class OrderWillBePaidDeferredMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <PaymentMessage
        color="#ff9800"
        icon={<ScheduleIcon />}
        text={t("order.order-will-be-paid-deferred")}
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderWillBePaidDeferredMessage)
);
