import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { CircularProgress, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import {
  feeHelper,
  serviceOptionHelper,
  utilsHelper,
  voucherHelper,
  zoneHelper,
  depositHelper
} from "tap-io/helpers";
import { orderService, paymentService } from "tap-io/client/services";
import LayoutPath from "tap-io/models/zone/LayoutPath";
import DeliveryMethod from "tap-io/models/delivery/DeliveryMethod";

import OrderDrawer from "./OrderDrawer";
import SelectOrderItems from "./SelectOrderItems";
import OrderSubmittingDialog from "./OrderSubmittingDialog";
import withAuthorization from "../auth/withAuthorization";
import VerifyOrderButton from "./VerifyOrderButton";
import ApplyDeliveryCache from "../storage/ApplyDeliveryCache";
import ApplyCustomerFieldsCache from "../storage/ApplyCustomerFieldsCache";
import ApplyItemsCache from "../storage/ApplyItemsCache";
import SelectOrderPrerequisitesDrawer from "./SelectOrderPrerequisitesDrawer";
import SelectOrderPrerequisitesButton from "./SelectOrderPrerequisitesButton";
import BarTitle from "../bar/BarTitle";
import ShowOrderHistoryButton from "./ShowOrderHistoryButton";

const styles = (theme) => ({
  content: {},
  preventContentScroll: {
    position: "fixed",
    visibility: "hidden"
  },
  noMenuElements: {
    padding: "100px 0",
    fontWeight: "bold"
  },
  actionButtons: {
    position: "sticky",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  centerButtons: {
    bottom: 44,
    pointerEvents: "none"
  },
  centerButton: {
    width: "100%",
    maxWidth: 608,
    padding: theme.spacing(0, 0.5),
    pointerEvents: "auto"
  },
  bottomButtons: {
    display: "flex",
    flexDirection: "row",
    bottom: 0,
    padding: theme.spacing(0.5),
    backgroundColor: "rgba(235, 235, 235, 0.95)",
    "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
      backdropFilter: "saturate(180%) blur(20px)",
      backgroundColor: "rgba(235, 235, 235, 0.75)",
    }
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: 600,
    margin: "0 auto",
    borderRadius: 4,
    backgroundColor: "rgba(235, 235, 235, 0.95)",
    "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
      backdropFilter: "saturate(180%) blur(20px)",
      backgroundColor: "rgba(235, 235, 235, 0.85)",
    },
  },
  barTitle: {
    marginLeft: theme.spacing(2),
    width: 0,
    transition: "width 0.3s ease"
  },
  visibleBarTitle: {
    width: "100%"
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 400
  },
  spacing: {
    height: 10
  }
});

class CreateOrder extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      isOrderDrawerOpen: false,
      isSelectOrderPrerequisitesDrawerOpen: false,
      isOrderSubmittingDialogOpen: false,
      isSelectOrderItemsVisible: false, // TO FIX: temporary solution awaiting virtualized list
      orderId: null,
      orderCustomer: {},
      orderItems: {},
      orderTip: 0,
      orderFees: [],
      orderItemsAmount: 0,
      orderItemsTotal: 0,
      orderTotal: 0,
      orderDistributedDeposits: {},
      orderDepositsAmount: 0,
      orderDepositsTotal: 0,
      orderZone: null,
      orderLayoutPath: null,
      orderName: "",
      orderNote: "",
      orderFields: null,
      orderVoucherIds: [],
      orderServiceOption: null,
      orderDeliveryContact: null
    };
  };

  componentDidMount() {
    const { bar, orderName, vouchers } = this.props;

    this.handleSetOrderName(orderName);

    if (bar.isAllowingVoucherPayments() && vouchers) {
      this.handleOrderVoucherIdsChange(vouchers.map((voucher) => voucher.id));
    }

    this.checkFees();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { t, bar, bases, allZones, orderName, vouchers, menuItems, menuDeposits } =
      this.props;
    const {
      isSelectOrderPrerequisitesDrawerOpen,
      isSelectOrderItemsVisible,
      orderZone,
      orderLayoutPath,
      orderServiceOption,
      orderItems
    } = this.state;

    const prevOrderName = prevProps.orderName;

    if (orderName !== prevOrderName) {
      this.handleSetOrderName(orderName);
    }

    if (orderZone) {
      if (
        allZones.filter((zone) => zone.code === orderZone.code).length === 0
      ) {
        this.handleOrderZoneChange(null, null);
        this.setState({ isOrderDrawerOpen: false });
      }
    }

    // Clear order items if they are not available anymore
    let orderItemsChanged = false;
    if (Object.keys(orderItems).length > 0) {
      if (menuItems) {
        const updatedOrderItems = {};

        menuItems.forEach((menuItem) => {
          if (menuItem.isAvailable && orderItems[menuItem.id]) {
            updatedOrderItems[menuItem.id] = {
              ...orderItems[menuItem.id],
              menuItem
            };
          }
        });


        // Deposits
        const menuDepositIds = menuDeposits ? menuDeposits.map(menuDeposit => menuDeposit.id) : [];
        const prevMenuDepositIds = prevProps.menuDeposits ? prevProps.menuDeposits.map(menuDeposit => menuDeposit.id) : [];

        if (
          Object.keys(updatedOrderItems).length !==
          Object.keys(orderItems).length ||
          !utilsHelper.areArraysEqualShallow(menuDepositIds, prevMenuDepositIds)
        ) {
          this.handleOrderItemsChange(updatedOrderItems);
          orderItemsChanged = true;
        }
      } else {
        if (Object.keys(orderItems).length > 0) {
          this.handleClearOrderItems();
          orderItemsChanged = true;
        }
      }
    } else {
      if (menuItems) {
        const productVouchers = (vouchers || []).filter(voucher =>
          voucherHelper.isProductVoucher(voucher) && !voucher.mustBeRedeemedInOneGo
        );

        const productAmounts = productVouchers.reduce((productAmounts, voucher) => {
          voucher.remainingValue.forEach(value => {
            value.productIds.forEach(productId => {
              if (!productAmounts[productId]) {
                productAmounts[productId] = 0;
              }

              productAmounts[productId] += value.amount;
            })
          });

          return productAmounts;
        }, {});

        const voucherOrderItems = {};
        menuItems.forEach((menuItem) => {
          if (menuItem.productId && menuItem.isAvailable && productAmounts[menuItem.productId]) {
            voucherOrderItems[menuItem.id] = {
              amount: productAmounts[menuItem.productId],
              menuItem
            };
            delete productAmounts[menuItem.productId];
          }
        });

        if (Object.keys(voucherOrderItems).length > 0) {
          this.handleOrderItemsChange(voucherOrderItems);
        }
      }
    }
    if (orderItemsChanged) {
      toast.info(
        t(
          "order.order-has-been-adjusted-because-availability-of-some-items-has-changed"
        )
      );
    }

    // Ask customer to select zone/delivery if no zone is selected yet
    const isOrderDeliveryValid = !!orderServiceOption;
    const isOrderZoneValid =
      !bar.isUsingZones() ||
      (orderZone &&
        orderZone.layout &&
        orderZone.layout.isAllowedToOrderForPath(
          orderLayoutPath ? orderLayoutPath : new LayoutPath()
        ));

    if (!isOrderDeliveryValid || !isOrderZoneValid) {
      let openSelectOrderPrerequisitesDrawerIfNotOpen = true;

      if (orderZone) {
        if (bases) {
          const availableServiceOptions = this.getAvailableServiceOptions();

          if (availableServiceOptions.length === 1) {
            this.handleOrderServiceOptionChange(availableServiceOptions.all[0]);

            openSelectOrderPrerequisitesDrawerIfNotOpen = false;
          }
        } else {
          openSelectOrderPrerequisitesDrawerIfNotOpen = false;
        }
      } else {
        if (allZones && allZones.length === 1) {
          const zone = allZones[0];

          if (!zone.layout.hasChildren) {
            // Automatically set orderZone if there is only one available
            this.handleOrderZoneChange(allZones[0], null);

            openSelectOrderPrerequisitesDrawerIfNotOpen = false;
          }
        }
      }

      if (
        !isSelectOrderPrerequisitesDrawerOpen &&
        openSelectOrderPrerequisitesDrawerIfNotOpen
      ) {
        this.setState({ isSelectOrderPrerequisitesDrawerOpen: true });
      }
    }

    // If any vouchers are set (and the bar allows it), apply them for the order by default
    const prevVouchers = prevProps.vouchers;
    if (
      bar.isAllowingVoucherPayments() &&
      vouchers &&
      !utilsHelper.areArraysEqualShallow(vouchers, prevVouchers)
    ) {
      this.handleOrderVoucherIdsChange(vouchers.map((voucher) => voucher.id));
    }

    // If vouchers && menuItems are ready and orderLayoutPath is chosen (only if using zones),
    // check if product vouchers are present
    const prevIsSelectOrderPrerequisitesDrawerOpen =
      prevState.isSelectOrderPrerequisitesDrawerOpen;
    const prevOrderServiceOption = prevState.orderServiceOption;

    if (bar.isAllowingVoucherPayments()) {
      const prevMenuItems = prevProps.menuItems;
      const prevOrderZone = prevState.orderZone;
      const prevOrderLayoutPath = prevState.orderLayoutPath;

      const wasReady =
        !prevIsSelectOrderPrerequisitesDrawerOpen &&
        prevVouchers &&
        prevVouchers.length > 0 &&
        prevMenuItems &&
        prevOrderServiceOption &&
        (!bar.isUsingZones() ||
          (prevOrderZone &&
            prevOrderZone.layout &&
            prevOrderZone.layout.isAllowedToOrderForPath(
              prevOrderLayoutPath ? prevOrderLayoutPath : new LayoutPath()
            )));
      if (!wasReady) {
        const isReady =
          !isSelectOrderPrerequisitesDrawerOpen &&
          vouchers &&
          vouchers.length > 0 &&
          menuItems &&
          orderServiceOption &&
          (!bar.isUsingZones() ||
            (orderZone &&
              orderZone.layout &&
              orderZone.layout.isAllowedToOrderForPath(
                orderLayoutPath ? orderLayoutPath : new LayoutPath()
              )));

        if (isReady) {
          this.checkVouchersThatMustBeRedeemedInOneGo();
        }
      }
    }

    if (isSelectOrderPrerequisitesDrawerOpen) {
      if (isSelectOrderItemsVisible) {
        this.setState({ isSelectOrderItemsVisible: false });
      }
    } else {
      if (!isSelectOrderItemsVisible) {
        if (this.selectOrderItemsVisibleTimeout) {
          clearTimeout(this.selectOrderItemsVisibleTimeout);
        }

        this.selectOrderItemsVisibleTimeout = setTimeout(() => {
          this.setState({ isSelectOrderItemsVisible: true });
        }, 250);
      }
    }
  }

  checkVouchersThatMustBeRedeemedInOneGo = () => {
    const { t, vouchers, menuItems, onVoucherRemove } = this.props;

    const vouchersThatMustBeRedeemedInOneGo = vouchers.filter(
      (voucher) => voucher.mustBeRedeemedInOneGo
    );

    if (vouchersThatMustBeRedeemedInOneGo.length === 1) {
      const voucher = vouchersThatMustBeRedeemedInOneGo[0];

      if (voucher.remainingValue.length === 0) {
        return toast.error("error.this-voucher-has-no-remaining-value");
      }

      const orderItems = {};
      let foundAllOrderItems = true;

      for (let i = 0; i < voucher.remainingValue.length; i++) {
        const entry = voucher.remainingValue[i];
        const foundMenuItems = menuItems.filter(
          (menuItem) => menuItem.productId === entry.getProductId()
        );
        const menuItem =
          foundMenuItems.length === 1 ? foundMenuItems[0] : undefined;

        if (menuItem && menuItem.isAvailable) {
          orderItems[menuItem.id] = {
            menuItem,
            amount: entry.amount
          };
        } else {
          foundAllOrderItems = false;
          break;
        }
      }

      if (foundAllOrderItems) {
        this.handleOrderItemsChange(orderItems);
        this.handleVerifyOrder();
      } else {
        onVoucherRemove(voucher);
        toast.error(
          t("error.not-all-menu-items-for-this-product-voucher-are-available")
        );
      }
    }
  };

  checkFees = () => {
    const { activeFees } = this.props;
    const {
      orderItemsTotal,
      orderTip,
      orderServiceOption,
      orderZone,
      orderLayoutPath,
      orderDistributedDeposits
    } = this.state;

    const orderFees = activeFees
      ? activeFees.filter((fee) => {
        return (
          fee.calculateFee(
            orderItemsTotal,
            orderServiceOption?.name,
            orderZone ? orderZone.code : undefined,
            orderZone ? orderLayoutPath : undefined
          ) > 0
        );
      })
      : [];

    const orderTotal = this.calculateOrderTotal(
      orderItemsTotal,
      orderTip,
      orderFees,
      orderDistributedDeposits
    );

    this.setState({ orderFees, orderTotal });
  };

  checkDeposits = () => {
    const { orderDistributedDeposits } = this.state;

    const orderDepositsAmount = depositHelper.calculateTotalDepositAmount(orderDistributedDeposits);
    const orderDepositsTotal = depositHelper.calculateTotalDepositValue(orderDistributedDeposits);

    this.setState({ orderDepositsAmount, orderDepositsTotal });
  }

  getAvailableServiceOptions = () => {
    const { bar, bases } = this.props;
    const { orderZone, orderLayoutPath } = this.state;

    const orderLayoutNode =
      orderZone && orderZone.layout
        ? orderZone.layout.find(
          orderLayoutPath ? orderLayoutPath : new LayoutPath()
        )
        : null;

    return serviceOptionHelper.getAvailableServiceOptions(
      bar,
      bases,
      orderZone
        ? orderLayoutNode
          ? orderLayoutNode.serviceOptions
          : serviceOptionHelper.createServiceOptions()
        : serviceOptionHelper.createDefaultServiceOptions(bar.deliveryMethods)
    );
  };

  getDistributedDeposits = (orderItems) => {
    const { menuDeposits } = this.props;

    const distributedDeposits = {};

    if (menuDeposits) {
      const depositsAsMap = {};
      menuDeposits.forEach(
        (deposit) => (depositsAsMap[deposit.id] = deposit)
      );

      if (orderItems) {
        for (let id in orderItems) {
          const item = orderItems[id];
          const depositIds = item?.menuItem?.depositIds || [];

          depositIds.forEach((depositId) => {
            const deposit = depositsAsMap[depositId];

            if (deposit) {
              if (!distributedDeposits[depositId]) {
                distributedDeposits[depositId] = {
                  deposit,
                  amount: 0
                };
              }

              distributedDeposits[depositId].amount += item.amount;
            }
          });
        }
      }
    }

    return distributedDeposits;
  };

  handleSetOrderName = (orderName) => {
    const { bar, allZones } = this.props;

    if (bar.isUsingZones() && allZones && allZones.length > 0) {
      const { zoneCode, layoutPath } =
        zoneHelper.parseZoneCodeAndLayoutPath(orderName);
      const zone = utilsHelper.findInArrayByPropVal(allZones, "code", zoneCode);

      if (zone) {
        this.handleOrderZoneChange(
          zone,
          layoutPath && zone.layout.find(layoutPath) ? layoutPath : null
        );
      } else {
        //this.updateOrderZone(allZones[0], 1);
      }
    } else {
      this.updateOrderName(orderName);
    }
  };

  handleClearOrderItems = () => {
    this.setState({
      orderItems: {},
      orderTip: 0,
      orderItemsAmount: 0,
      orderItemsTotal: 0,
      orderTotal: 0
    });
  };

  handleVerifyOrder = () => {
    this.setState({ isOrderDrawerOpen: true });
  };

  handleOrderDrawerClose = (isOrderComplete) => {
    const { vouchers, onVoucherRemove } = this.props;
    const { orderItems } = this.state;

    if (!isOrderComplete && this.hasVouchersThatMustBeRedeemedInOneGo()) {
      for (let id in orderItems) {
        this.handleOrderItemAmountChange(id, 0);
      }

      vouchers.forEach((voucher) => {
        if (voucher.mustBeRedeemedInOneGo) {
          onVoucherRemove(voucher);
        }
      });

      this.setState({ isOrderDrawerOpen: false, orderItems: {} });
    } else {
      // Filter out orderItems with amount set to 0 in verify step
      const updatedOrderItems = {};

      for (let id in orderItems) {
        const orderItem = orderItems[id];

        if (orderItem.amount > 0) {
          updatedOrderItems[id] = orderItem;
        }
      }

      this.setState(
        { isOrderDrawerOpen: false, orderItems: updatedOrderItems },
        () => {
          if (isOrderComplete) {
            this.addOrder();
          }
        }
      );
    }
  };

  handleSelectOrderPrerequisites = () => {
    this.setState({ isSelectOrderPrerequisitesDrawerOpen: true });
  };

  handleSelectOrderPrerequisitesDrawerClose = () => {
    this.setState({ isSelectOrderPrerequisitesDrawerOpen: false });
  };

  handleOrderSubmittingDialogClose = () => {
    this.setState({ isOrderSubmittingDialogOpen: false });
  };

  handleOrderItemsChange = (orderItems) => {
    this.updateOrderItems(orderItems);
  };

  handleOrderItemsChangeAndVerify = (orderItems) => {
    this.updateOrderItems(orderItems);

    this.setState({
      isSelectOrderPrerequisitesDrawerOpen: false,
      isOrderDrawerOpen: true
    });
  };

  handleOrderItemAmountChange = (id, amount) => {
    const orderItems = { ...this.state.orderItems };

    orderItems[id].amount = amount;

    this.updateOrderItems(orderItems);
  };

  handleOrderTipChange = (tip) => {
    this.updateOrderTip(isNaN(tip) || tip <= 0 ? 0 : tip);
  };

  handleOrderZoneChange = (zone, layoutPath) => {
    const { onOrderZoneChange } = this.props;

    this.updateOrderZone(zone, layoutPath);

    this.handleOrderServiceOptionOrLayoutNodeChange(null, zone, layoutPath);

    if (onOrderZoneChange) {
      onOrderZoneChange(zone);
    }
  };

  handleOrderServiceOptionOrLayoutNodeChange = (
    orderServiceOption,
    orderZone,
    orderLayoutPath
  ) => {
    const { onOrderServiceOptionOrLayoutNodeChange } = this.props;

    if (onOrderServiceOptionOrLayoutNodeChange) {
      const orderLayoutNode =
        orderZone && orderZone.layout
          ? orderZone.layout.find(
            orderLayoutPath ? orderLayoutPath : new LayoutPath()
          )
          : null;

      onOrderServiceOptionOrLayoutNodeChange(
        orderServiceOption,
        orderLayoutNode
      );
    }

    /*this.checkOrderPrerequisites(
      orderServiceOption,
      orderZone,
      orderLayoutPath
    );*/
  };

  handleOrderLayoutPathChange = (layoutPath) => {
    const { orderZone, orderLayoutPath, orderServiceOption } = this.state;

    this.updateOrderLayoutPath(layoutPath);

    this.handleOrderServiceOptionOrLayoutNodeChange(
      orderServiceOption,
      orderZone,
      layoutPath
    );

    // Close drawer automatically if orderLayoutPath is defined
    /*if (
      orderLayoutPath === null &&
      layoutPath !== null &&
      orderZone &&
      orderZone.layout &&
      orderZone.layout.isAllowedToOrderForPath(layoutPath)
    ) {
      this.handleSelectOrderPrerequisitesDrawerClose();
    }*/
  };

  /*checkOrderPrerequisites = (
    orderServiceOption,
    orderZone,
    orderLayoutPath
  ) => {
    const { bar } = this.props;

    if (
      orderServiceOption &&
      (!bar.isUsingZones() ||
        (orderLayoutPath !== null &&
          orderZone &&
          orderZone.layout &&
          orderZone.layout.isAllowedToOrderForPath(orderLayoutPath)))
    ) {
      this.handleSelectOrderPrerequisitesDrawerClose();
    }
  };*/

  handleOrderNameChange = (name) => {
    this.updateOrderName(name);
  };

  handleOrderNoteChange = (note) => {
    this.setState({ orderNote: note });
  };

  handleOrderFieldsChange = (fields) => {
    const { bar } = this.props;

    this.setState({ orderFields: fields });

    const customerFieldToSendOrderConfirmation =
      bar.getCustomerFieldToSendOrderConfirmation();
    if (
      customerFieldToSendOrderConfirmation &&
      fields &&
      fields[customerFieldToSendOrderConfirmation.id]
    ) {
      const field = fields[customerFieldToSendOrderConfirmation.id];
      if (field.isValid) {
        this.handleOrderDeliveryContactChange({ email: field.value });
      }
    }
  };

  handleOrderVoucherIdsChange = (voucherIds) => {
    this.setState({ orderVoucherIds: voucherIds });
  };

  handleOrderServiceOptionChange = (serviceOption) => {
    const { orderZone, orderLayoutPath, orderServiceOption } = this.state;

    const serviceOptionName = serviceOption ? serviceOption.name : null;

    // Check
    if (
      serviceOptionName &&
      !this.getAvailableServiceOptions().has(serviceOption.name)
    ) {
      console.warn("serviceOptionName invalid");
      return;
    }

    const orderServiceOptionName = orderServiceOption
      ? orderServiceOption.name
      : null;

    this.setState(
      {
        orderServiceOption: serviceOption
      },
      this.checkFees
    );

    if (serviceOptionName !== orderServiceOptionName) {
      this.handleOrderServiceOptionOrLayoutNodeChange(
        serviceOption,
        orderZone,
        orderLayoutPath
      );
    }
  };

  handleOrderDeliveryContactChange = (deliveryContact) => {
    this.setState({ orderDeliveryContact: deliveryContact }, this.checkFees);
  };

  handleOrderDeliveryContactAndFieldChange = (deliveryContact) => {
    const { bar } = this.props;

    const parsedEmail = utilsHelper.parseEmail(deliveryContact?.email);

    if (parsedEmail) {
      const customerFieldToSendOrderConfirmation =
        bar.getCustomerFieldToSendOrderConfirmation();

      if (customerFieldToSendOrderConfirmation) {
        const orderFields = this.state.orderFields || {};

        orderFields[customerFieldToSendOrderConfirmation.id] = {
          value: parsedEmail,
          isValid: true
        };

        this.handleOrderFieldsChange(orderFields);
      } else {
        this.handleOrderDeliveryContactChange({ email: parsedEmail });
      }
    } else {
      console.warn("deliveryContact is not valid", deliveryContact);
    }
  };

  updateOrderTip = (orderTip) => {
    const { orderItemsTotal, orderFees, orderDistributedDeposits } = this.state;

    const orderTotal = this.calculateOrderTotal(
      orderItemsTotal,
      orderTip,
      orderFees,
      orderDistributedDeposits
    );

    this.setState({ orderTip, orderTotal });
  };

  updateOrderItems = (orderItems) => {
    const { orderTip, orderFees } = this.state;

    let orderItemsAmount = 0,
      orderItemsTotal = 0;

    for (let id in orderItems) {
      const orderItem = orderItems[id];
      orderItemsAmount += orderItem.amount;
      orderItemsTotal += orderItem.menuItem.price * orderItem.amount;
    }

    const orderDistributedDeposits = this.getDistributedDeposits(orderItems);

    const orderTotal = this.calculateOrderTotal(
      orderItemsTotal,
      orderTip,
      orderFees,
      orderDistributedDeposits
    );

    this.setState({
      orderItems,
      orderItemsAmount,
      orderItemsTotal,
      orderTotal,
      orderDistributedDeposits
    }, this.checkDeposits);
  };

  updateOrderZone = (orderZone, orderLayoutPath) => {
    const parsedOrderLayoutPath =
      orderLayoutPath && orderLayoutPath.length > 0 ? orderLayoutPath : null;

    const orderName = this.compileOrderName(orderZone, parsedOrderLayoutPath);

    this.setState(
      {
        orderZone,
        orderLayoutPath: parsedOrderLayoutPath,
        orderName
      },
      this.checkFees
    );
  };

  updateOrderLayoutPath = (orderLayoutPath) => {
    const { orderZone } = this.state;

    const parsedOrderLayoutPath =
      orderLayoutPath && orderLayoutPath.length > 0 ? orderLayoutPath : null;

    const orderName = this.compileOrderName(orderZone, parsedOrderLayoutPath);

    this.setState(
      { orderLayoutPath: parsedOrderLayoutPath, orderName },
      this.checkFees
    );
  };

  updateOrderName = (orderName) => {
    this.setState({ orderName: orderName ? orderName : "" });
  };

  calculateOrderTotal = (orderItemsTotal, orderTip, orderFees, orderDistributedDeposits) => {
    const { orderServiceOption, orderZone, orderLayoutPath } = this.state;

    return utilsHelper.roundToTwoDecimals(
      orderItemsTotal +
      orderTip +
      feeHelper.calculateTotalFee(
        orderFees,
        orderItemsTotal,
        orderServiceOption?.name,
        orderZone ? orderZone.code : undefined,
        orderZone ? orderLayoutPath : undefined
      ) +
      depositHelper.calculateTotalDepositValue(
        orderDistributedDeposits
      )
    );
  };

  compileOrderName = (orderZone, orderLayoutPath) => {
    if (orderZone) {
      const pathLocations = orderZone.layout && orderLayoutPath ?
        orderZone.layout.getPathLocations(orderLayoutPath) :
        [];

      return `${orderZone.name}${pathLocations.length > 0 ? `, ${pathLocations.join(", ")}` : ""}`;
    }

    return "";
  };

  addOrder = async () => {
    const { t, locale, bar, auth, vouchers, voucherCodes, onOrderCreated } =
      this.props;
    const {
      orderItems,
      orderFields,
      orderVoucherIds,
      orderServiceOption,
      orderDeliveryContact
    } = this.state;

    try {
      const orderName = this.state.orderName
        ? this.state.orderName.trim()
        : undefined;
      const orderNote = this.state.orderNote
        ? this.state.orderNote.trim()
        : undefined;
      let orderZone, orderLayoutPath, orderTip;

      if (!bar || !bar.id) {
        throw new Error("error.no-valid-bar-found");
      }
      if (!auth || !auth.user || !auth.user.uid) {
        throw new Error("error.no-valid-user-found");
      }
      if (!orderName) {
        throw new Error("error.name-not-entered");
      }
      if (!orderItems || orderItems.length === 0) {
        throw new Error("error.no-items-selected");
      }
      if (bar.isUsingZones()) {
        if (this.state.orderZone) {
          orderZone = this.state.orderZone;
        } else {
          throw new Error("error.zone-not-entered");
        }
        orderLayoutPath = this.state.orderLayoutPath
          ? this.state.orderLayoutPath.elements
          : null;
      }
      if (this.state.orderTip) {
        orderTip = parseFloat(this.state.orderTip);

        if (isNaN(orderTip) || orderTip < 0) {
          throw new Error("error.tip-invalid");
        }
      }

      let orderFieldsAsArray;
      if (orderFields) {
        orderFieldsAsArray = [];
        for (const id in orderFields) {
          const orderField = orderFields[id];

          if (!orderField.isValid) {
            throw new Error("error.name-entered-invalid");
          }

          orderFieldsAsArray.push({
            id,
            value: orderField.value
          });
        }
      }

      // Service
      if (!orderServiceOption) {
        throw new Error("error.service-option-is-not-defined");
      }

      // Delivery
      const orderDelivery = {};
      switch (orderServiceOption.deliveryMethod) {
        case DeliveryMethod.PICKUP:
          if (!orderDeliveryContact) {
            throw new Error(
              "error.delivery-contact-is-not-defined-while-delivery-method-is-pickup"
            );
          }

          orderDelivery.contact = orderDeliveryContact;
          break;
      }

      // Make sure vouchers are sorted
      const orderVouchers = voucherHelper.sortVouchersInRedeemOrder(
        vouchers
          ? vouchers.filter(
            (voucher) => orderVoucherIds.indexOf(voucher.id) >= 0
          )
          : []
      );
      // Voucher code can be undefined if voucher is removed
      // TO FIX: Remove voucher id if voucher is removed?
      const orderVoucherCodes = orderVouchers
        .map((voucher) => voucherCodes[voucher.id])
        .filter((voucherCode) => voucherCode !== undefined);

      const orderItemsAsArray = [];
      for (const id in orderItems) {
        const orderItem = orderItems[id];

        orderItemsAsArray.push({
          id,
          menuId: orderItem.menuItem.menuId,
          amount: orderItem.amount
        });
      }

      this.setState({ isOrderSubmittingDialogOpen: true });

      // Create order
      const orderId = await orderService.addOrder(
        auth.user.uid,
        bar.id,
        orderName,
        orderItemsAsArray,
        { option: { name: orderServiceOption.name } },
        orderDelivery,
        orderZone ? orderZone.code : undefined,
        orderLayoutPath,
        orderFieldsAsArray,
        orderNote ? orderNote : undefined,
        orderTip,
        locale
      );

      // Pay order with vouchers (if enabled)
      if (
        bar.isAllowingVoucherPayments() &&
        orderVoucherCodes &&
        orderVoucherCodes.length > 0
      ) {
        const order = await orderService.onOrderReady(bar.id, orderId);
        await paymentService.redeemVouchers(
          auth.user.uid,
          order,
          orderVoucherCodes
        );
      }

      this.setState({ isOrderSubmittingDialogOpen: false });

      onOrderCreated(
        orderId,
        orderName,
        orderServiceOption,
        orderDeliveryContact,
        orderFields
      );
    } catch (error) {
      console.warn(error);

      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`
      );

      this.setState({ isOrderSubmittingDialogOpen: false });
    }
  };

  hasVouchersThatMustBeRedeemedInOneGo = () => {
    const { vouchers } = this.props;

    if (vouchers) {
      for (let i = 0; i < vouchers.length; i++) {
        if (vouchers[i].mustBeRedeemedInOneGo) {
          return true;
        }
      }
    }

    return false;
  };

  render() {
    const {
      classes,
      t,
      queryParams,
      headerProps,
      barLocator,
      bar,
      bases,
      activeFees,
      vouchers,
      allZones,
      menuElements,
      menuItems,
      menuCategories,
      menuDeposits
    } = this.props;
    const {
      isOrderDrawerOpen,
      isSelectOrderPrerequisitesDrawerOpen,
      isOrderSubmittingDialogOpen,
      isSelectOrderItemsVisible,
      orderId,
      orderCustomer,
      orderItems,
      orderTip,
      orderFees,
      orderItemsAmount,
      orderItemsTotal,
      orderDistributedDeposits,
      orderDepositsAmount,
      orderDepositsTotal,
      orderTotal,
      orderZone,
      orderLayoutPath,
      orderName,
      orderNote,
      orderFields,
      orderVoucherIds,
      orderServiceOption,
      orderDeliveryContact
    } = this.state;

    // TO FIX (fixed by wrapping text in Button for handleSelectOrderZoneCodeAndLayoutPath in a <span /> element)
    // https://github.com/facebook/react/issues/11538#issuecomment-390386520
    // https://github.com/facebook/react/issues/13278

    const preventContentScroll = isSelectOrderPrerequisitesDrawerOpen;

    const isReadyToApplyCaches =
      !bar.isUsingBases() || (bases && (!bar.isUsingZones() || allZones));
    const isReadyToApplyItemsCache =
      menuItems &&
      orderServiceOption &&
      (!bar.isUsingZones() ||
        (orderZone &&
          orderZone.layout.isAllowedToOrderForPath(
            orderLayoutPath ? orderLayoutPath : new LayoutPath()
          )));

    return (
      <div
        className={classNames(classes.content, {
          [classes.preventContentScroll]: preventContentScroll
        })}
      >
        {isReadyToApplyCaches && (
          <>
            <ApplyDeliveryCache
              bar={bar}
              onOrderServiceOptionChange={this.handleOrderServiceOptionChange}
              onOrderDeliveryContactChange={
                this.handleOrderDeliveryContactAndFieldChange
              }
              serviceOptionName={queryParams?.serviceOptionName || null}
              deliveryContact={
                queryParams?.emailAddress
                  ? { email: queryParams?.emailAddress }
                  : null
              }
            />
            <ApplyCustomerFieldsCache
              bar={bar}
              onOrderFieldsChange={this.handleOrderFieldsChange}
            />
            <ApplyItemsCache
              isReady={isReadyToApplyItemsCache}
              items={JSON.parse(queryParams?.items || "[]")}
              menuItems={menuItems}
              onOrderItemsChange={this.handleOrderItemsChangeAndVerify}
            />
          </>
        )}
        <OrderDrawer
          isOpen={isOrderDrawerOpen}
          onClose={this.handleOrderDrawerClose}
          allowOrderChange={!this.hasVouchersThatMustBeRedeemedInOneGo()}
          bar={bar}
          allZones={allZones}
          vouchers={vouchers}
          orderCustomer={orderCustomer}
          orderItems={orderItems}
          orderDistributedDeposits={orderDistributedDeposits}
          orderTip={orderTip}
          orderFees={orderFees}
          orderItemsAmount={orderItemsAmount}
          orderItemsTotal={orderItemsTotal}
          orderDepositsAmount={orderDepositsAmount}
          orderDepositsTotal={orderDepositsTotal}
          orderTotal={orderTotal}
          orderZone={orderZone}
          orderLayoutPath={orderLayoutPath}
          orderName={orderName}
          orderNote={orderNote}
          orderFields={orderFields}
          orderVoucherIds={orderVoucherIds}
          orderServiceOption={orderServiceOption}
          orderDeliveryContact={orderDeliveryContact}
          onOrderItemAmountChange={this.handleOrderItemAmountChange}
          onOrderTipChange={this.handleOrderTipChange}
          onOrderZoneChange={this.handleOrderZoneChange}
          onOrderLayoutPathChange={this.handleOrderLayoutPathChange}
          onOrderNameChange={this.handleOrderNameChange}
          onOrderNoteChange={this.handleOrderNoteChange}
          onOrderFieldsChange={this.handleOrderFieldsChange}
          onOrderVoucherIdsChange={this.handleOrderVoucherIdsChange}
          onOrderDeliveryContactChange={this.handleOrderDeliveryContactChange}
        />
        <SelectOrderPrerequisitesDrawer
          isOpen={isSelectOrderPrerequisitesDrawerOpen}
          onClose={this.handleSelectOrderPrerequisitesDrawerClose}
          bar={bar}
          bases={bases}
          activeFees={activeFees}
          allZones={allZones}
          orderZone={orderZone}
          orderLayoutPath={orderLayoutPath}
          orderServiceOption={orderServiceOption}
          onOrderZoneChange={this.handleOrderZoneChange}
          onOrderLayoutPathChange={this.handleOrderLayoutPathChange}
          onOrderNameChange={this.handleOrderNameChange}
          onOrderServiceOptionChange={this.handleOrderServiceOptionChange}
        />
        <OrderSubmittingDialog
          isOpen={isOrderSubmittingDialogOpen}
          onClose={this.handleOrderSubmittingDialogClose}
        />
        {menuElements && menuElements.length > 0 ?
          isSelectOrderItemsVisible ? (
            <div>
              <SelectOrderItems
                headerProps={headerProps}
                bar={bar}
                menuElements={
                  menuElements
                    ? menuElements.filter((element) => !element.isHidden)
                    : null
                }
                menuCategories={
                  menuCategories
                    ? menuCategories.filter((category) => !category.isHidden)
                    : null
                }
                orderItems={orderItems}
                onOrderItemsChange={this.handleOrderItemsChange}
              />
              <div
                className={classNames(
                  classes.actionButtons,
                  classes.centerButtons
                )}
              >
                <div className={classes.centerButton}>
                  <VerifyOrderButton
                    bar={bar}
                    orderItemsAmount={orderItemsAmount}
                    orderItemsTotal={orderItemsTotal}
                    orderDepositsAmount={orderDepositsAmount}
                    orderDepositsTotal={orderDepositsTotal}
                    onClick={this.handleVerifyOrder}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.loadingContainer}>
              <Typography variant="h6">
                {t("order.loading-order-items")}
              </Typography>
              <div className={classes.spacing} />
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.noMenuElements}>
              {t(
                isSelectOrderPrerequisitesDrawerOpen
                  ? bar.isUsingZones()
                    ? "order.select-order-zone-and-service-option-to-order"
                    : "order.select-order-service-option-to-order"
                  : "order.ordering-currently-not-available"
              )}
            </div>
          )}
        <div
          className={classNames(classes.actionButtons, classes.bottomButtons)}
        >
          <div className={classes.buttonsContainer}>
            <div
              className={classNames(classes.barTitle, {
                [classes.visibleBarTitle]: !headerProps.isLarge
              })}
            >
              <BarTitle color="black" bar={bar} align="left" />
            </div>
            <ShowOrderHistoryButton
              barLocator={barLocator}
              bar={bar}
              hideLabel={!headerProps.isLarge}
            />
            <SelectOrderPrerequisitesButton
              bar={bar}
              orderZone={orderZone}
              orderLayoutPath={orderLayoutPath}
              orderServiceOption={orderServiceOption}
              hideLabel={!headerProps.isLarge}
              onClick={this.handleSelectOrderPrerequisites}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  withAuthorization()(withTranslation("common")(CreateOrder))
);
