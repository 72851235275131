import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { List, ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { serviceOptionHelper } from "tap-io/helpers";
import SelectServiceOption from "tap-io/client/components/service/SelectServiceOption";

import ServiceOptionFees from "../service/ServiceOptionFees";

const styles = (theme) => ({});

class SelectOrderServiceOption extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getAvailableServiceOptions = () => {
    const { bar, bases } = this.props;

    return serviceOptionHelper.getAvailableServiceOptions(bar, bases);
  };

  render() {
    const {
      classes,
      t,
      bar,
      activeFees,
      orderServiceOption,
      onOrderServiceOptionChange,
      hideTitle
    } = this.props;

    const availableServiceOptions = this.getAvailableServiceOptions();

    return (
      <List>
        {!hideTitle && (
          <ListItem>
            <div>
              <Typography variant="subtitle1" className={classes.title}>
                {t("order.select-service-option")}
              </Typography>
            </div>
          </ListItem>
        )}
        <ListItem>
          {availableServiceOptions.length > 0 ? (
            <SelectServiceOption
              allServiceOptions={availableServiceOptions}
              serviceOption={orderServiceOption}
              onChange={onOrderServiceOptionChange}
              getDescription={(serviceOption) => (
                <ServiceOptionFees
                  bar={bar}
                  fees={activeFees}
                  serviceOption={serviceOption}
                />
              )}
            />
          ) : (
            <Typography>{t("order.no-service-options-found")}</Typography>
          )}
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SelectOrderServiceOption)
);
