import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button, Dialog, MobileStepper, Slide } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { alpha } from "@mui/material/styles";

import withStyles from "@mui/styles/withStyles";

import OrderStep from "./OrderStep";
import OrderItemsStep from "./OrderItemsStep";
import OrderCustomerStep from "./OrderCustomerStep";
import OrderConfirmationStep from "./OrderConfirmationStep";
import BarTitle from "../bar/BarTitle";

const STEP_ORDER_ITEMS = {
  title: "order.step-order-items",
  component: OrderItemsStep
};

const STEP_ORDER_CUSTOMER = {
  title: "order.step-order-customer",
  component: OrderCustomerStep
};

const STEP_ORDER_CONFIRMATION = {
  title: "order.step-order-confirmation",
  component: OrderConfirmationStep
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const STEPPER_HEIGHT = 54;

const styles = (theme) => ({
  drawer: {
    //minHeight: "100vh",
    backgroundColor: theme.palette.tertiary.main
  },
  step: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 600,
    minHeight: `calc(100% - ${STEPPER_HEIGHT}px)`,
    margin: "0 auto"
  },
  stepperContainer: {
    position: "sticky",
    bottom: 0,
    backgroundColor: alpha(theme.palette.tertiary.main, 0.85),
    "-webkit-background": alpha(theme.palette.tertiary.main, 0.85),
    "backdrop-filter": "saturate(180%) blur(20px)",
    "-webkit-backdrop-filter": "saturate(180%) blur(20px)"
  },
  stepper: {
    width: "100%",
    height: STEPPER_HEIGHT,
    maxWidth: 600,
    margin: "0 auto",
    background: "transparent"
  },
  stepperButton: {
    color: theme.palette.tertiary.contrastText
  }
});

class OrderDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      orderSteps: [STEP_ORDER_CONFIRMATION],
      activeStepIndex: 0,
      skipStepIndexes: []
    };

    this.stepNextHandlers = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen, onClose } = this.props;
    const { activeStepIndex, orderSteps } = this.state;

    const prevIsOpen = prevProps.isOpen;

    if (isOpen) {
      if (!prevIsOpen) {
        this.setState({ activeStepIndex: 0 });
      } else if (activeStepIndex >= orderSteps.length) {
        this.handleClose();
      }
    }
  }

  setActiveStepIndex = (newActiveStepIndex) => {
    const { activeStepIndex, skipStepIndexes } = this.state;

    if (skipStepIndexes.indexOf(newActiveStepIndex) >= 0) {
      return this.setActiveStepIndex(
        newActiveStepIndex > activeStepIndex
          ? newActiveStepIndex + 1
          : newActiveStepIndex - 1
      );
    } else {
      this.setState({ activeStepIndex: newActiveStepIndex });
    }
  };

  handleBack = () => {
    const { activeStepIndex } = this.state;

    this.setActiveStepIndex(activeStepIndex - 1);
  };

  handleNext = async () => {
    const { t } = this.props;
    const { activeStepIndex } = this.state;

    if (this.stepNextHandlers[activeStepIndex]) {
      this.setState({ isDisabled: true });

      try {
        await this.stepNextHandlers[activeStepIndex]();

        this.setState({ isDisabled: false });
      } catch (error) {
        console.warn(error);

        this.setState({ isDisabled: false });
        toast.error(
          `${t("label.error")}: ${t(
            error ? error.message : "error.unknown-error"
          )}`
        );

        // Don't go to the next step
        return;
      }
    }

    this.setActiveStepIndex(activeStepIndex + 1);
  };

  handleClose = () => {
    const { onClose } = this.props;
    const { activeStepIndex, orderSteps } = this.state;

    const isOrderComplete = activeStepIndex >= orderSteps.length;
    onClose(isOrderComplete);
  };

  render() {
    const {
      classes,
      t,
      isOpen,
      allowOrderChange,
      bar,
      allZones,
      vouchers,
      orderCustomer,
      orderItems,
      orderDistributedDeposits,
      orderTip,
      orderFees,
      orderItemsAmount,
      orderItemsTotal,
      orderDepositsAmount,
      orderDepositsTotal,
      orderTotal,
      orderZone,
      orderLayoutPath,
      orderName,
      orderNote,
      orderFields,
      orderVoucherIds,
      orderServiceOption,
      orderDeliveryContact,
      onOrderItemAmountChange,
      onOrderTipChange,
      onOrderLayoutPathChange,
      onOrderNameChange,
      onOrderNoteChange,
      onOrderFieldsChange,
      onOrderVoucherIdsChange,
      onOrderDeliveryContactChange
    } = this.props;
    const { isDisabled, orderSteps, activeStepIndex } = this.state;

    const activeStep = orderSteps[activeStepIndex];

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        classes={{ paper: classes.drawer }}
        fullScreen
        TransitionComponent={Transition}
        scroll="body"
      >
        <div className={classes.step}>
          {activeStep && (
            <OrderStep
              title={t(activeStep.title)}
              subtitle={<BarTitle bar={bar} align="left" />}
            >
              <activeStep.component
                setOnNext={(onNext) =>
                  (this.stepNextHandlers[activeStepIndex] = onNext)
                }
                onNext={this.handleNext}
                allowOrderChange={allowOrderChange}
                bar={bar}
                orderFees={orderFees}
                allZones={allZones}
                vouchers={vouchers}
                orderCustomer={orderCustomer}
                orderItems={orderItems}
                orderDistributedDeposits={orderDistributedDeposits}
                orderTip={orderTip}
                orderItemsAmount={orderItemsAmount}
                orderItemsTotal={orderItemsTotal}
                orderDepositsAmount={orderDepositsAmount}
                orderDepositsTotal={orderDepositsTotal}
                orderTotal={orderTotal}
                orderZone={orderZone}
                orderLayoutPath={orderLayoutPath}
                orderName={orderName}
                orderNote={orderNote}
                orderFields={orderFields}
                orderVoucherIds={orderVoucherIds}
                orderServiceOption={orderServiceOption}
                orderDeliveryContact={orderDeliveryContact}
                onOrderItemAmountChange={onOrderItemAmountChange}
                onOrderTipChange={onOrderTipChange}
                onOrderLayoutPathChange={onOrderLayoutPathChange}
                onOrderNameChange={onOrderNameChange}
                onOrderNoteChange={onOrderNoteChange}
                onOrderFieldsChange={onOrderFieldsChange}
                onOrderVoucherIdsChange={onOrderVoucherIdsChange}
                onOrderDeliveryContactChange={onOrderDeliveryContactChange}
              />
            </OrderStep>
          )}
        </div>
        <div className={classes.stepperContainer}>
          <MobileStepper
            steps={orderSteps.length > 1 ? orderSteps.length : 0}
            position="static"
            variant="dots"
            className={classes.stepper}
            activeStep={activeStepIndex}
            nextButton={
              <Button
                className={classes.stepperButton}
                disabled={isDisabled}
                onClick={this.handleNext}
              >
                {t(
                  activeStepIndex < orderSteps.length - 1
                    ? "label.next"
                    : "order.confirm-and-pay"
                )}
                <KeyboardArrowRightIcon />
              </Button>
            }
            backButton={
              activeStepIndex > 0 ? (
                <Button
                  className={classes.stepperButton}
                  disabled={isDisabled}
                  onClick={this.handleBack}
                >
                  <KeyboardArrowLeftIcon />
                  {t("label.back")}
                </Button>
              ) : (
                <Button
                  className={classes.stepperButton}
                  disabled={isDisabled}
                  onClick={this.handleClose}
                >
                  <KeyboardArrowLeftIcon />
                  {t("label.back")}
                </Button>
              )
            }
          />
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(OrderDrawer)
);

/*
action={
  <ShareOrderButton
    bar={bar}
    orderName={orderName}
    orderServiceOption={orderServiceOption}
    orderItems={orderItems}
  />
}
*/
