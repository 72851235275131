import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import WarningIcon from "@mui/icons-material/Warning";
import withStyles from "@mui/styles/withStyles";

import PaymentMessage from "tap-io/client/components/payment/PaymentMessage";

const styles = (theme) => ({});

class OnlyPaidOrdersAreProcessedMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <PaymentMessage
        color="#ff9800"
        icon={<WarningIcon />}
        text={t("order.only-paid-orders-are-processed")}
        description={t("payment.contact-waiter-if-you-cannot-pay")}
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OnlyPaidOrdersAreProcessedMessage)
);
