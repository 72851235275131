import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";

const styles = (theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    fontSize: 12
  },
  code: {
    fontFamily: "'Courier New', Courier, monospace",
    textTransform: "uppercase"
  },
  subOrderCode: {
    flexGrow: 1,
    textAlign: "right"
  }
});

class OrderConfirmationCode extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, order, base } = this.props;

    const confirmationCode = order.confirmationCode
      ? order.confirmationCode
      : `(${t("label.unknown")})`;

    let subOrderCode = null;
    if (base && base.id) {
      const subOrder = order.subOrders.getForBase(base.id);
      if (subOrder && subOrder.code) {
        subOrderCode = subOrder.code;
      }
    }

    return (
      <Typography className={classes.content}>
        <span>
          {t("order.confirmation-code")}{" "}
          <strong className={classes.code}>{confirmationCode}</strong>
        </span>
        {subOrderCode && (
          <span className={classes.subOrderCode}>
            {t("order.suborder-code")}{" "}
            <strong className={classes.code}>{subOrderCode}</strong>
          </span>
        )}
      </Typography>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderConfirmationCode));
