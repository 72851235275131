import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";
import VoucherButton from "../voucher/VoucherButton";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 0",
    backgroundColor: theme.palette.background.default
  },
  spacing: {
    height: 10
  },
  vouchers: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%"
  },
  voucher: {
    margin: 10
  }
});

class OrderVouchersAppliedMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleVoucherRemove = (voucher) => (event) => {
    const { onVoucherRemove } = this.props;

    onVoucherRemove(voucher);
  };

  render() {
    const { classes, t, bar, vouchers } = this.props;

    return (
      <div className={classes.content}>
        <Typography>
          {t(
            vouchers.length === 1
              ? "payment.you-are-using-following-voucher"
              : "payment.you-are-using-following-vouchers"
          )}
        </Typography>
        <div className={classes.spacing} />
        <div className={classes.vouchers}>
          {vouchers.map((voucher) => (
            <div key={voucher.id} className={classes.voucher}>
              <VoucherButton
                bar={bar}
                voucher={voucher}
                onRemove={this.handleVoucherRemove(voucher)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderVouchersAppliedMessage));
