import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Slide,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import LayoutPath from "tap-io/models/zone/LayoutPath";

import SelectOrderZoneAndLayoutPath from "./SelectOrderZoneAndLayoutPath";
import SelectOrderZoneServiceOption from "./SelectOrderZoneServiceOption";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  drawer: {
    //maxHeight: "90%"
    /*height: "100%",
    overflow: "auto"*/
  },
  content: {
    width: "100%",
    maxWidth: 600,
    margin: "0 auto",
    padding: 0
  },
  actions: {
    justifyContent: "center",
    padding: `${theme.spacing()} ${theme.spacing(2)}`
  },
  confirmButton: {
    maxWidth: 600
  }
});

class SelectOrderZoneDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  isOrderLayoutPathValid = () => {
    const { orderZone, orderLayoutPath } = this.props;

    return (
      orderZone &&
      orderZone.layout &&
      orderZone.layout.isAllowedToOrderForPath(
        orderLayoutPath ? orderLayoutPath : new LayoutPath()
      )
    );
  };

  isOrderServiceOptionValid = () => {
    const { orderServiceOption } = this.props;

    return !!orderServiceOption;
  };

  render() {
    const {
      classes,
      t,
      isOpen,
      onClose,
      bar,
      bases,
      activeFees,
      allZones,
      orderZone,
      orderLayoutPath,
      orderServiceOption,
      onOrderZoneChange,
      onOrderLayoutPathChange,
      onOrderNameChange,
      onOrderServiceOptionChange
    } = this.props;

    const isValid =
      this.isOrderLayoutPathValid() && this.isOrderServiceOptionValid();

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.drawer }}
        fullScreen
        TransitionComponent={Transition}
        //scroll="body"
      >
        <DialogContent className={classes.content}>
          <List>
            <ListItem>
              <Typography variant="h6" className={classes.title}>
                {t("order.select-zone-and-service-option")}
              </Typography>
            </ListItem>
          </List>
          <SelectOrderZoneAndLayoutPath
            bar={bar}
            allZones={allZones}
            orderZone={orderZone}
            orderLayoutPath={orderLayoutPath}
            onOrderZoneChange={onOrderZoneChange}
            onOrderLayoutPathChange={onOrderLayoutPathChange}
            onOrderNameChange={onOrderNameChange}
          />
          {orderZone && (
            <SelectOrderZoneServiceOption
              bar={bar}
              bases={bases}
              activeFees={activeFees}
              orderZone={orderZone}
              orderLayoutPath={orderLayoutPath}
              orderServiceOption={orderServiceOption}
              onOrderServiceOptionChange={onOrderServiceOptionChange}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            className={classes.confirmButton}
            disabled={!isValid}
            onClick={onClose}
          >
            {t("label.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SelectOrderZoneDrawer)
);
