import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import LayoutPath from "tap-io/models/zone/LayoutPath";

const styles = (theme) => ({
  container: {
    width: "100%"
  },
  smallSpacing: {
    height: 10
  },
  emptyLocation: {
    fontStyle: "italic"
  }
});

class SelectLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationCode: ""
    };
  }

  componentDidMount() {
    const { layoutPath } = this.props;

    const layoutLocation = layoutPath && layoutPath.get(0);
    if (layoutLocation) {
      this.setState({ locationCode: layoutLocation });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { onSelect, layoutPath } = this.props;
    const { locationCode } = this.state;

    const layoutLocation = layoutPath && layoutPath.get(0);
    const prevLayoutLocation =
      prevProps.layoutPath && prevProps.layoutPath.get(0);

    const prevLocationCode = prevState.locationCode;

    if (locationCode !== prevLocationCode && locationCode !== layoutLocation) {
      onSelect(new LayoutPath(locationCode ? [locationCode] : []));
    }

    if (
      layoutLocation !== prevLayoutLocation &&
      layoutLocation !== locationCode
    ) {
      this.setState({ locationCode: layoutLocation ? layoutLocation : "" });
    }
  }

  handleLocationCodeChange = (event) => {
    this.setState({ locationCode: event.target.value });
    /*this.setState({ locationCode: null }, () => {
      this.setState({ locationCode: event.target.value });
    });*/
  };

  handleSelectChildLocation = (path) => {
    const { onSelect } = this.props;
    const { locationCode } = this.state;

    if (path) {
      path.prepend(new LayoutPath([locationCode]));
    }

    onSelect(path);
  };

  joinNames = (names) => {
    const { t } = this.props;

    switch (names.length) {
      case 0:
        return "";
        break;
      case 1:
        return names[0];
        break;
      default:
        const baseName = names[0];

        // Try to find a common name
        if (baseName.length > 0) {
          const otherNames = names.slice(1);

          let commonName = "";
          let lastSpaceIndex = 0;

          for (let i = 0; i < baseName.length; i++) {
            commonName += baseName[i];

            let isCommonNameValid = true;
            for (let j = 1; j < otherNames.length; j++) {
              const otherName = otherNames[j];
              if (otherName.indexOf(commonName) !== 0) {
                isCommonNameValid = false;
                break;
              }
            }

            if (!isCommonNameValid) {
              commonName = commonName.substr(0, lastSpaceIndex);
              break;
            }

            if (baseName[i] === " ") {
              lastSpaceIndex = i;
            }
          }

          // Only valid if the name contains at least one space
          if (lastSpaceIndex > 0) {
            const variableIndex = commonName.length;
            const lastName = otherNames[otherNames.length - 1];

            return `${commonName} ${baseName.substr(
              variableIndex
            )} - ${lastName.substr(variableIndex)}`;
          }
        }

        return `${
          names.length > 2
            ? `${names.slice(0, names.length - 2).join(", ")}, `
            : ""
        }${names.slice(names.length - 2).join(` ${t("label.or")} `)}`;
        break;
    }
  };

  render() {
    const { classes, t, variant, layoutNode, layoutPath } = this.props;
    const { locationCode } = this.state;

    const childLayoutNode = locationCode
      ? layoutNode.find(new LayoutPath([locationCode]))
      : null;
    const selectedLocationCode = childLayoutNode ? locationCode : "";

    return (
      <div className={classes.container}>
        {layoutNode.children.length > 0 && (
          <FormControl fullWidth variant={variant}>
            <InputLabel>
              {this.joinNames(layoutNode.getChildNames())}
            </InputLabel>
            <Select
              fullWidth
              value={selectedLocationCode}
              onChange={this.handleLocationCodeChange}
            >
              <MenuItem key="" value="" className={classes.emptyLocation}>
                {t("label.none")}
              </MenuItem>
              {layoutNode.getChildLayoutLocations().map((location) => (
                <MenuItem key={location.code} value={location.code}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <div className={classes.smallSpacing} />
        {childLayoutNode && (
          <WrappedSelectLocation
            variant={variant}
            layoutNode={childLayoutNode}
            layoutPath={layoutPath ? layoutPath.slice(1) : null}
            onSelect={this.handleSelectChildLocation}
          />
        )}
      </div>
    );
  }
}

const WrappedSelectLocation = withStyles(styles, { withTheme: true })(
  withTranslation("common")(SelectLocation)
);

export default WrappedSelectLocation;
