import React, { PureComponent } from "react";

import AuthContext from "../auth/authContext";

const withAuthContext = (Component) => {
  class WithAuthContext extends PureComponent {
    render() {
      return (
        <AuthContext.Consumer>
          {(auth) => <Component auth={auth} {...this.props} />}
        </AuthContext.Consumer>
      );
    }
  }

  return WithAuthContext;
};

export default withAuthContext;
