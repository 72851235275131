import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import FeeType from "tap-io/models/fee/FeeType";
import { utilsHelper } from "tap-io/helpers";

const styles = (theme) => ({
  feeContainer: {
    display: "flex",
    width: "100%"
  },
  feeLabel: {
    flexGrow: 1
  },
  feeValue: {
    textAlign: "right"
  }
});

class OrderFeesLines extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getFeeLabel = (fee) => {
    let label = fee.name;

    if (fee.type === FeeType.VARIABLE) {
      label += ` (${fee.value * 100}%)`;
    }

    return label;
  };

  render() {
    const {
      classes,
      t,
      bar,
      orderFees,
      orderItemsTotal,
      orderServiceOption,
      orderZone,
      orderLayoutPath
    } = this.props;

    return (
      <div>
        {orderFees.map((fee) => (
          <ListItem key={fee.id}>
            <Typography variant="body1" className={classes.feeContainer}>
              <span className={classes.feeLabel}>{this.getFeeLabel(fee)}</span>
              <span className={classes.feeValue}>
                {`${utilsHelper.formatToTwoDecimals(
                  fee.value
                    ? fee.value
                    : fee.calculateFee(
                        orderItemsTotal,
                        orderServiceOption?.name,
                        orderZone ? orderZone.code : undefined,
                        orderZone ? orderLayoutPath : undefined
                      )
                )} ${bar.getOrderCurrency()}`}
              </span>
            </Typography>
          </ListItem>
        ))}
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderFeesLines));
