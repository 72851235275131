import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import EuroIcon from "@mui/icons-material/Euro";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "5px 0 25px 0"
  },
  description: {
    marginTop: 10
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
});

class OrderPayButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, onClick } = this.props;

    return (
      <div className={classes.container}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          startIcon={<EuroIcon />}
          onClick={onClick}
        >
          {t("order.pay-order")}
        </Button>
        <Typography variant="caption" className={classes.description}>
          {t("payment.payment-problems")}
          <br />
          <a
            href="https://watdrinkje.be/faq-klant"
            className={classes.link}
            target="_blank"
          >
            {t("label.take-a-look-at-faq")}
          </a>
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderPayButton));
