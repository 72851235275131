import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ContactlessIcon from "@mui/icons-material/Contactless";
import withStyles from "@mui/styles/withStyles";

import AuthorizedAppLink from "../auth/AuthorizedAppLink";

const styles = (theme) => ({
  button: {
    margin: 20
  }
});

class PayOrderWithCcv extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handlePaymentLinkClick = () => {
    const { onProcessing } = this.props;

    if (onProcessing) {
      onProcessing();
    }
  };

  render() {
    const { classes, t, bar, id, order, autoRedirect } = this.props;

    return (
      <div className={classes.button}>
        <AuthorizedAppLink
          bar={bar}
          endpoint="public/payment"
          params={{
            provider: "ccv",
            kind: "order",
            orderId: order.id
          }}
          target="_self"
          variant="outlined"
          size="large"
          color="primary"
          text={t("payment.pay-online")}
          icon={<ContactlessIcon />}
          autoRedirect={autoRedirect}
          onClick={this.handlePaymentLinkClick}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(PayOrderWithCcv)
);
