import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import QRCode from "qrcode.react";

import { CircularProgress, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import withStyles from "@mui/styles/withStyles";

import OrderStatus from "tap-io/models/order/OrderStatus";

import DeliveryConfirmationDialog from "../delivery/DeliveryConfirmationDialog";

const styles = (theme) => ({
  spacing: {
    height: 15
  },
  statusText: {
    fontSize: 20
  }
});

class OrderStatusOnDemand extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOrderDeliveryConfirmationDialogOpen: false
    };
  }

  componentDidMount() {
    const { setShowDeliveryConfirmation } = this.props;
    if (setShowDeliveryConfirmation) {
      setShowDeliveryConfirmation(this.handleShowDeliveryConfirmation);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { order, base } = this.props;
    const { isOrderDeliveryConfirmationDialogOpen } = this.state;

    const orderStatus = order ? order.getStatus(base) : undefined;

    if (
      isOrderDeliveryConfirmationDialogOpen &&
      orderStatus === OrderStatus.COMPLETE
    ) {
      this.setState({ isOrderDeliveryConfirmationDialogOpen: false });
    }
  }

  handleShowDeliveryConfirmation = () => {
    this.setState({ isOrderDeliveryConfirmationDialogOpen: true });
  };

  handleDeliveryConfirmationDialogClose = () => {
    this.setState({ isOrderDeliveryConfirmationDialogOpen: false });
  };

  render() {
    const { classes, t, order, base, allZones, orderUrl } = this.props;
    const { isOrderDeliveryConfirmationDialogOpen } = this.state;

    if (order && order.isStatusError(base)) {
      return (
        <Typography className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />{" "}
          {t("order.order-confirmation-failed")}. {t("order.retry-new-order")}.
        </Typography>
      );
    }

    return (
      <div>
        <DeliveryConfirmationDialog
          isOpen={isOrderDeliveryConfirmationDialogOpen}
          onClose={this.handleDeliveryConfirmationDialogClose}
          order={order}
          base={base}
          allZones={allZones}
          orderUrl={orderUrl}
        />
        {order.isStatusQueued(base) ? (
          <div>
            {orderUrl && (
              <div>
                <Typography className={classes.statusText}>
                  {base ? (
                    <span>
                      {t(
                        "order.pick-up-your-order-at-x-and-show-following-qr-code-1"
                      )}{" "}
                      <strong>{base.name}</strong>{" "}
                      {t(
                        "order.pick-up-your-order-at-x-and-show-following-qr-code-2"
                      )}
                    </span>
                  ) : (
                    t("order.pick-up-your-order-and-show-following-qr-code")
                  )}
                </Typography>
                <div className={classes.spacing} />
                <QRCode level="M" size={128} value={orderUrl} />
                <div className={classes.spacing} />
                <Typography variant="caption">
                  {`(${t("order.press-qr-code-to-enlarge")})`}
                </Typography>
              </div>
            )}
          </div>
        ) : order.isStatusClaimed(base) ? (
          <div>
            {orderUrl && (
              <div>
                <Typography className={classes.statusText}>
                  {t("order.your-order-has-been-scanned")}
                  {base && (
                    <span>
                      {` ${t("order.by")} `}
                      <strong>{base.name}</strong>
                    </span>
                  )}
                </Typography>
                <div className={classes.spacing} />
                <CircularProgress />
              </div>
            )}
          </div>
        ) : order.isStatusComplete(base) ? (
          <div>
            <Typography className={classes.statusText}>
              {t("order.status-complete-long")}
              {base && (
                <span>
                  {` ${t("order.by")} `}
                  <strong>{base.name}</strong>
                </span>
              )}
            </Typography>
            <div className={classes.spacing} />
            <CheckIcon />
          </div>
        ) : (
          <div>
            <Typography className={classes.statusText}>
              {t("order.status-queued-long")}
            </Typography>
            <div className={classes.spacing} />
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderStatusOnDemand)
);
