import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import SportsBarIcon from "@mui/icons-material/SportsBar";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import theme from "tap-io/client/theme";
import { barService, baseService, orderService } from "tap-io/client/services";
import OrderCard from "tap-io/client/components/order/OrderCard";
import withPageQuery from "tap-io/client/components/common/withPageQuery";
import { utilsHelper } from "tap-io/helpers";

import * as routes from "../constants/routes";
import OrderPayment from "../components/order/OrderPayment";
import LoadingOrder from "../components/order/LoadingOrder";
import OrderStatusCard from "../components/order/OrderStatusCard";
import CancelOrder from "../components/order/CancelOrder";
import AssistanceNeeded from "../components/common/AssistanceNeeded";
import OrderFeesCard from "../components/order/OrderFeesCard";
import ShowOrderHistoryButton from "../components/order/ShowOrderHistoryButton";

const styles = (theme) => ({
  content: {
    maxWidth: 600,
    margin: "0 auto",
    padding: "0 20px"
  },
  spacing: {
    height: 20
  },
  line: {
    opacity: 0.2,
    height: 1,
    backgroundColor: theme.palette.primary.main
  },
  orderBaseStatus: {
    margin: "10px 0"
  },
  newOrderLink: {
    textDecoration: "none"
  },
  buttons: {
    display: "flex",
    flexDirection: "column"
  }
});

class ViewOrderPage extends PureComponent {
  constructor(props) {
    super(props);

    const { match } = props;

    /*
    let id = "";
    if (match && match.params && match.params.id) {
      // If query params are present, this is appended to the id
      const indexOfSearch = match.params.id.indexOf("?");

      id =
        indexOfSearch >= 0
          ? match.params.id.substr(0, indexOfSearch)
          : match.params.id;
    }
    */

    let barLocator = null,
      orderId = null;
    if (match && match.params) {
      // Make sure params are properly decoded
      if (match.params.barLocator) {
        barLocator = decodeURIComponent(match.params.barLocator);
      }
      if (match.params.orderId) {
        orderId = decodeURIComponent(match.params.orderId);
      }
    }

    this.state = {
      barLocator,
      orderId,
      order: null,
      bases: null,
      requestImmediatePayment: false,
      isWaiterPage: false
    };
  }

  componentDidMount() {
    const { bar } = this.props;
    const { orderId } = this.state;

    this.checkAction();
    this.checkIsWaiterPage();

    this.unsubscribeOrder = orderService.onById(bar, orderId, async (order) => {
      this.setState({ order });

      if (bar.isUsingBases() && order.menuIds) {
        const bases = await baseService.getBasesWithMenuIds(
          bar.id,
          order.menuIds
        );
        this.setState({ bases });
      } else {
        this.setState({ bases: null });
      }
    });

    utilsHelper.resetScrollPosition();
  }

  componentWillUnmount() {
    if (this.unsubscribeOrder) {
      this.unsubscribeOrder();
    }
  }

  checkAction = () => {
    const { queryParams, bar } = this.props;

    if (queryParams && queryParams.action) {
      const action = queryParams.action;

      switch (action) {
        case "pay":
          this.setState({ requestImmediatePayment: true });
          break;
      }
    }
  };

  checkIsWaiterPage = async () => {
    const { queryParams, bar } = this.props;

    if (queryParams && queryParams.waiterCode) {
      const waiterCode = queryParams.waiterCode;
      const isWaiterPage = await barService.isBarWaiterCodeValid(
        bar,
        waiterCode
      );

      this.setState({
        isWaiterPage,
        waiterCode: isWaiterPage ? waiterCode : null
      });
    }
  };

  getCreateOrderLink = () => {
    const { isWaiterPage, waiterCode, barLocator, orderId } = this.state;
    const pathname = routes.CREATE_ORDER.replace(
      ":barLocator",
      barLocator
    ).replace("/:orderName*", "");

    const search = `${isWaiterPage ? `&waiterCode=${waiterCode}` : ""}`;

    return `${pathname}?${search}`;
  };

  render() {
    const { classes, t, timeOffset, bar, allZones } = this.props;
    const { barLocator, order, bases, requestImmediatePayment, isWaiterPage, waiterCode } =
      this.state;

    return (
      <div className={classes.content}>
        {order && order.hasBeenCreated() ? (
          <div>
            <div className={classes.spacing} />
            <Typography variant="subtitle1">
              {t("order.thank-you-for-ordering-with-x", {
                productName: config.product.name
              })}
            </Typography>
            <div className={classes.spacing} />
            {!order.hasBeenQueued() && (
              <div>
                <OrderCard
                  bar={bar}
                  order={order}
                  showOrderContent={
                    !bar.isUsingBases() || order.hasBeenCancelled()
                  }
                />
                <div className={classes.spacing} />
              </div>
            )}
            <OrderPayment
              bar={bar}
              order={order}
              barLocator={barLocator}
              requestImmediatePayment={requestImmediatePayment}
              isWaiter={isWaiterPage}
              waiterCode={waiterCode}
            />
            <div className={classes.spacing} />
            {order.hasBeenQueued() && (
              <div>
                <div className={classes.spacing} />
                {bases ? (
                  bases.map((base) => (
                    <div key={base.id} className={classes.orderBaseStatus}>
                      <OrderStatusCard
                        timeOffset={timeOffset}
                        bar={bar}
                        order={order}
                        base={base}
                        allZones={allZones}
                      />
                      <div className={classes.spacing} />
                    </div>
                  ))
                ) : (
                  <div>
                    <OrderStatusCard
                      timeOffset={timeOffset}
                      bar={bar}
                      order={order}
                      allZones={allZones}
                    />
                    <div className={classes.spacing} />
                  </div>
                )}
                <OrderFeesCard bar={bar} order={order} />
              </div>
            )}
            {order.isStatusCreated() && !order.isPaymentProcessing() && (
              <div>
                <CancelOrder order={order} />
                <div className={classes.spacing} />
              </div>
            )}
            <div className={classes.spacing} />
            <div className={classes.buttons}>
              <Link
                to={this.getCreateOrderLink()}
                className={classes.newOrderLink}
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  startIcon={<SportsBarIcon />}
                >
                  {t("order.new-order")}
                </Button>
              </Link>
              <div className={classes.spacing} />
              <ShowOrderHistoryButton
                fullWidth={false}
                //variant="contained"
                size="small"
                color="primary"
                barLocator={barLocator}
                bar={bar}
              />
            </div>
          </div>
        ) : (
          <LoadingOrder />
        )}
        <div className={classes.spacing} />
        <AssistanceNeeded />
        <div className={classes.spacing} />
        <theme.socials />
      </div>
    );
  }
}

export default withPageQuery(
  withStyles(styles)(withTranslation("common")(ViewOrderPage))
);

/*
{order.isDeliveryMethodPickup ? (
  <OrderDeliveryMethodPickupMessage />
) : (
  <OrderDeliveryMethodServeMessage />
)}
*/
