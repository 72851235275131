import React, { PureComponent } from "react";

import { StepLabel } from "@mui/material";
import OrderStatusStepIcon from "./OrderStatusStepIcon";

class OrderStatusStepLabel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { children, color, CustomIcon, ...otherProps } = this.props;

    return (
      <StepLabel
        {...otherProps}
        StepIconComponent={(iconProps) => (
          <OrderStatusStepIcon
            color={color}
            {...iconProps}
            CustomIcon={CustomIcon}
          />
        )}
      >
        {children}
      </StepLabel>
    );
  }
}

export default OrderStatusStepLabel;
