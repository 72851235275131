import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import HourglassEmpty from "@mui/icons-material/HourglassEmpty";
import withStyles from "@mui/styles/withStyles";
import DeliveryMethod from "tap-io/models/delivery/DeliveryMethod";
import { serviceOptionHelper } from "tap-io/helpers";

const styles = (theme) => ({
  button: {
    color: "black"
  },
  icon: {
    marginRight: theme.spacing(1)
  }
});

class SelectOrderDeliveryButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, orderServiceOption, hideLabel, onClick } = this.props;

    return (
      <Button
        fullWidth={!hideLabel}
        className={classes.button}
        onClick={onClick}
      >
        {orderServiceOption?.deliveryMethod === DeliveryMethod.SERVE ? (
          <EventSeatIcon className={classes.icon} />
        ) : orderServiceOption?.deliveryMethod === DeliveryMethod.PICKUP ? (
          <EmojiPeopleIcon className={classes.icon} />
        ) : (
          <HourglassEmpty className={classes.icon} />
        )}
        {!hideLabel && (
          <span>
            {t(
              orderServiceOption
                ? serviceOptionHelper.getServiceOptionLabel(
                    orderServiceOption,
                    t
                  )
                : "order.select-service-option"
            )}
          </span>
        )}
      </Button>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SelectOrderDeliveryButton)
);
