import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import HourglassEmpty from "@mui/icons-material/HourglassEmpty";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  button: {
    color: "black"
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
});

class SelectOrderZoneButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, orderZone, orderLayoutPath, hideLabel, onClick } =
      this.props;

    return (
      <Button
        fullWidth={!hideLabel}
        className={classes.button}
        onClick={onClick}
      >
        {orderZone ? (
          <PersonPinIcon className={classes.icon} />
        ) : (
          <HourglassEmpty className={classes.icon} />
        )}
        {!hideLabel && (
          <span className={classes.label}>
            {orderZone
              ? `${orderZone.name} ${
                  orderLayoutPath && orderLayoutPath.length > 0
                    ? " • " +
                      orderZone.layout
                        .getPathLocations(orderLayoutPath)
                        .map((location) => location.name)
                        .join(" • ")
                    : ""
                }`
              : t("zone.select-zone")}
          </span>
        )}
      </Button>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SelectOrderZoneButton)
);
