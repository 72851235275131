import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "../../../helpers";

const styles = (theme) => ({
  contact: {
    width: "100%"
  },
  emailField: {}
});

class SetDeliveryContact extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.checkDeliveryContact();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkDeliveryContact();
  }

  checkDeliveryContact = () => {
    const { deliveryContact, onChange } = this.props;

    if (!deliveryContact) {
      onChange({ email: "" });
    }
  };

  handleContactEmailChange = (event) => {
    const { onChange } = this.props;
    const value = event.target.value;

    onChange({
      email: value
    });
  };

  render() {
    const { classes, t, isDisabled, deliveryContact, isContactRequired } =
      this.props;

    return (
      <div className={classes.contact}>
        {deliveryContact && (
          <TextField
            variant="standard"
            type="email"
            name="email"
            autoComplete="email"
            className={classes.emailField}
            value={deliveryContact.email}
            disabled={isDisabled}
            required={isContactRequired}
            onChange={this.handleContactEmailChange}
            fullWidth
            error={
              isContactRequired &&
              (!deliveryContact ||
                !utilsHelper.parseEmail(deliveryContact.email))
            }
            placeholder={t("label.email-address")}
            helperText={t("delivery.enter-to-receive-confirmation")}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SetDeliveryContact)
);
