import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";
import FeeType from "tap-io/models/fee/FeeType";

const styles = (theme) => ({
  fees: {
    margin: `${theme.spacing(0.5)} 0`
  }
});

class ServiceOptionFees extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, fees, zone, layoutPath, serviceOption } =
      this.props;

    return (
      <div className={classes.fees}>
        {fees &&
          fees
            .filter(
              (fee) =>
                fee.hasConditions &&
                fee.willApply(
                  serviceOption?.name,
                  zone ? zone.code : undefined,
                  layoutPath
                )
            )
            .map((fee) => (
              <div key={fee.id} className={classes.fee}>
                {`${
                  fee.type === FeeType.FLAT
                    ? `${fee.value > 0 ? "+ " : fee.value < 0 ? "- " : ""}${
                        fee.value
                      } ${bar.getOrderCurrency()}`
                    : ""
                }${
                  fee.type === FeeType.VARIABLE
                    ? `+ ${Math.ceil(fee.value * 100)}%`
                    : ""
                } (${fee.name})`}
              </div>
            ))}
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(ServiceOptionFees));
