import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, CircularProgress, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { authHelper, utilsHelper } from "tap-io/helpers";

import withAuthorization from "../auth/withAuthorization";

const styles = (theme) => ({
  payconiqLogo: {
    width: 26,
    height: 26,
    background: `url('/img/payconiq-by-bancontact.png')`,
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat"
  }
});

class PayOrderWithPayconiq extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      paymentUrl: null
    };
  }

  componentDidMount() {
    this.generatePaymentLink();
  }

  generatePaymentLink = async () => {
    const { auth, bar, order, onComplete, onError } = this.props;

    const link = `${config.functions.api}/public/payment`;

    this.generatePaymentLinkPromise = utilsHelper.makePromiseCancelable(
      authHelper.generateAuthorizedLink(auth.user, link, {
        provider: "payconiq",
        kind: "order",
        barId: bar.id,
        orderId: order.id
      })
    );

    try {
      const paymentUrl = await this.generatePaymentLinkPromise.promise;
      this.setState({ paymentUrl });
    } catch (error) {
      console.warn(error);

      onError(error);
    }
  };

  setPaymentInterval = () => {
    const { onComplete } = this.props;

    this.checkPaymentInterval = setInterval(() => {
      const { order } = this.props;

      if (order && order.isPaid()) {
        clearInterval(this.checkPaymentInterval);
        this.checkPaymentInterval = undefined;

        this.setState({ isLoading: false }, onComplete);
      }
    }, 3000);
  };

  handleOpenPaymentLinkInApp = () => {
    const { onProcessing } = this.props;
    const { paymentUrl } = this.state;

    this.setState({ isLoading: true, openingPaymentLinkInApp: true });

    window.open(paymentUrl, "_self");

    this.setPaymentInterval();

    if (onProcessing) {
      onProcessing();
    }
  };

  handleCancelPayment = () => {
    const { onComplete } = this.props;

    if (this.generatePaymentLinkPromise) {
      this.generatePaymentLinkPromise.cancel();
      this.generatePaymentLinkPromise = undefined;
    }
    if (this.checkPaymentInterval) {
      clearInterval(this.checkPaymentInterval);
      this.checkPaymentInterval = undefined;
    }

    try {
      if (window && window.stop) {
        window.stop();
      }
    } catch (error) {
      console.warn(error);
    }

    onComplete();
  };

  render() {
    const { classes, t } = this.props;
    const { isLoading, paymentUrl } = this.state;

    return (
      <div>
        <Button
          variant="outlined"
          size="large"
          color="primary"
          disabled={!paymentUrl || isLoading}
          onClick={this.handleOpenPaymentLinkInApp}
          startIcon={
            !paymentUrl || isLoading ? (
              <CircularProgress size={25} />
            ) : (
              <div className={classes.payconiqLogo} />
            )
          }
        >
          {t("label.open-the-payconiq-app")}
        </Button>
        {isLoading && (
          <IconButton onClick={this.handleCancelPayment} size="large">
            <CancelIcon />
          </IconButton>
        )}
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(PayOrderWithPayconiq)
  )
);

/*
handleOpenPaymentLinkInIframe() {
  const { onProcessing } = this.props;

  this.setState({ openingPaymentLinkInIframe: true });

  this.setPaymentInterval();

  if (onProcessing) {
    onProcessing();
  }
}

{allowPaymentWithQRCode && !openingPaymentLinkInApp && (
  <div
    className={classNames(
      classes.paymentFulfilmentInIframe,
      classes.paymentFulfilmentMethod,
      openingPaymentLinkInIframe
        ? classes.paymentFulfilmentMethodNoTopBottomMargin
        : undefined
    )}
  >
    <Typography variant="body1">
      {t("payment.the-payconiq-by-bancontact-app-is")}{" "}
      <strong>{t("label.not")}</strong>{" "}
      {`${t("payment.installed-on-this-device")} ${t(
        "payment.and-i-want"
      )} `}
      <strong>{t("payment.to-scan-qr-with-other-device")}</strong>{" "}
      {t("payment.to-pay")}.
    </Typography>
    {openingPaymentLinkInIframe ? (
      <div>
        <div className={classes.paymentLinkInIframeDescription}>
          <CircularProgress
            size={25}
            className={classes.paymentLinkInIframeSpinner}
          />
          <Typography>
            {t("payment.scan-qr-with-app-to-pay")}
          </Typography>
        </div>
        <iframe src={paymentUrl} className={classes.iframe} />
      </div>
    ) : (
      <Button
        className={classes.openPaymentLinkInIframe}
        classes={{
          label: classes.openPaymentLinkInIframeButtonContent
        }}
        variant="contained"
        onClick={this.handleOpenPaymentLinkInIframe}
      >
        <div className={classes.qrCode}></div>
        <span className={classes.openPaymentLinkInIframeButtonText}>
          {t("label.show-qr-code")}
        </span>
      </Button>
    )}
  </div>
)}
*/
