import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { List, ListItem, TextField, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import LayoutPath from "tap-io/models/zone/LayoutPath";
import ConsentAgreement from "tap-io/client/components/common/ConsentAgreement";
import { utilsHelper } from "tap-io/helpers";
import DeliveryMethod from "tap-io/models/delivery/DeliveryMethod";
import SetDeliveryContact from "tap-io/client/components/delivery/SetDeliveryContact";
import PreferencesConsentToggle from "tap-io/client/components/common/PreferencesConsentToggle";

import CustomerFieldsForm from "../customer/CustomerFieldsForm";

const styles = (theme) => ({});

class OrderCustomerStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      acceptConsentAgreement: false
    };
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(() => {
      this.handleVerifyOrderDelivery();
      this.handleVerifyOrderCustomer();
    });
  }

  handleVerifyOrderDelivery = () => {
    const { bar, orderServiceOption, orderDeliveryContact } = this.props;

    if (!orderServiceOption) {
      throw new Error("error.service-option-is-not-defined");
    }

    switch (orderServiceOption.deliveryMethod) {
      case DeliveryMethod.PICKUP:
        if (
          !orderDeliveryContact ||
          !utilsHelper.parseEmail(orderDeliveryContact.email)
        ) {
          throw new Error("error.delivery-contact-email-is-not-valid");
        }
        break;
    }
  };

  handleVerifyOrderCustomer = () => {
    const { bar, orderZone, orderLayoutPath, orderName, orderFields } =
      this.props;
    const { acceptConsentAgreement } = this.state;

    if (!orderName) {
      throw new Error("error.order-name-cannot-be-empty");
    }

    if (bar.isUsingZones()) {
      if (!orderZone) {
        throw new Error("error.order-zone-cannot-be-empty");
      }

      if (
        !orderZone.layout.isAllowedToOrderForPath(
          orderLayoutPath ? orderLayoutPath : new LayoutPath()
        )
      ) {
        throw new Error("error.not-allowed-to-order-for-path");
      }
    }

    for (let id in orderFields) {
      if (!orderFields[id].isValid) {
        throw new Error("error.not-all-fields-are-completed-correctly");
      }
    }

    if (!acceptConsentAgreement) {
      throw new Error("error.consent-to-agreements");
    }
  };

  handleOrderNameChange = (event) => {
    const { onOrderNameChange } = this.props;

    onOrderNameChange(event.target.value);
  };

  handleOrderNoteChange = (event) => {
    const { onOrderNoteChange } = this.props;

    const value = event.target.value;

    onOrderNoteChange(value.substr(0, 200)); // max 200 characters
  };

  handleAcceptConsentAgreementChange = (acceptConsentAgreement) => {
    this.setState({ acceptConsentAgreement });
  };

  render() {
    const {
      classes,
      t,
      bar,
      orderFields,
      orderServiceOption,
      orderDeliveryContact,
      orderNote,
      onOrderDeliveryContactChange,
      onOrderFieldsChange
    } = this.props;
    const { acceptConsentAgreement } = this.state;

    return (
      <List>
        <ListItem>
          <Typography variant="caption">
            {t("order.customer-fields-cache-disclaimer")}
          </Typography>
        </ListItem>
        {orderServiceOption?.deliveryMethod === DeliveryMethod.PICKUP &&
          !bar.hasCustomerFieldToSendOrderConfirmation() && (
            <ListItem>
              <SetDeliveryContact
                isContactRequired={true}
                deliveryContact={orderDeliveryContact}
                onChange={onOrderDeliveryContactChange}
              />
            </ListItem>
          )}
        {bar.hasCustomerFields() && (
          <CustomerFieldsForm
            customerFields={bar.getCustomerFields()}
            initialFieldValues={orderFields}
            onChange={onOrderFieldsChange}
          />
        )}
        {bar.params.allowNotes && (
          <ListItem>
            <TextField
              variant="standard"
              label={t("order.note")}
              value={orderNote}
              onChange={this.handleOrderNoteChange}
              fullWidth
            />
          </ListItem>
        )}
        <PreferencesConsentToggle label={t("order.remember-order-details")} />
        <ConsentAgreement
          label={`${t("legal.legal-drinking-age")} ${t(
            "legal.by-placing-an-order-you-consent-to-the"
          )}`}
          value={acceptConsentAgreement}
          onChange={this.handleAcceptConsentAgreementChange}
        />
      </List>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderCustomerStep));
