import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Card, CardContent } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import Order from "./Order";

const styles = (theme) => ({
  card: {
    position: "relative"
    //minWidth: 300
  },
  redText: {
    //padding: 3,
    padding: "5px 0",
    fontWeight: "bold",
    textTransform: "uppercase",
    backgroundColor: "#c62828",
    color: "#ffebee"
  },
  redBorder: {
    border: "2px solid #c62828"
  }
});

class OrderCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showOrderContent: false
    };
  }

  componentDidMount() {
    const { showOrderContent } = this.props;

    if (showOrderContent !== undefined) {
      this.setState({ showOrderContent: showOrderContent === true });
    }
  }

  handleOrderClick = () => {
    const { showOrderContent } = this.state;

    this.setState({ showOrderContent: !showOrderContent });
  };

  render() {
    const { classes, t, bar, base, order, isDisabled } = this.props;
    const { showOrderContent } = this.state;

    const overallStatus = order.getOverallStatus();
    const isStatusCancelled = order.isStatusCancelled(base);
    const isStatusError = order.isStatusError(base);

    return (
      <div>
        <Card
          className={classNames(classes.card, {
            [classes.redBorder]: isStatusCancelled || isStatusError
          })}
          onClick={isDisabled ? undefined : this.handleOrderClick}
        >
          {isStatusCancelled && (
            <div className={classes.redText}>
              {t("order.this-order-has-been-cancelled")}
            </div>
          )}
          {isStatusError && (
            <div className={classes.redText}>
              {overallStatus.error && overallStatus.error.text
                ? overallStatus.error.text
                : `${t("error.unknown-error")}`}
            </div>
          )}
          <CardContent>
            <Order
              bar={bar}
              base={base}
              order={order}
              showOrderContent={showOrderContent}
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderCard));
