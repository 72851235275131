import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import QRCode from "qrcode.react";

import ReactMoment from "react-moment";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import OrderItems from "tap-io/client/components/order/OrderItems";
import { deliveryHelper, utilsHelper } from "tap-io/helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  content: {
    maxWidth: 400,
    margin: "0 auto"
  },
  smallSpacing: {
    height: 20
  },
  largeSpacing: {
    height: 50
  },
  center: {
    display: "block",
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  code: {
    fontWeight: "bold",
    fontFamily: "'Courier New', Courier, monospace"
  },
  info: {
    fontWeight: "normal"
  },
  uppercase: {
    textTransform: "uppercase"
  },
  fullWidth: {
    width: "100%"
  },
  orderDetails: {
    display: "flex",
    alignItems: "center"
  }
});

class DeliveryConfirmationDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getCode = () => {
    const { order, base } = this.props;

    if (base) {
      const subOrder = order.subOrders.getForBase(base.id);

      if (subOrder && subOrder.code) {
        return subOrder.code;
      }
    }

    return order.delivery.code;
  };

  handleClose = (event) => {
    event.stopPropagation();

    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { theme, classes, t, isOpen, order, base, allZones, orderUrl } =
      this.props;

    const deliveryColor = order.getDeliveryColor(base);
    const isDefaultDeliveryColor =
      deliveryColor === deliveryHelper.getDefaultDeliveryColorKey();

    const augmentedDeliveryColor = theme.palette.augmentColor({
      color: { main: deliveryHelper.getDeliveryColorValue(deliveryColor) },
      name: "deliveryColor"
    });

    const zone =
      allZones && order.zoneCode
        ? utilsHelper.findInArrayById(allZones, order.zoneCode)
        : undefined;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        PaperProps={{
          style: {
            backgroundColor: augmentedDeliveryColor.main,
            color: augmentedDeliveryColor.contrastText
          }
        }}
        TransitionComponent={Transition}
        fullScreen
      >
        <DialogContent>
          <div className={classes.content}>
            <Typography className={classes.center}>
              {t("delivery.show-this-screen-at-the-bar-to-pickup-your-order")}
            </Typography>
            <div className={classes.smallSpacing} />
            {base && (
              <div>
                <Typography variant="h4" className={classes.center}>
                  {t("delivery.pickup-point")} <strong>{t(base.name)}</strong>
                </Typography>
                <div className={classes.smallSpacing} />
              </div>
            )}
            {orderUrl ? (
              <QRCode
                level="M"
                size={256}
                includeMargin={true}
                value={orderUrl}
                style={{ width: "100%", height: "auto", borderRadius: 8 }}
              />
            ) : null}
            <div className={classes.smallSpacing} />
            <Typography
              variant="h4"
              className={classNames(classes.center, classes.code)}
            >
              {!isDefaultDeliveryColor && (
                <span className={classes.uppercase}>
                  {t(`color.${deliveryColor}`)} &bull;{" "}
                </span>
              )}
              <span className={classes.code}>{this.getCode()}</span>
            </Typography>
            <div className={classes.smallSpacing} />
            <Typography
              variant="body"
              className={classNames(classes.center, classes.code)}
            >
              {zone && (
                <>
                  <span className={classes.info}>{t("zone.zone")}</span>{" "}
                  {zone.name}
                  <br />
                </>
              )}
              <span className={classes.info}>
                {t("order.customer-reference")}
              </span>{" "}
              {order.name}
              <br />
              <span className={classes.info}>
                {t("order.confirmation-code")}
              </span>{" "}
              <span className={classes.uppercase}>
                {order.confirmationCode}
              </span>
              <br />
              <span className={classes.info}>{t("label.timestamp")}</span>{" "}
              <ReactMoment format="DD/MM/YYYY, HH:mm">
                {order.timestamp}
              </ReactMoment>
            </Typography>
            <div className={classes.smallSpacing} />
            <OrderItems
              order={order}
              base={base}
              color={augmentedDeliveryColor.contrastText}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            style={{ color: augmentedDeliveryColor.contrastText }}
          >
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(DeliveryConfirmationDialog));
