import React, { PropsWithChildren, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
    ListItem,
    Theme,
    Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { utilsHelper } from "tap-io/helpers";
import Bar from "tap-io/models/bar/Bar";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        totalContainer: {
            display: "flex",
            width: "100%"
        },
        totalLabel: {
            width: "100%"
        },
        totalAmount: {
            width: "100%",
            textAlign: "right"
        }
    })
);

type OrderDepositsLineProps = {
    bar: Bar;
    orderDepositsAmount: number;
    orderDepositsTotal: number;
};

function OrderDepositsLine({
    bar,
    orderDepositsAmount,
    orderDepositsTotal
}: OrderDepositsLineProps) {
    const { t, i18n } = useTranslation("common");
    const classes = useStyles();

    return (
        <ListItem>
            <Typography variant="body1" className={classes.totalContainer}>
                <span className={classes.totalLabel}>
                    {t("order.deposit")}
                    {orderDepositsAmount !== undefined && (
                        <span>
                            {" "}
                            ({`${orderDepositsAmount}`}{" "}
                            {t(orderDepositsAmount === 1 ? "order.item" : "order.items")})
                        </span>
                    )}
                </span>
                <span
                    className={classes.totalAmount}
                >{`${utilsHelper.formatToTwoDecimals(
                    orderDepositsTotal
                )} ${bar.getOrderCurrency()}`}</span>
            </Typography>
        </ListItem>
    );
}

export default OrderDepositsLine;
