import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";
import BarRoutes from "./BarRoutes";

const styles = (theme) => ({
  content: {
    width: "100%",
    //maxWidth: 600,
    margin: "0 auto"
  }
});

class BarContent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      headerProps,
      timeOffset,
      locale,
      bar,
      assets,
      activeFees,
      activeDeposits,
      allZones
    } = this.props;

    return (
      <main className={classes.content}>
        <BarRoutes
          headerProps={headerProps}
          timeOffset={timeOffset}
          locale={locale}
          bar={bar}
          assets={assets}
          activeFees={activeFees}
          activeDeposits={activeDeposits}
          allZones={allZones}
        />
      </main>
    );
  }
}

export default withStyles(styles)(BarContent);
