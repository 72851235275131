import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, Step, Stepper, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import withStyles from "@mui/styles/withStyles";

import { deliveryHelper } from "tap-io/helpers";
import OrderStatus from "tap-io/models/order/OrderStatus";

import OrderStatusStepLabel from "./OrderStatusStepLabel";
import DeliveryConfirmationDialog from "../delivery/DeliveryConfirmationDialog";

const styles = (theme) => ({
  content: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  stepper: {
    padding: 0,
    background: "transparent"
  },
  stepDescription: {
    display: "block",
    fontSize: 10
  },
  error: {
    color: "#f44336"
  },
  errorIcon: {
    display: "inline-block",
    marginBottom: -8,
    marginRight: 4
  },
  statusContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: theme.spacing(4)
  },
  statusText: {
    fontSize: 20
  },
  showDeliveryConfirmationButton: {
    marginTop: theme.spacing(2),
    color: "white"
  },
  spacing: {
    height: 10
  },
  confirmedContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  confirmedIcon: {
    fontSize: 50,
    color: theme.palette.success.main
  },
  confirmedText: {
    marginTop: theme.spacing(0.5),
    fontWeight: "bold"
  }
});

class OrderStatusAsSoonAsPossible extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOrderDeliveryConfirmationDialogOpen: false,
      activeStepIndex: 0
    };
  }

  componentDidMount() {
    const { setShowDeliveryConfirmation } = this.props;
    if (setShowDeliveryConfirmation) {
      setShowDeliveryConfirmation(this.handleShowDeliveryConfirmation);
    }

    this.refreshActiveStepIndex();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { order, base, shouldPreparationBeComplete } = this.props;
    const { isOrderDeliveryConfirmationDialogOpen } = this.state;

    const orderStatus = order ? order.getStatus(base) : undefined;
    const prevOrderStatus = prevProps.order
      ? prevProps.order.getStatus(base)
      : undefined;

    const prevShouldPreparationBeComplete =
      prevProps.shouldPreparationBeComplete;

    if (
      orderStatus !== prevOrderStatus ||
      shouldPreparationBeComplete !== prevShouldPreparationBeComplete
    ) {
      this.refreshActiveStepIndex();
    }

    if (
      isOrderDeliveryConfirmationDialogOpen &&
      orderStatus === OrderStatus.COMPLETE
    ) {
      this.setState({ isOrderDeliveryConfirmationDialogOpen: false });
    }
  }

  refreshActiveStepIndex = () => {
    const { order, base, shouldPreparationBeComplete } = this.props;

    let activeStepIndex = 0;

    if (order) {
      if (order.isStatusClaimed(base)) {
        activeStepIndex = shouldPreparationBeComplete ? 2 : 1;
      } else if (order.isStatusComplete(base)) {
        activeStepIndex = 3;
      }
    }

    this.setState({ activeStepIndex });
  };

  handleShowDeliveryConfirmation = () => {
    this.setState({ isOrderDeliveryConfirmationDialogOpen: true });
  };

  handleDeliveryConfirmationDialogClose = () => {
    this.setState({ isOrderDeliveryConfirmationDialogOpen: false });
  };

  getOrderStatusDescription = () => {
    const { t, order, base } = this.props;

    if (order) {
      const status = order.getStatus(base);

      switch (status) {
        case OrderStatus.QUEUED:
          return t(
            "order.order-has-been-confirmed-and-is-awaiting-preparation"
          );
          break;
        case OrderStatus.CLAIMED:
          //return t("order.order-is-in-preparation");
          break;
      }
    }

    return null;
  };

  render() {
    const {
      classes,
      t,
      order,
      base,
      allZones,
      orderUrl,
      shouldPreparationBeComplete,
      estimatedRemainingPreparationTimeInMinutes
    } = this.props;
    const { isOrderDeliveryConfirmationDialogOpen, activeStepIndex } =
      this.state;

    if (order && order.isStatusError(base)) {
      return (
        <Typography className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />{" "}
          {t("order.order-confirmation-failed")}. {t("order.retry-new-order")}.
        </Typography>
      );
    }

    const deliveryColor = deliveryHelper.getDeliveryColorValue(
      order.getDeliveryColor(base)
    );

    const statusDescription = this.getOrderStatusDescription();

    return (
      <div>
        <DeliveryConfirmationDialog
          isOpen={isOrderDeliveryConfirmationDialogOpen}
          onClose={this.handleDeliveryConfirmationDialogClose}
          order={order}
          base={base}
          allZones={allZones}
          orderUrl={orderUrl}
        />
        <div className={classes.statusContainer}>
          {order.isStatusComplete(base) ? (
            <Typography className={classes.statusText}>
              {t("order.status-complete-long")}
            </Typography>
          ) : (
            <>
              {shouldPreparationBeComplete ? (
                <div>
                  <Typography className={classes.statusText}>
                    {t(
                      `order.status-claimed${
                        order && order.delivery
                          ? `-${order.delivery.method}`
                          : ""
                      }-long`
                    )}
                    {base && (
                      <span>
                        {` ${t(
                          order.isDeliveryMethodPickup ? "order.at" : "order.by"
                        )} `}
                        <strong>{base.name}</strong>
                      </span>
                    )}
                  </Typography>
                </div>
              ) : (
                <div>
                  <Typography className={classes.statusText}>
                    {base ? (
                      <span>
                        {t("order.order-is-being-processed-by")}{" "}
                        <strong>{base ? base.name : ""}</strong>
                      </span>
                    ) : (
                      t("order.order-is-being-processed")
                    )}
                  </Typography>
                </div>
              )}
              {estimatedRemainingPreparationTimeInMinutes &&
              estimatedRemainingPreparationTimeInMinutes > 0 ? (
                <Typography>
                  {t("order.order-expected-to-be-ready-in")}{" "}
                  <strong>{`${estimatedRemainingPreparationTimeInMinutes} ${t(
                    estimatedRemainingPreparationTimeInMinutes === 1
                      ? "label.minute"
                      : "label.minutes"
                  )}`}</strong>
                </Typography>
              ) : null}
              {order.isDeliveryMethodPickup && shouldPreparationBeComplete && (
                <Button
                  variant="contained"
                  onClick={this.handleShowDeliveryConfirmation}
                  className={classes.showDeliveryConfirmationButton}
                  style={{ backgroundColor: deliveryColor }}
                >
                  {t("order.show-delivery-confirmation")}
                </Button>
              )}
            </>
          )}
          {order.isStatusQueued(base) && (
            <>
              <div className={classes.spacing} />
              <div className={classes.confirmedContainer}>
                <CheckCircleIcon className={classes.confirmedIcon} />
                <Typography className={classes.confirmedText}>
                  {t("order.order-confirmed")}
                </Typography>
              </div>
            </>
          )}
          {statusDescription && (
            <>
              <div className={classes.spacing} />
              <Typography variant="caption">{statusDescription}</Typography>
            </>
          )}
        </div>
        <Stepper
          alternativeLabel
          orientation="horizontal"
          activeStep={activeStepIndex}
          className={classes.stepper}
        >
          <Step>
            <OrderStatusStepLabel color={deliveryColor}>
              {t("order.status-queued-short")}
              {order.isHighPriority && (
                <Typography
                  variant="overline"
                  className={classes.stepDescription}
                  style={{ color: deliveryColor }}
                >
                  {t("service.priority-high")}
                </Typography>
              )}
            </OrderStatusStepLabel>
          </Step>
          <Step>
            <OrderStatusStepLabel color={deliveryColor}>
              {t("order.status-claimed-short")}
            </OrderStatusStepLabel>
          </Step>
          <Step>
            <OrderStatusStepLabel
              color={deliveryColor}
              CustomIcon={
                shouldPreparationBeComplete
                  ? order.isDeliveryMethodServe
                    ? undefined //EventSeatIcon
                    : order.isDeliveryMethodPickup
                    ? EmojiPeopleIcon
                    : HourglassEmptyIcon
                  : undefined
              }
            >
              {t(
                shouldPreparationBeComplete
                  ? `order.status-claimed${
                      order && order.delivery ? `-${order.delivery.method}` : ""
                    }-short`
                  : "order.status-complete-short"
              )}
            </OrderStatusStepLabel>
          </Step>
        </Stepper>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderStatusAsSoonAsPossible)
);
