import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import Ad from "./Ad";

const styles = (theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.background.default
  }
});

class Ads extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, height, ads } = this.props;

    return (
      <div className={classes.container} style={{ height }}>
        {ads && ads.map((ad) => <Ad key={ad.id} ad={ad} />)}
      </div>
    );
  }
}

export default withStyles(styles)(Ads);
