import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import withStyles from "@mui/styles/withStyles";

import DeliveryMethod from "tap-io/models/delivery/DeliveryMethod";
import { serviceOptionHelper } from "tap-io/helpers";

const styles = (theme) => ({});

class OrderDeliveryLine extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, orderServiceOption } = this.props;

    return (
      <ListItem>
        <ListItemIcon>
          {orderServiceOption?.deliveryMethod === DeliveryMethod.SERVE ? (
            <EventSeatIcon className={classes.icon} />
          ) : orderServiceOption?.deliveryMethod === DeliveryMethod.PICKUP ? (
            <EmojiPeopleIcon className={classes.icon} />
          ) : (
            <HourglassEmptyIcon className={classes.icon} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            orderServiceOption
              ? serviceOptionHelper.getServiceOptionLabel(orderServiceOption, t)
              : `(${t("label.unknown")})`
          }
        />
      </ListItem>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderDeliveryLine));
