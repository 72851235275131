import React, { PureComponent } from "react";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  header: {
    display: "flex",
    padding: theme.spacing(2, 3, 1, 3)
  },
  headerText: {
    flexGrow: 1,
    paddingRight: theme.spacing(1)
  },
  title: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.tertiary.contrastText
  },
  subtitle: {
    color: theme.palette.tertiary.contrastText
  }
});

class OrderStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, children, title, subtitle, action } = this.props;

    return (
      <div>
        <div className={classes.header}>
          <div className={classes.headerText}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
          </div>
          {action}
        </div>
        <div>{children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(OrderStep);
