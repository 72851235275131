import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, Drawer, List, ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ScanOrManuallyEnterVoucher from "tap-io/client/components/voucher/ScanOrManuallyEnterVoucher";

const styles = (theme) => ({
  drawer: {
    //maxHeight: "90%"
  },
  list: {
    width: "100%",
    maxWidth: 600,
    margin: "0 auto"
  }
});

class ScanOrManuallyEnterVoucherDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      voucherId: "",
      voucherPin: ""
    };
  }

  handleVoucherIdChange = (voucherId) => {
    this.setState({ voucherId });
  };

  handleVoucherPinChange = (voucherPin) => {
    this.setState({ voucherPin });
  };

  handleConfirmVoucher = (voucherId, voucherPin) => {
    const { onConfirm } = this.props;
    this.setState({ voucherId, voucherPin });

    onConfirm(voucherId, voucherPin);
  };

  render() {
    const { classes, t, style, isOpen, onClose } = this.props;
    const { voucherId, voucherPin } = this.state;

    return (
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.drawer }}
        style={style}
      >
        <List className={classes.list}>
          <ListItem>
            <Typography variant="h6" className={classes.title}>
              {t("voucher.enter-voucher")}
            </Typography>
          </ListItem>
          <ListItem>
            <ScanOrManuallyEnterVoucher
              confirmVoucherLabel={t("voucher.add-voucher")}
              voucherId={voucherId}
              voucherPin={voucherPin}
              onVoucherIdChange={this.handleVoucherIdChange}
              onVoucherPinChange={this.handleVoucherPinChange}
              onConfirmVoucher={this.handleConfirmVoucher}
            />
          </ListItem>
          <ListItem>
            <Button fullWidth onClick={onClose}>
              {t("label.confirm")}
            </Button>
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(ScanOrManuallyEnterVoucherDrawer)
);
