import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ConfirmOrderDeferredPaymentDialog from "tap-io/client/components/payment/ConfirmOrderDeferredPaymentDialog";

import withAuthorization from "../auth/withAuthorization";

const styles = (theme) => ({});

class PayOrderCash extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmOrderDeferredPaymentDialogOpen: false
    };
  }

  componentDidMount() {
    const { autoRedirect } = this.props;

    if (autoRedirect) {
      setTimeout(this.handleConfirmOrderDeferredPayment, 100);
    }
  }

  handleConfirmOrderDeferredPayment = (event) => {
    this.setState({ isConfirmOrderDeferredPaymentDialogOpen: true });
  };

  handleConfirmOrderDeferredPaymentDialogClose = () => {
    const { onComplete } = this.props;

    this.setState(
      {
        isConfirmOrderDeferredPaymentDialogOpen: false
      },
      onComplete
    );
  };

  render() {
    const { t, auth, bar, order } = this.props;
    const { isConfirmOrderDeferredPaymentDialogOpen } = this.state;

    return (
      <div>
        <ConfirmOrderDeferredPaymentDialog
          isOpen={isConfirmOrderDeferredPaymentDialogOpen}
          onClose={this.handleConfirmOrderDeferredPaymentDialogClose}
          auth={auth}
          bar={bar}
          order={order}
          alsoRequestPayment={true}
        />
        <Button
          variant="outlined"
          size="large"
          color="primary"
          onClick={this.handleConfirmOrderDeferredPayment}
        >
          {t("payment.checkout-order-manually")}
        </Button>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(PayOrderCash))
);
